import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import TableRow from './TableRow';
import { UUID } from '../util';
import './Table.scss';

const TContent = (props) => {
  const { type, content } = props;
  const headerCheck = (type === 'head');
  const Area = (headerCheck) ? 'thead' : 'tbody';
  return (
    <Area>
      {content.filter((element) => element.options.isHeader === headerCheck).map((element, index) => (
        <TableRow
          key={UUID()}
          options={element.options}
          Content={element.Content}
        />
      ))}
    </Area>
  );
};

/**
 * @group Components
 * Displays table component
 */
const Table = (props) => {
  const { opts = {} } = props;
  const { options, Content, id } = opts;

  return (
    <table className={classNames('thdTable', { 'thdTable--responsive': options.isResponsive })} id={id}>
      <TContent content={Content} type="head" />
      <TContent content={Content} type="body" />
    </table>
  );
};

TContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string
  })),
  type: PropTypes.string
};
TContent.defaultProps = {
  content: [],
  type: ''
};

Table.propTypes = {
  opts: PropTypes.shape({
    Content: PropTypes.instanceOf(Array),
    options: PropTypes.shape({}),
    id: PropTypes.string,
  }).isRequired
};

export default Table;
