import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import PriceFormat from './PriceFormat';
import Rebates from './Rebates';
import InstantRebates from './InstantRebates';
import SavingsCenterPromos from './SavingsCenterPromos';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-simple.style.scss';

class PriceSimple extends Component {
  // BRIO Skus
  handleBRIOSku = () => {
    const { skuModel } = this.props;
    const { pricing } = skuModel || {};
    const isCustomKitchenCabinetsSku = pricing?.category === 'Custom Kitchen Cabinets';
    const quoteMessage = isCustomKitchenCabinetsSku ? '' : `Get a quote during a FREE design Consultation`;
    const howToBuyDetails = `Home Depot Installation ${isCustomKitchenCabinetsSku ? 'Available' : 'Required'}`;
    return (
      <div className="brio__wrapper">
        <div className="brio__quote-message">
         {quoteMessage}
        </div>
        <div className="brio__how-to-buy-details">
          {howToBuyDetails}
        </div>
      </div>
    );
  };

  // HIDE_PRICE/LIVE_GOODS
  handleHidePrice = () => {
    return (<div className="price__contact-message">Contact your local store for price</div>);
  };

  // Limit per order
  handleLimitPerOrder({
    wasPrice, priceBadge, uom, save, price, hasEcoRebates,
    bulkPrice, bulkPriceThresholdQty
  }) {
    const {
      zipCode,
      displayEachUom,
      hideBadge,
      hideBulkPrice,
      hideLimitPerOrder,
      hideRebates,
      hideSavingsText,
      skuModel,
    } = this.props;
    const { pricing } = skuModel;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    const showRebates = !hideRebates && hasEcoRebates && zipCode;
    if (!!pricing.quantityLimit) {
      return (
        <div className="limit-per-order price__qty-limit">
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
          />
          <div className="price__was-price">
            <div>
              {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
              {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
            </div>
            {(!hideSavingsText && save) && (
              <div className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
            )}
          </div>
          {(bulkPrice && !hideBulkPrice) && (
            <div className="u__text--success">
              Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span>
            </div>
          )}
          {showRebates && (
            <Rebates
              itemId={pricing.itemId}
              zipCode={zipCode}
              optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
              rebateClasses="price__ecorebates"
              multi
            />
          )}
          {!hideLimitPerOrder && (
            <div className="price__qty-limit">
              Limit {pricing.quantityLimit} per order
            </div>
          )}
        </div>
      );
    }
    return '';
  }

  // Unit price regular, Unit price with strike through
  handleUnitPrice = ({
    alternatePrice, alternateUom, uom, price, priceBadge,
    save, wasPrice, unitWasPrice, bulkPrice, bulkPriceThresholdQty, hasEcoRebates
  }) => {
    const {
      displayEachUom,
      hideBadge,
      hideBulkPrice,
      hideLimitPerOrder,
      hideRebates,
      hideSavingsText,
      skuModel,
      zipCode,
    } = this.props;
    const { pricing } = skuModel;
    const { originalPrice } = pricing;
    const showRebates = !hideRebates && hasEcoRebates && zipCode;
    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    if (pricing.unitOriginalPrice) {
      if (uom === 'sq. ft.') {
        return (
          <div id="unit-case-price">
            <PriceFormat
              price={price}
              priceBadge={priceBadge}
              hideBadge={hideBadge}
            />
            <div className="price__was-price">
              {!unitWasPrice && (
                <span>
                  <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
                  (<span className="u__bold">{helpers.handlePriceParsing(originalPrice, false)}</span><span> {pricing.uom && helpers.parseUom(pricing.uom, displayEachUom)})</span>
                </span>
              )}
              {unitWasPrice && (
                <span>
                  <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
                  (<span className="u__bold">{helpers.handlePriceParsing(alternatePrice, false)}</span><span> {pricing.uom && helpers.parseUom(pricing.uom, displayEachUom)})</span>
                  <div>
                    <span className="u__strike">{helpers.handlePriceParsing(unitWasPrice.original, true)}</span>
                    {!hideSavingsText && save && <span className="u__text--success"> Save {save.percentageOff}%</span>}
                  </div>
                </span>
              )}
            </div>
            {(bulkPrice && !hideBulkPrice) && (
              <div className="u__text--success">
                Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span>
              </div>
            )}
            {(!!pricing.quantityLimit && !hideLimitPerOrder) && (
              <div className="price__qty-limit">Limit {pricing.quantityLimit} per order</div>
            )}
            {showRebates && (
              <Rebates
                itemId={pricing.itemId}
                zipCode={zipCode}
                optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
                rebateClasses="price__ecorebates"
                multi
              />
            )}
          </div>
        );
      } else if (uom !== 'sq. ft.') {
        return (
          <div id="unit-case-price">
            <PriceFormat
              price={alternatePrice.toString()}
              priceBadge={priceBadge}
              hideBadge={hideBadge}
            />
            <div className="price__was-price">
              {!wasPrice && (
                <span>
                  <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                  {pricing.unitsPerCase > 1 && <span>(<span className="u__bold">{helpers.handlePriceParsing(price, false)}</span><span> {uom && helpers.parseUom(uom, displayEachUom)})</span></span>}
                </span>
              )}
              {wasPrice && (
                <span>
                  <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                  {pricing.unitsPerCase > 1 && <span>(<span className="u__bold">{helpers.handlePriceParsing(price, false)}</span><span> {uom && helpers.parseUom(uom, displayEachUom)})</span></span>}
                  <div>
                    <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
                    {!hideSavingsText && save && <span className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</span>}
                  </div>
                </span>
              )}
            </div>
            {(bulkPrice && !hideBulkPrice) && (
              <div className="u__text--success">
                Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span>
              </div>
            )}
            {(!!pricing.quantityLimit && !hideLimitPerOrder) && (
              <div className="price__qty-limit">Limit {pricing.quantityLimit} per order</div>
            )}
            {showRebates && (
              <Rebates
                itemId={pricing.itemId}
                zipCode={zipCode}
                optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
                rebateClasses="price__ecorebates"
                multi
              />
            )}
          </div>
        );
      }
    }

    return (
      <div id="unit-price">
        {pricing.unitOfMeasureCoverage && (
          <div className="price__unit-cover">
            {pricing.unitOfMeasureCoverage} ({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})
          </div>
        )}
        <PriceFormat
          price={alternatePrice.toString()}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        {!wasPrice && <span className="priceFormat__unit-alignment"> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>}
        {wasPrice &&
          <div className="price__was-price">
            <span>{alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
            <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
            {!hideSavingsText && save && <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>}
          </div>
        }
        {bulkPrice && !hideBulkPrice && (
          <div className="u__text--success">
            Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span>
          </div>
        )}
        {showRebates && (
          <Rebates
            itemId={pricing.itemId}
            zipCode={zipCode}
            optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
            rebateClasses="price__ecorebates"
            multi
          />
        )}
      </div>
    );
  };

  // Was price
  handleWasPrice = ({
    wasPrice, save, priceBadge, uom
  }) => {
    const { original, special } = wasPrice;
    const { displayEachUom, hideBadge, hideSavingsText } = this.props;
    return (
      <div id="was-price">
        <PriceFormat
          price={special}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        <div className="price__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(original, true)}</span>}
          {!hideSavingsText && save && <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>}
        </div>
      </div>
    );
  };

  // Starting At price
  handleStartingAt = ({
    wasPrice, save, price, priceBadge, uom
  }) => {
    const { original } = wasPrice || {};
    const { displayEachUom, hideBadge, hideSavingsText } = this.props;
    return (
      <div id="start-at">
        <div className="price__start-at">Starting at</div>
        <div>
          <PriceFormat
            price={price}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
          />
          <div className="price__was-price">
            {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
            {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(original, true)}</span>}
            {(!hideSavingsText && save) && (
              <div className="u__text--success">
                Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Map price
  handleMapPrice = ({
    mapPrice, wasPrice, uom, priceBadge, pricingMessage
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
    let priceToDisplay = '';
    if (mapPrice) {
      priceToDisplay = mapPrice.original;
    } else if (wasPrice) {
      priceToDisplay = wasPrice.original;
    }
    const { channel, displayEachUom, skuModel, hideBadge } = this.props;
    const { pricing } = skuModel;
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    return (
      <div className="price__map-price">
        {(mapPrice || wasPrice) && (
          <div>
            <span className="price__message">
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                hideBadge={hideBadge}
                hasStrikeThrough
              />
            </span>
            {uom && <span className="price__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
          </div>
        )}
        <Button link contentClass="price__tooltip" >
          <Tooltip
            channel={channel}
            closeButton
            content={tooltipContent}
            maxWidth={300}
            placement="bottom"
          >
            <div id={tooltipId}>{pricingMessage}</div>
          </Tooltip>
        </Button>
      </div>
    );
  };

  // Bulk price
  handleBulkPrice = ({
    price, priceBadge, uom, wasPrice, save, bulkPrice, bulkPriceThresholdQty
  }) => {
    const bulkPriceTemplate = helpers.handlePriceParsing(bulkPrice, true);
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    const { displayEachUom, hideBadge, hideBulkPrice, hideSavingsText } = this.props;
    return (
      <div id="bulk-price">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        <div className="price__was-price">
          {uom && (
            <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
          )}
          {wasPrice && (
            <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
          )}
          {(!hideSavingsText && save) && (
            <div className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
          )}
        </div>
        {!hideBulkPrice && (
          <div className="u__text--success">
            Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{bulkPriceTemplate}</span>
          </div>
        )}
      </div>
    );
  };

  // Instant Rebate
  handleInstantRebate = ({
    price, priceBadge, uom, displayEachUom, wasPrice
  }) => {
    const { skuModel, channel, hideBadge, hideRebates } = this.props;
    const { pricing } = skuModel;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    return (
      <div id="instant-rebate">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        <div className="price__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
        </div>
        {!hideRebates && (
          <InstantRebates
            storePromotion={pricing?.storePromotion}
            price={pricing}
            channel={channel}
          />
        )}
      </div>
    );
  };

  // Range Price
  handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, percentageOff
  }) => {
    const { hideBadge, hideSavingsText } = this.props;
    return (
      <div id="range-price">
        <div>
          <PriceFormat
            price={minPrice}
            hideBadge={hideBadge}
          />
          <span className="u__bold price__range"> - </span>
          <PriceFormat
            price={maxPrice}
            hideBadge={hideBadge}
          />
        </div>
        <div>
          {(wasMinPrice && wasMaxPrice) && (
            <span className="u__strike">
              {helpers.handlePriceParsing(wasMinPrice, true)}
              <span> - {helpers.handlePriceParsing(wasMaxPrice, true)}</span>
            </span>
          )}
          {(!hideSavingsText && percentageOff) && (
            <span className="u__text--success"> Save up to {percentageOff}%</span>
          )}
        </div>
      </div>
    );
  };

  // Eco Rebate
  handleEcoRebates = ({
    price, priceBadge, uom, wasPrice, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty,
  }) => {
    const {
      displayEachUom,
      hideBadge,
      hideBulkPrice,
      hideRebates,
      hideSavingsText,
      skuModel,
      zipCode,
    } = this.props;
    const { pricing } = skuModel;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    const showRebates = !hideRebates && zipCode;
    return (
      <div id="eco-rebate-price">
        {(bulkPrice && !hideBulkPrice) && this.handleBulkPrice({
          price, priceBadge, uom, displayEachUom, wasPrice, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && this.handleMapPrice({
          mapPrice, price, priceBadge, uom, displayEachUom, pricingMessage
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              hideBadge={hideBadge}
            />
            <div className="price__was-price">
              {uom && (
                <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
              )}
              {wasPrice && (
                <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
              )}
              {(!hideSavingsText && save) && (
                <div className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
              )}
            </div>
          </>
        )}
        {showRebates && (
          <Rebates
            itemId={pricing.itemId}
            zipCode={zipCode}
            optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
            rebateClasses="price__ecorebates"
            multi
          />
        )}
      </div>
    );
  }

  // No details
  handleNoDetailsPrice = ({
    price, uom, wasPrice, save, priceBadge
  }) => {
    const { displayEachUom, hideBadge, hideSavingsText } = this.props;
    const { original = '' } = wasPrice = {};
    return (
      <div id="no-details-price">
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        <div className="price__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {wasPrice.original && (
            <span className="u__strike">{helpers.handlePriceParsing(original, true)}</span>
          )}
          {(!hideSavingsText && save) && (
            <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
          )}
        </div>
      </div>
    );
  }

  // Standard price
  handleStandardPrice = ({
    price, priceBadge, uom
  }) => {
    const { displayEachUom, hideBadge } = this.props;
    return (
      <div id="standard-price">
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
        />
        {uom && <span className="price__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
      </div>
    );
  }

  // Basic price
  handleBasicPrice = ({ price }) => {
    return (
      <PriceFormat
        basic
        price={price}
      />
    );
  };

  renderByType = ({ _price, type }) => {
    const { skuModel } = this.props;
    const { pricing } = skuModel;
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return this.handleStandardPrice(_price);
      case PRICE_TYPES.BASIC:
        return this.handleBasicPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return this.handleWasPrice(_price);
      case PRICE_TYPES.STARTING_AT:
        return this.handleStartingAt(_price);
      case PRICE_TYPES.MAP_PRICE:
        return this.handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return this.handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return this.handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return this.handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return this.handleUnitPrice(_price, pricing);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return this.handleLimitPerOrder(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return this.handleRangePrice(_price);
      case PRICE_TYPES.BRIO:
        return this.handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return this.handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return this.handleNoDetailsPrice(_price);
      default: return null;
    }
  }

  render() {
    const {
      channel, skuModel, onSavingsCenterToggle, secondaryImageUrl, hidePromotions
    } = this.props;
    const { pricing = {} } = skuModel;
    const hasSavingsCenterPromos = /Buy More Save More/i.test(pricing.savingsCenterPromos);

    return (
      <div className="price__wrapper">
        {this.renderByType(helpers.getPricingObject({ ...this.props, pricing }))}
        {(hasSavingsCenterPromos && !hidePromotions) && (
          <SavingsCenterPromos
            itemId={pricing.itemId}
            channel={channel}
            secondaryImageUrl={secondaryImageUrl}
            onSavingsCenterToggle={onSavingsCenterToggle}
          />
        )}
      </div>
    );
  }
}

PriceSimple.propTypes = {
  /**
   * To show the basic price format
   */
  basic: PropTypes.bool,
  /**
   * device type
   */
  channel: PropTypes.string.isRequired,
  /**
   * disables the display of range pricing often seen in generic sku product pods
   */
  disableRangePricing: PropTypes.bool,
  /**
   * disables the "each" uom treatment
   */
  displayEachUom: PropTypes.bool,
  /**
   * suppresses the display of badges, such as bulk pricing
   */
  hideBadge: PropTypes.bool,
  /**
   * disables bulk pricing messaging
   */
  hideBulkPrice: PropTypes.bool,
  /** 
   * suppresses the display of limit per order text
   */
  hideLimitPerOrder: PropTypes.bool,
  /**
   * disables display of the short description for promotions
   */
  hidePromotions: PropTypes.bool,
  /**
   * Hide Savings Text
   */
  hideSavingsText: PropTypes.bool,
  /**
   * Hide Rebate Messaging and prevent Rebate Calls
   */
  hideRebates: PropTypes.bool,
  /**
   * to display only a very simplified view of the price
   */
  omitDetails: PropTypes.bool,
  /**
   * callback triggered when savings center tooltip link is clicked
   */
  onSavingsCenterToggle: PropTypes.func,
  /**
   * The secondary image URL
   */
  secondaryImageUrl: PropTypes.string,
  /**
   * the transformed price data
   */
  skuModel: PropTypes.shape({
    pricing: PropTypes.shape({
      itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      uom: PropTypes.string,
      unitsPerCase: PropTypes.number,
      originalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dollarOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      percentageOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuySavings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuyDollarOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      alternatePriceDisplay: PropTypes.bool,
      quantityLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      unitOriginalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      storePromotion: PropTypes.shape(),
      unitOfMeasureCoverage: PropTypes.string
    }).isRequired
  }).isRequired,
  /**
   * allows the display of a discounted price. This is overriden by the actual data when it includes the special price
   */
  specialPrice: PropTypes.bool,
  /**
   * zipcode used by ecorebates
   */
  zipCode: PropTypes.string,
};

PriceSimple.defaultProps = {
  basic: false,
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideRebates: false,
  hideSavingsText: false,
  omitDetails: false,
  onSavingsCenterToggle: () => { },
  secondaryImageUrl: null,
  specialPrice: false,
  zipCode: null,
};

export default PriceSimple;
