import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import './BasicHeroWithOutButton.scss';
import './BasicHeroWithOutButtonCC.scss';

const BasicHeroWithOutButton = (props) => {
  const { opts = {}, channel } = props;
  const {
    collectionLink,
    cornerCaption,
    heading,
    subHeading,
    heroMedia = {},
    heroMediaMobile = {},
    eventLogo = {},
    eventLogoMobile = {},
    id,
    isHeroModuleWithoutButtonCC,
    isHeroModuleWithoutButton
  } = opts;
  const isMobile = channel === 'mobile';
  const wrapperClass = classNames({
    etchHeroCC: isHeroModuleWithoutButtonCC,
    etchHero: isHeroModuleWithoutButton
  });

  const renderHeroMedia = () => {
    const media = isMobile ? heroMediaMobile : heroMedia;
    const { src, alt, height, width } = media;
    const imgClass = classNames({
      'img--block': height && width
    });
    return (
      <picture className={`${wrapperClass}__media`}>
        <source media="(min-width: 640px)" srcSet={src} />
        <Image
          className={`stretchy ${wrapperClass}__main ${imgClass}`}
          src={src}
          alt={alt}
          height={height}
          width={width}
        />
      </picture>
    );
  };

  const renderEventLogo = () => {
    const logo = isMobile ? eventLogoMobile : eventLogo;
    const { src, alt, height, width } = logo;
    const logoImg = isMobile ? `${wrapperClass}__eventLogoMobile` : `${wrapperClass}__eventLogo`;
    const imgClass = classNames({
      'img--block': height && width
    });
    const logoWrapperClass = isMobile ? `${wrapperClass}__mobilelogo` : `${wrapperClass}__logo`;
    return (
      <div className={logoWrapperClass}>
        <Image src={src} className={`${logoImg} ${imgClass}`} alt={alt} width={width} height={height} />
      </div>
    );
  };

  return (
    <div className={wrapperClass} id={id}>
      {renderHeroMedia()}
      <div className={`${wrapperClass}__info u__text-shadow--none`}>
        {renderEventLogo()}

        <h2 className={`${wrapperClass}__heading u__display-font--black u__jumbo`}>
          {heading}
        </h2>
        {subHeading
          && <h3 className={`${wrapperClass}__subHeading u__large`}>{subHeading}</h3>}
      </div>
      <div className={`${wrapperClass}__corner`}>
        {collectionLink
          && (
            <a className={`${wrapperClass}__corner__caption`} href={collectionLink}>
              {cornerCaption}
            </a>
          )}
        {!collectionLink
          && <p className={`${wrapperClass}__corner__caption`}>{cornerCaption}</p>}
      </div>
    </div>
  );
};

BasicHeroWithOutButton.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string,
    isHeroModuleWithoutButtonCC: PropTypes.bool,
    isHeroModuleWithoutButton: PropTypes.bool
  }).isRequired,
  channel: PropTypes.string
};
BasicHeroWithOutButton.defaultProps = {
  channel: 'desktop'
};

export default BasicHeroWithOutButton;
