import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Endcap } from '@thd-olt-component-react/endcap';
import './EtchEndCap.scss';
import {
  CUSTOMER_TYPES,
  getSvocId,
  getUserId,
  getMarketingCloudVisitorID,
} from '../../helpers/endCapHelper';

const EtchEndCap = (props) => {
  const { opts = {}, channel, navParam } = props;
  const { id } = opts;
  const { storeId, storeZip } = useStore();
  const customerType = typeof window !== 'undefined' && window?.THDCustomer?.default?.customerType;
  // eslint-disable-next-line no-restricted-globals
  let pageUrl = (isNaN(navParam[0]) || navParam.length > 20) ? '/' + navParam : '/N-' + navParam;
  const personalizedDealsUserid = customerType === CUSTOMER_TYPES.B2B
    ? getSvocId() + '_' + getUserId()
    : getMarketingCloudVisitorID();
  return (
    <QueryProvider
      className="etch-analytics"
      data-module-id={id}
      cacheKey="endcap"
      defaultVariables={{ storeId, zipCode: storeZip }}
    >
      <Endcap
        channel={channel.toUpperCase()}
        customerType={customerType === CUSTOMER_TYPES.B2B ? 'B2B' : 'B2C'}
        pageUrl={pageUrl}
        storeId={storeId}
        personalizedDealsUserid={personalizedDealsUserid}
        entryId={id}
        isABTesting={false}
      />
    </QueryProvider>
  );
};

EtchEndCap.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  channel: PropTypes.string.isRequired,
  navParam: PropTypes.string.isRequired
};

export default EtchEndCap;
