/* eslint-disable */
import classNames from 'classnames';

class AlignmentSelector {
  constructor() {}



  get(options) {
    return classNames({
      'u__text-align--left': options.textAlignment.isLeft_Align,
      'u__text-align--center': options.textAlignment.isCenter_Align,
      'u__text-align--right': options.textAlignment.isRight_Align,
      'u__text-align--justify': options.textAlignment.isJustify_Align,
    });
  }
}

export default AlignmentSelector;