import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AlignmentSelector from '../../helpers/textAlignmentSelector';
import './CTA.style.scss';

let alignmentSelector = new AlignmentSelector();

/**
 * @group Titles
 * Call to Action
 */

const CTA = (props) => {
  const { opts = {} } = props;
  const { text, options } = opts;
  let textAlignment = (typeof options.textAlignment !== 'undefined')
    ? alignmentSelector.get(options)
    : 'u__text-align--left';
  return (
    <p
      className={classNames('lower_pod_cta', textAlignment)}
      id={options.name}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
      <a
        href={(options.URL) ? options.URL : '#'}
        target={(options.targetBlank) ? '_blank' : ''}
        rel={`${(options.externalLink) ? 'nofollow' : ''}${(options.targetBlank) ? ' noopener noreferrer' : ''}`}
        style={{
          top: `${options.positionY}px`,
          left: `${options.positionX}px`,
          position: 'relative'
        }}
        className={classNames({
          triggerOverlay: options.openInModal,
          'tip-Opener': options.isToolTip
        })}
        data-overlay-type={options.relationship}
        data-tooltip={options.URL}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: `${text}` }} />
      </a>
    </p>
  );
};

CTA.propTypes = {
  opts: PropTypes.shape({
    text: PropTypes.string,
    options: PropTypes.shape({})
  }).isRequired
};

export default CTA;
