import React from 'react';
import PropTypes from 'prop-types';

/**
  * @group Components
  * Social Icons
  */

const SocialIcon = (props) => {
  const { opts = {} } = props;
  const { options } = opts;
  const fbString = (options.facebook) ? 'facebook,' : '';
  const twitterString = (options.twitter) ? 'twitter,' : '';
  const pinterestString = (options.pinterest) ? 'pinterest,' : '';
  return (
    <div id="socialWidgetTable" className="widgetEmailPrintTable">
      <div
        className="sharewidget"
        data-socialmedia={`${fbString}${twitterString}${pinterestString}`}
      />
      {options.print
        && (
          <div className="widget">
          <a href="javascript:window.print();" name="print-page"><i className="dwarf-printIcon" /></a>  { /* eslint-disable-line */ }
          </div>
        )}
    </div>
  );
};

SocialIcon.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    type: PropTypes.string,
    Content: PropTypes.instanceOf(Array),
    id: PropTypes.string,
  }).isRequired
};

export default SocialIcon;
