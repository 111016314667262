import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './EtchVisualNavigationStory.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchVisualNavigationStory = (props) => {

  const { opts = {} } = props;
  const {
    ctaLink = '',
    type,
    image = {},
    heading,
    subHeading,
    linkList,
    id
  } = opts;
  const { src, alt, height, width } = image;
  const imgClass = classNames({
    'img--block': height && width
  });

  return (
    <>
      <div className="etchVisualNavigationStory etch-analytics" data-module-id={id} data-module-type={type}>
        <div className="etchVisualNavigationStory__container">
          <div className="etchVisualNavigationStory__card" data-module-content-id={id}>
            {ctaLink
              && (
                <CTARenderer ctaLink={ctaLink}>
                  <Image
                    className={`etchVisualNavigationStory__card__img ${imgClass}`}
                    lazy
                    src={src}
                    alt={alt}
                    height={height}
                    width={width}
                  />
                  <span className="etchVisualNavigationStory__card__title">
                    {heading}
                  </span>
                  <p className="etchVisualNavigationStory__card__description">{subHeading}</p>
                </CTARenderer>
              )}
            {!ctaLink
              && (
                <>
                  <Image
                    className={`etchVisualNavigationStory__card__img ${imgClass}`}
                    lazy
                    src={src}
                    alt={alt}
                    height={height}
                    width={width}
                  />
                  <p className="etchVisualNavigationStory__card__title">{heading}</p>
                  <p className="etchVisualNavigationStory__card__description">{subHeading}</p>
                </>
              )}
            {linkList?.length > 0
              && (
                <ul className="etchVisualNavigationStory__link list list--type-plain" data-module-content-id={id}>
                  {linkList.map((links, index) => {
                    const { link, linkText } = links;
                    return link
                      ? (
                        <li className="list__item" key={`vis-nav-text-${index}`}>
                          <CTARenderer className="list__link" ctaLink={link}>
                            {linkText}
                          </CTARenderer>
                        </li>
                      )
                      : (
                        <li className="list__item" key={`vis-nav-text-${index}`}>
                          {linkText}
                        </li>
                      );
                  })}
                </ul>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

EtchVisualNavigationStory.displayName = 'EtchVisualNavigationStory';

EtchVisualNavigationStory.propTypes = {
  opts: PropTypes.shape({
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    type: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    linkList: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      linkText: PropTypes.string
    })),
    id: PropTypes.string
  }).isRequired
};

EtchVisualNavigationStory.defaultProps = {
};

export default EtchVisualNavigationStory;
