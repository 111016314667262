import classNames from 'classnames';

class ListClassSelector {
  constructor() {
    this.optionObj = {};
  }

  getListClass(options) {
    this.optionObj = (typeof options !== 'undefined') ? options : {};
    this.optionObj.liType = (typeof options.liType !== 'undefined') ? options.liType : {};
    return classNames({
      'list--type-plain': (this.optionObj.isNone || this.optionObj.isLinkList
        || this.optionObj.isLargeLinkList || this.optionObj.isHorizontal),
      'list--type-numberList': this.optionObj.isOrdered,
      'list__item list__item--padding-none': this.optionObj.liType.isNone,
      'list__item ': (this.optionObj.liType.isUnordered || this.optionObj.liType.isOrdered
        || this.optionObj.liType.isLinkList),
      'list__item list__item--padding-extra u__bold u__medium': this.optionObj.liType.isLargeLinkList,
      'list__item--type-inline': this.optionObj.liType.isHorizontal,
    });
  }

  get(options) {
    let classString = '';
    if (typeof options.listType !== 'undefined') {
      classString = this.getListClass(options.listType);
    } else {
      classString = classNames(options.display, {
        horizontal: options.horizontal
      });
    }
    return classString;
  }
}

export default ListClassSelector;
