import React from 'react';
import {
  getIframeAnchorTags,
  getUrlParams,
  newRelicClickMetricHandler,
  onTriggerNewRelicAdBlocked,
  triggerNewRelic,
  triggerNewRelicTimeLog
} from './helpers';
import { get } from './axios';
import { htmlAttributes, newRelicConstants } from '../constants';
import { initHeroImpressionWatchers, initImpressionWatchers } from './initImpressionWatchers';
import { BANNER, bannerTypes } from '../constants/constants';

const defaultRevJetBannerConfig = {
  leaderboard: {
    tag: 'tag233656',
    key: '924'
  },
  skyscraper: {
    tag: 'tag170885',
    key: 'aec'
  },
  middle_desktop: {
    tag: 'tag179445',
    key: '6ec'
  },
  middle_mobile: {
    tag: 'tag179446',
    key: 'd0e'
  },
  desktop: {
    tag: 'tag98547',
    key: '82a'
  },
  mobile: {
    tag: 'tag98548',
    key: 'dce'
  },
  hero_desktop: {
    tag: 'tag138278',
    key: 'cd8'
  },
  hero_mobile: {
    tag: 'tag154612',
    key: '82e'
  },
  hero_pro_desktop: {
    tag: 'tag222327',
    key: '2e2'
  },
  hero_pro_mobile: {
    tag: 'tag222328',
    key: '740'
  },
};

const bannersWithMigratedImpressionLogic = ['skyscraper', 'hero', 'middle', 'leaderboard'];

function getRevJetConfig(requestConfig) {
  let revJetBannerConfig = requestConfig?.externalRevJetConfig
    ? requestConfig.externalRevJetConfig : defaultRevJetBannerConfig;
  switch (requestConfig.bannerType) {
  case 'leaderboard':
    return revJetBannerConfig.leaderboard;
  case 'skyscraper':
    return revJetBannerConfig.skyscraper;
  case 'middle':
    if (requestConfig.experienceContext.channel === 'desktop') {
      return revJetBannerConfig.middle_desktop;
    }
    return revJetBannerConfig.middle_mobile;
  case 'hero':
    if (requestConfig.experienceContext.customer.type === 'b2b') {
      if (requestConfig.experienceContext.channel === 'desktop') {
        return revJetBannerConfig.hero_pro_desktop;
      }
      return revJetBannerConfig.hero_pro_mobile;
    }
    if (requestConfig.experienceContext.channel === 'desktop') {
      return revJetBannerConfig.hero_desktop;
    }
    return revJetBannerConfig.hero_mobile;
  default:
    return revJetBannerConfig[requestConfig.experienceContext.channel];
  }
}

function getPubRef(data) {
  const params = getUrlParams(data.banner.bannerURL);
  return decodeURIComponent(params.pub_ref);
}

function getCreativeId(bannerURL) {
  let creativeId = '';

  if (bannerURL && bannerURL.indexOf('cdn.revjet.com') === -1) {
    const anchorElement = document.createElement('a');
    anchorElement.href = bannerURL;
    creativeId = anchorElement.pathname.split('-')[1];
  }

  return creativeId;
}

function getItcParams({ banner = {} }) {
  return {
    campaignId: banner.campaignId ? banner.campaignId : '',
    placementId: banner.placementId ? banner.placementId : '',
    slotId: banner.slotId ? banner.slotId : '',
    creativeId: getCreativeId(banner.bannerURL)
  };
}

function getTag({ channelConfig, data }) {
  const params = getUrlParams(window.location.href);
  let tag = '';

  if (params.mockSponsoredBannerId) {
    tag = channelConfig.tag + '-' + params.mockSponsoredBannerId;
  } else if (params.useSponsoredMock) {
    tag = channelConfig.tag + '-' + getCreativeId(data.banner.bannerURL);
  } else {
    tag = channelConfig.tag;
  }

  return tag;
}

function getFrameConfig({ data, requestConfig }) {

  const channelConfig = getRevJetConfig(requestConfig);
  const itcParams = getItcParams(data);
  // if disableSSR is true
  // channelConfig will be undefined
  if (!channelConfig?.key) {
    return null;
  }
  return {
    thd_itc_suffix: true,
    _tag: getTag({ channelConfig, data, requestConfig }),
    _key: channelConfig.key,
    ct_url: data?.banner?.onClickBeacon,
    piq_slot_id: itcParams.slotId,
    piq_campaign_id: itcParams.campaignId,
    piq_placement_id: itcParams.placementId,
    _opts: {
      embd_tag_id: requestConfig.schemaId,
      autoscale: true,
      b64: getPubRef(data)
    }
  };
}

function isNormalBanner(requestConfig) {
  return requestConfig.bannerType === undefined;
}

function isSkyscraperBanner(requestConfig) {
  return requestConfig.bannerType === 'skyscraper';
}

function isLeaderboardBanner(requestConfig) {
  return requestConfig.bannerType === 'leaderboard';
}

function isPIPBanner(requestConfig) {
  return requestConfig.bannerType === 'middle';
}

function isBannerWithMigratedImpressionLogic(requestConfig) {
  return bannersWithMigratedImpressionLogic.includes(requestConfig.bannerType)
    || requestConfig.bannerType === undefined;
}

function frameLoad({ frameConfig, data, requestConfig, invScriptTime }) {
  const bannerDomElement = window.document.getElementById(frameConfig._opts.embd_tag_id);

  if (bannerDomElement) {
    let impressionBeaconAddTime;
    if (isNormalBanner(requestConfig)) {
      try {
        const carousel = bannerDomElement.closest('.carousel');
        const next = carousel.querySelector('.carousel__next');
        // next.click be gone once placeholder is implemented
        /* istanbul ignore next */
        next.click();
      } catch (error) { /* */ }
    }

    if (bannerDomElement.id === 'hp_sponsored__hero') {
      bannerDomElement.setAttribute(htmlAttributes.DATA_TEST_ID, 'sponsored-hero-banner');
      initHeroImpressionWatchers();
    }

    const itcParams = getItcParams(data);
    // Remove from previous banner
    bannerDomElement.removeAttribute('data-hl-beacon');
    bannerDomElement.setAttribute(htmlAttributes.DATA_CAMPAIGN_ID, itcParams.campaignId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_PLACEMENT_ID, itcParams.placementId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_SLOT_ID, itcParams.slotId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_CREATIVE_ID, itcParams.creativeId);
    bannerDomElement.setAttribute('data-usenewimpressionlogic', true);

    let viewBeacon = data.banner.onViewBeacon;
    viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';

    if (isSkyscraperBanner(requestConfig)) {
      triggerNewRelic(newRelicConstants.SKYSCRAPER_BANNER, newRelicConstants.LOAD);
    }

    if (isLeaderboardBanner(requestConfig)) {
      triggerNewRelic(newRelicConstants.LEADERBOARD_BANNER, newRelicConstants.LOAD);
    }

    if (isPIPBanner(requestConfig)) {
      triggerNewRelic(newRelicConstants.PIP_BANNER, newRelicConstants.LOAD);
    }

    if (isBannerWithMigratedImpressionLogic(requestConfig)) {
      bannerDomElement.setAttribute('onview-beacon-nucleus', viewBeacon);
      impressionBeaconAddTime = Date.now();
    } else {
      bannerDomElement.setAttribute('onview-beacon', viewBeacon);
    }

    // Wait until the iframe content is loaded to add the listeners
    setTimeout(() => {
      const skyscraperAnchorTags = getIframeAnchorTags('skyscraper');
      newRelicClickMetricHandler('skyscraper', skyscraperAnchorTags);

      const leaderboardAnchorTags = getIframeAnchorTags('leaderboard');
      newRelicClickMetricHandler('leaderboard', leaderboardAnchorTags);

      const pipAnchorTags = getIframeAnchorTags('pip');
      newRelicClickMetricHandler('middle', pipAnchorTags);

      const heroAnchorTags = getIframeAnchorTags('hero');
      newRelicClickMetricHandler('hero', heroAnchorTags);
    }, 500);

    if (isNormalBanner(requestConfig)) {
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);
      const standardBannerLoadTime = Date.now();
      // SEND TIME FROM BANNER INITIATED TO AD LOAD
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_LOAD, requestConfig.startTime, standardBannerLoadTime);
      // SEND TIME FROM INNERVATE SCRIPT INJECTION
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD, invScriptTime,
        standardBannerLoadTime);

      // SET BANNER INIT TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, requestConfig.startTime);
      // SET AD LOAD TIMESTAMP ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, standardBannerLoadTime);
      // SET INV SCRIPT INJECT TIME
      bannerDomElement.setAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP, invScriptTime);
      // SET BEACON ADD TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);

      if (window.newrelic && !window.__SponsoredBannerLoaded__) {
        // Dont fire more than once
        window.__SponsoredBannerLoaded__ = true;
        triggerNewRelic(newRelicConstants.STANDARD_BANNER, newRelicConstants.LOAD);
      }
    }
  }
}

function renderFrame({ data, requestConfig, invScriptTime }) {
  if (data) {
    const frameConfig = getFrameConfig({ data, requestConfig });
    // if we dont have frameConfig we dont have all the data to render revjet
    if (frameConfig) {
      frameConfig._opts.onload = () => frameLoad({ frameConfig, data, requestConfig, invScriptTime });
      window.REVJET_Tag(frameConfig);
    }

  }
}

function injectRevJetScript({ data, requestConfig, adType }) {
  let invScriptTime = '';
  const revJetScript = document.createElement('script');
  revJetScript.onerror = () => onTriggerNewRelicAdBlocked(adType);
  revJetScript.onload = () => renderFrame({ data, requestConfig, invScriptTime });
  revJetScript.async = true;
  revJetScript.src = 'https://ads.revjet.com/bg';

  const firstScript = document.getElementsByTagName('body')[0];
  firstScript.parentNode.insertBefore(revJetScript, firstScript);

  // INNERVATE SCRIPT INJECTION START TIME
  const innervateScriptAddedTime = new Date().getTime();
  if (adType === newRelicConstants.STANDARD_BANNER) {
    invScriptTime = innervateScriptAddedTime;
  }
}

function getMockResponse(requestConfig) {
  const { mockSponsoredBannerId } = getUrlParams(window.location.href);
  let revJetConfig = getRevJetConfig(requestConfig);
  let mockRevJetUrl = `http://ads.revjet.com/${revJetConfig.tag}-${mockSponsoredBannerId}`;
  mockRevJetUrl += `?adkey=${revJetConfig.key}&pub_ref=mock`;

  return {
    banner: {
      bannerURL: mockRevJetUrl,
      clickthruURL: 'https://www.homedepot.com/',
      /* eslint-disable-next-line */
      onViewBeacon: 'https://ad-thd-east.tagdelivery.com/impression?id=58c1e65f-1347-4a8e-974e-d1a38c2ff351&x=PQBSrMU8RPJZkBbOrjDSc18Q1ayQXTSmIeTxihTSx1y9TAuGAWAYT6d0LVZ7PodtSlSjEGL3wuSCkAhce75zYtd-2sqdeq-ApKqECLNtI40rBpnEOi566Og3XfUuZsHu0-15T3v3fSc-a7BXv68-EExUCyJFjDF7FlBAnzs1ztKoQWtu2VHTbbD6FKqRRGYRFSG7urhW1pbYdsJ-XPIdtg-L9I-O_rEEcfuW_cPu2-r7wbll5brpZCzuLnnSX5ssi-JC7-an2ySXhP26YTjmLySo4v5i7-KmTyDrmhWLWShNRqc3V43yMUQszMuiaffUgej1g-fhSG6wGdKRbQNexLFce3CmTg_wurbZat-PyhTk-i2D4AJc4qXbxoJvQO3LoIkN0kIMiQMFfuv2jn_7h3-GyF7knjxDEYzBAf6rNtc%3D',
      /* eslint-disable-next-line */
      onClickBeacon: 'https://ad-thd-east.tagdelivery.com/click?id=58c1e65f-1347-4a8e-974e-d1a38c2ff351&x=PQBSrMU8RPJZkBbOrjDSc18Q1ayQXTSmIeTxihTSx1y9TAuGAWAYT6d0LVZ7PodtSlSjEGL3wuSCkAhce75zYtd-2sqdeq-ApKqECLNtI40rBpnEOi566Og3XfUuZsHu0-15T3v3fSc-a7BXv68-EExUCyJFjDF7FlBAnzs1ztKoQWtu2VHTbbD6FKqRRGYRFSG7urhW1pbYdsJ-XPIdtg-L9I-O_rEEcfuW_cPu2-r7wbll5brpZCzuLnnSX5ssi-JC7-an2ySXhP26YTjmLySo4v5i7-KmTyDrmhWLWShNRqc3V43yMUQszMuiaffUgej1g-fhSG6wGdKRbQNexLFce3CmTg_wurbZat-PyhTk-i2D4AJc4qXbxoJvQO3LoIkN0kIMiQMFfuv2jn_7h3-GyF7knjxDEYzBAf6rNtc%3D&redirect=',
      slotId: '1123',
      campaignId: 'abc123',
      placementId: 'xyz123'
    }
  };
}

function buildRevJetBanner({ data, requestConfig, adType }) {

  if (window.REVJET_Tag) {
    renderFrame({ data, requestConfig });
  } else {
    injectRevJetScript({ data, requestConfig, adType });
  }
}

function buildRevJetBannerMock(requestConfig) {
  const data = getMockResponse(requestConfig);

  buildRevJetBanner({ data, requestConfig });
}

function getHeroBannerPosition() {
  const defaultPosition = 2;
  const value = typeof window !== 'undefined' ? window.cookieUtils?.readBrowserCookie('xtt-tntHeroBannerPos') : null;
  return value ? value - 1 : defaultPosition;
}

const buildHeroBannerRequest = (
  { isGuest, experienceContext, externalRevJetConfig, browserId }
) => {
  const bannerChannel = experienceContext?.channel === 'desktop' ? 'desktop' : 'mobileweb';
  const bannerSchema = isGuest ? 'hp_sponsored_auth' : 'hp_sponsored';
  const customerType = experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  const heroBannerPosition = getHeroBannerPosition();
  const heroRequestConfig = {
    schema: bannerSchema,
    experienceContext,
    carouselType: null,
    bannerType: 'hero',
    schemaId: 'hp_sponsored__hero',
    externalRevJetConfig,
  };

  const params = {
    version: 'model2',
    appid: bannerChannel,
    customerType,
    browserid: browserId,
    schema: bannerSchema,
    show: BANNER,
    bannerType: bannerTypes.HERO,
    bannerPosition: heroBannerPosition,
  };
  return { heroRequestConfig, heroBannerPosition, params };
};

const insertHeroBanner = ({
  cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
}) => {
  initImpressionWatchers();
  let tempChild = (
    <div
      id="hp_sponsored__hero"
      key={`item-${newCarousel.length}`}
      className={cx ? cx(classNames) : classNames}
      data-campaignid={data?.banner?.campaignId}
      data-placementid={data?.banner?.placementId}
      data-slotid={data?.banner?.slotId}
      data-creativeid={data?.banner?.creativeId}
      data-onview-beacon-nucleus={data?.banner?.onViewBeacon}
    >
      {newCarousel[0]}
    </div>
  );

  // We do not want to set the banner position
  // to the first and last index.
  if (heroBannerPosition > 0 && heroBannerPosition < newCarousel?.length - 1) {
    newCarousel.splice(heroBannerPosition, 0, tempChild);
    injectRevJetScript({ data, requestConfig: heroRequestConfig });
  }
};

const getHeroBanner = ({
  cx,
  data,
  heroBannerPosition,
  heroRequestConfig,
  classNames,
}) => {
  initImpressionWatchers();
  injectRevJetScript({ data, requestConfig: heroRequestConfig });

  return {
    HeroBanner: (
      <div
        id="hp_sponsored__hero"
        className={cx ? cx(classNames) : classNames}
        data-campaignid={data?.banner?.campaignId}
        data-placementid={data?.banner?.placementId}
        data-slotid={data?.banner?.slotId}
        data-creativeid={data?.banner?.creativeId}
        data-onview-beacon-nucleus={data?.banner?.onViewBeacon}
      />
    ),
    heroBannerPosition,
  };

};

async function getSponsoredHeroBanner({
  newCarousel, cx, classNames, isGuest, experienceContext, externalRevJetConfig, browserId, returnSponsoredHeroOnly
}) {

  const { heroRequestConfig, heroBannerPosition, params } = buildHeroBannerRequest({
    isGuest, experienceContext, externalRevJetConfig, browserId
  });
  try {
    const response = await get({ params });
    triggerNewRelic(newRelicConstants.HERO_BANNER, `API-${response.status}`);
    if (response.status === 200 && response?.data) {
      const { data } = response;
      if (returnSponsoredHeroOnly) {
        return (getHeroBanner({
          cx, data, heroBannerPosition, heroRequestConfig, classNames
        }));
      }
      (
        insertHeroBanner({
          cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
        })
      );
    }

  } catch (error) {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('error-boundary.error', {
        error,
        id: newRelicConstants.HERO_BANNER
      });
    }
  }
  return newCarousel;
}

export {
  buildRevJetBanner, buildRevJetBannerMock, getFrameConfig, frameLoad, getSponsoredHeroBanner, getRevJetConfig
};
