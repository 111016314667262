import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MillworksContent.scss';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { RevJetTag } from '@revjet/react-revjet-tag';

export const getDefaultChannelConfig = (channel) => {
  const defaults = {
    responsive: false,
    autoscale: true,
    deliveryMethod: 'banner',
    customDomain: 'rma.homedepot.com'
  };
  const config = {
    mobile: {
      placementId: '48146786',
      size: '375x450',
      tagId: 'tag154512',
      key: '298'
    },
    desktop: {
      placementId: '48146666',
      size: '1420x350',
      tagId: 'tag154511',
      key: '4c3'
    }
  };
  return { ...defaults, ...config[channel] };
};
export const getUserChannelConfig = (opts, channel) => {
  if (opts?.config?.config) {
    return { ...opts.config.config[channel] };
  }
  return null;
};

export const initializeMillworks = (storeId, channel, opts) => {
  const defaultChannelConfig = getDefaultChannelConfig(channel);
  const userChannelConfig = getUserChannelConfig(opts, channel);
  const finalConfig = { ...defaultChannelConfig, ...userChannelConfig };
  if (finalConfig) {
    return {
      _tag: finalConfig.tagId,
      _plc_id: finalConfig.placementId,
      _key: finalConfig.key,
      _opts: {
        embd_tag_id: (function (doc) {
          let tag = doc.createElement('div');
          tag.id = 'revjet-tag-' + ((window.revjet || {}).tags || []).length;
          let configScript = doc.getElementsByTagName('script');
          configScript[configScript.length - 1].parentNode.insertBefore(tag, configScript[configScript.length - 1]);
          return tag.id;
        }(document)),
        custom_domain: finalConfig.customDomain,
        responsive: finalConfig.responsive,
        autoscale: finalConfig.autoscale,
        delivery_method: finalConfig.deliveryMethod,
        _imp_banner_size: finalConfig.size
      },
      thd_store_id: storeId
    };
  }
  return null;
};

export const removeExtraRevjetScripts = () => {
  const revjetDiv = document.querySelector('[id^="revjet-tag-"]');
  const revjetScript = document.getElementById('revjet-id');
  if (revjetDiv) {
    revjetDiv.remove();
  }
  if (revjetScript) {
    revjetScript.remove();
  }
};

const MillworksContent = ({ opts }) => {
  const { storeId } = useStore({ varnish: false });
  const { channel } = useContext(ExperienceContext);
  const [runTagProperties, setRunTagProperties] = useState();
  useEffect(() => {
    if (storeId) {
      setRunTagProperties(initializeMillworks(storeId, channel, opts));
    }
  }, [storeId]);
  useEffect(() => {
    return () => {
      removeExtraRevjetScripts();
    };
  }, []);
  return runTagProperties ? <RevJetTag tagProperties={runTagProperties} /> : null;
};

MillworksContent.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default MillworksContent;
