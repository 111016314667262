import React, { useState, useEffect, useContext } from 'react';
import { string, bool, shape } from 'prop-types';
import { ExperienceContext, useMVCSID } from '@thd-nucleus/experience-context';
import classNames from 'classnames/bind';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { makeApiRequest, shouldMakeApiRequest } from '../../utils/requestHelpers';
import { getSchema, getSkus } from '../../utils/helpers';
import styles from './SponsoredSkyscraperBanner.module.scss';

const isValidScenario = ({
  schemaId, lastRequestScenario, experienceContext, pageContext, customRenderId, schema, browserId
}) => {

  if (schemaId.includes('event_browse_sponsored')) {

    return false;
  }

  const makeRequest = shouldMakeApiRequest({
    lastRequestScenario,
    experienceContext,
    pageContext,
    customRenderId,
    schema,
    browserId
  });

  return makeRequest;
};

const SponsoredSkyscraperBannerComponent = (props) => {
  const [lastRequestScenario, setLastRequestScenario] = useState({});
  const [hasData, setHasData] = useState(false);
  const experienceContext = useContext(ExperienceContext);
  const { pageContext, customRenderId, slugId } = props;
  const browserId = useMVCSID();
  const channel = experienceContext.channel === 'desktop' ? 'desktop' : 'mobile';

  let { schema } = props;
  const cx = classNames.bind(styles);

  schema = getSchema({ schema, pageContext });
  const schemaId = schema + '__skyscraper';

  useEffect(() => {
    const validScenario = isValidScenario({
      schemaId, lastRequestScenario, experienceContext, pageContext, customRenderId, schema, browserId
    });

    if (validScenario && channel === 'desktop') {
      setLastRequestScenario({
        url: window.location.pathname + window.location.search,
        skus: getSkus(pageContext),
      });

      makeApiRequest({
        browserId,
        schema,
        schemaId,
        experienceContext,
        pageContext,
        setHasData,
        bannerType: 'skyscraper',
        slugId,
        ignoreStoreId: true
      });

    }

  }, [pageContext?.data, schema, slugId, browserId]);

  useEffect(() => {
    initImpressionWatchers();
  }, [hasData]);

  if (!hasData) {
    return null;
  }

  return (
    <div id="sponsored-skyscraper-banner" className={cx('sponsored_skyscraper_banner')} data-component="SponsoredSkyscraperBanner">
      <div id={schemaId} data-testid="sponsored-skyscraper-banner" />
    </div>
  );
};

SponsoredSkyscraperBannerComponent.propTypes = {
  customRenderId: string,
  schema: string,
  slugId: string,
  pageContext: shape({
    label: string,
    data: shape(),
    keyword: string,
    isSearch: bool,
    isCategory: bool,
    isBrowse: bool,
  }),
};

SponsoredSkyscraperBannerComponent.defaultProps = {
  customRenderId: '',
  schema: '',
  slugId: '',
  pageContext: {},
};

const SponsoredSkyscraperBanner = withDynamicComponent(
  withHydrator(
    {
      id: 'sponsored-skyscraper-banner',
      preserveCtxVal: 'clientStore'
    },
    SponsoredSkyscraperBannerComponent
  )
);

export { SponsoredSkyscraperBanner };
