import React from 'react';
import PropTypes from 'prop-types';
import './EtchRichText.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';
import PopularCategories from '../PopularCategories';
import * as analytics from '../../analytics';

const EtchRichText = (props) => {

  const { opts = {}, channel } = props;
  const {
    alignment,
    html,
    id = 'richtext'
  } = opts;

  const sanitizedHtml = `${html}`.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

  if (html?.includes('cm_sp=popcats')) {
    return (
      <PopularCategories opts={opts} channel={channel}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: id
        })}
      </PopularCategories>
    );
  }

  if (sanitizedHtml?.indexOf('playVideo') !== -1) {
    const newHTML = sanitizedHtml?.replace(/javascript:playVideo\(([^)]+)\)/, '#');
    const matchesVideo = sanitizedHtml?.match(/javascript:playVideo\(([^)]+)\)/);
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CTARenderer ctaLink={matchesVideo?.[0]}>
        <div
          id={id}
          className={`etchRichText etchRichText--${alignment}`}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: newHTML }}
        />
      </CTARenderer>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div
      id={id}
      className={`etchRichText etchRichText--${alignment}`}
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

EtchRichText.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string,
    html: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
      includes: PropTypes.shape({})
    })]),

  }).isRequired,
  channel: PropTypes.string
};
EtchRichText.defaultProps = {
  channel: ''
};

export default EtchRichText;
