export const newRelicConstants = {
  // ad types
  STANDARD_BANNER: 'sponsoredStandardBanner',
  LEADERBOARD_BANNER: 'sponsoredLeaderboardBanner',
  SKYSCRAPER_BANNER: 'sponsoredSkyscraperBanner',
  PIP_BANNER: 'sponsoredPipBanner',
  PIP_PLA: 'sponsoredPipPla',
  CAROUSEL_PLA: 'sponsoredBottomCarousel',
  HERO_BANNER: 'sponsoredHeroBanner',
  NON_ENDEMIC_BANNER: 'nonEndemicBanner',
  CAROUSEL_SEO: 'sponsoredPipsemCarousel',
  PRODUCT_LISTING_AD: 'sponsoredProductListingAd',
  DEFAULT_BANNER: 'defaultStandardBanner',

  // event types
  LOAD: 'load',
  IMPRESSION: 'impression',
  CLICK: 'click',
  AD_BLOCKED: 'adblocker_enabled',

  // latency tracking
  STANDARD_BANNER_API_RESPONSE: 'sponsored-banner.time-until-api-response',
  STANDARD_BANNER_AD_LOAD: 'sponsored-banner.time-until-ad-load',
  STANDARD_BANNER_AD_INIT_TO_WINNER: 'sponsored-banner.time-from-init-to-winner',
  STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'sponsored-banner.time-from-innervate-script-to-load',
  STANDARD_BANNER_AD_INIT_TO_IMPRESSION: 'sponsored-banner.time-from-init-to-impression',
  STANDARD_BANNER_AD_LOAD_TO_IMPRESSION: 'sponsored-banner.time-from-load-to-impression',
  STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'sponsored-banner.time-from-innervate-script-to-impression',
  STANDARD_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'sponsored-banner.time-from-beacon-add-to-impression',
  NON_ENDEMIC_BANNER_REQUEST: 'nonendemic-banner.time-until-requested',

  // a/b testing metric
  PIP_BANNER_TARGETING_MODEL: 'sponsoredPipBanner-targeting-model'
};
export const newRelicDataTestId = {
  'sponsored-standard-banner': newRelicConstants.STANDARD_BANNER,
  'sponsored-skyscraper-banner': newRelicConstants.SKYSCRAPER_BANNER,
  'sponsored-leaderboard-banner': newRelicConstants.LEADERBOARD_BANNER,
  'sponsored-product-pod': newRelicConstants.CAROUSEL_PLA,
  'sponsored-hero-banner': newRelicConstants.HERO_BANNER,
  'sponsored-pip-banner': newRelicConstants.PIP_BANNER,
  'sponsored-product-listing': newRelicConstants.PRODUCT_LISTING_AD,
  'default-standard-banner': newRelicConstants.DEFAULT_BANNER
};
