import React from 'react';
import PropTypes from 'prop-types';
import PrivacyForm from '../PrivacyForm';
import RedBeacon from '../RedBeacon';

function FormType(opts) {
  const { options } = opts;
  const { legacySupportType } = options;
  const redBeaconConfig = [
    'isNhance',
    'isTubShowerInstallation',
    'isBathRemodeling',
    'isClosetAndStorageInstallation',
    'isElectricalServicesAndInstalltion',
    'isFenceInstallation',
    'isGeneratorInstallation',
    'isInteriorShutters',
    'isSolarPowerSystems',
    'isSunroomAndPatioCoverInstallation',
    'isWaterHeaterInstallation',
    'isWaterSoftnerInstallation',
    'isWaterHeaterRepair',
    'isHeatingAndCoolingSystemReplacement',
    'isHvacMaintenance',
    'isHeatingAndCoolingSystemRepair',
    'isTanklessWaterHeaterInstallation',
    'isKitchenRemodelCore',
    'isShowerDoorInstallation',
    'isWalkinTubs',
  ].join();

  switch (true) {
  case (legacySupportType === 'isPrivacyForm'):
    /* eslint-disable-next-line react/destructuring-assignment */
    return (<PrivacyForm opts={opts.opts} />);
  case (redBeaconConfig.indexOf(legacySupportType) > -1):
    return (<RedBeacon serviceType={legacySupportType} />);
  default:
    return (<div>component type not found: {legacySupportType}</div>);
  }
}

/**
 * @group Legacy
 * Router for legacy form types
 */

const LegacySupport = (props) => {
  const { opts = {} } = props;
  return (<FormType options={opts} />);
};

LegacySupport.propTypes = {
  opts: PropTypes.shape({
    legacySupportType: PropTypes.string
  }).isRequired,
};

export default LegacySupport;
