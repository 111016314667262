/* eslint-disable */
import classNames from 'classnames';

class FontColorSelector {
  constructor() {
  }
  getFontClass(options) {
    return classNames({
      'u__text--brand': options.isthdOrange,
      'thdBlack': options.isthdBlack,
      'u__text--dark': options.isthdGray20,
      'u___text--primary': options.isthdGray40,
      'thdGray': options.isthdGray,
      'thdGray60': options.isthdGray60,
      'thdGrayBB': options.isthdGrayBB,
      'u__text--light': options.isthdGray80,
      'thdGrayDD': options.isthdGrayDD,
      'u__text--faded': options.isthdGrayEE,
      'u__text--reverse': options.isthdWhite,
      'thdListhighlight': options.isthdListhighlight,
      'thdSeasonLink': options.isthdSeasonLink,
      'thdHeaderBg': options.isthdHeaderBg,
      'thdGardenClub': options.isthdGardenClub,
      'thdHomeService': options.isthdHomeService,
      'thdSpecialBuy': options.isthdSpecialBuy,
      'thdHoliday': options.isthdHoliday,
      'thdClearance': options.isthdClearance,
      'thdBulkPrice': options.isthdBulkPrice,
      'thdNLPGreen': options.isthdNLPGreen,
      'thdOutdoorGarden': options.isthdOutdoorGarden,
      'u__text--danger': options.isthdDanger,
      'u__text--alternate': options.isthdAlternate,
      'u__text--success': options.isthdSuccess,
      'u__text--warning': options.isthdWarning,
    });
  }
  get(options) {
    let classString = '';
    if (typeof options.colorPicker !== 'undefined' && options.colorPicker) {
      classString = this.getFontClass(options.colorPicker);
    } else {
      classString = classNames(options.colorclass);
    }
    return classString;
  }
}

export default FontColorSelector;
