/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';

import './TitleBar.scss';

/**
 * @group Components
 * Displays article titles
 */

const TitleBar = (props) => {
  const { opts = {} } = props || {};
  const { options = {} } = opts;
  const {
    level, duration, print, pdf, URL = ''
  } = options;

  const printPage = (event) => {
    event.preventDefault();
    if (typeof window !== 'undefined') {
      window.print();
    }
  };

  return (
    <div className="title howto-titleBar grid">
      <div className="skills col-1-2">
        {
          level && <span className={`difficulty ${level}`} />
        }
        {
          duration && <span className={`duration ${duration}`}>{duration}</span>
        }
      </div>
      <div id="socialWidgetTable" className="col-1-2 widgetEmailPrintTable div-right">
        {
          print && <div className="widget"><a href="#" onClick={printPage} name="print-page"><i className="dwarf-printIcon" /></a></div> /* eslint-disable-line */
        }
        {
          // eslint-disable-next-line max-len
          pdf && <div className="widget"><a href={URL} name="pdf-page"><i className="icon-pdfIcon" /></a></div>
        }
      </div>
    </div>
  );
};

TitleBar.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
  }).isRequired

};

export default TitleBar;
