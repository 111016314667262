import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import classNames from 'classnames/bind';

import { Image } from '@thd-olt-component-react/core-ui';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import styles from './MobileCategoryTab.module.scss';

const cNames = classNames.bind(styles);

const MobileCategoryTab = ({ category }) => {
  const { specialBuyEndpoint } = useSpecialBuy();
  const { categoryId, name, image, itemIds } = category;

  return (
    <a
      href={`${specialBuyEndpoint}#${categoryId}`}
      className={cNames('category-tab')}
    >
      <div className={cNames('category-tab__images')}>
        <Image
          alt={`${name} product image`}
          src={image.url}
          className={cNames('category-tab__image')}
          width={1}
          height={1}
        />
      </div>
      <span className={cNames('category-tab__text')}>{name} ({itemIds.length})</span>
    </a>
  );
};

MobileCategoryTab.propTypes = {
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
    image: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired
};

export { MobileCategoryTab };
