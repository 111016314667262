import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Rebates extends Component {

  componentDidMount = async () => {
    try {
      this.initRebatesData();
      await this.loadExternalJS();
    } catch (err) {
      console.log(err);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.zipCode !== prevProps.zipCode) {
       this.initRebatesData();
    }
  };

  static promise;

  initRebatesData = () => {
    const {
      itemId,
      zipCode,
      optionalText,
      rebateClasses,
      multi
    } = this.props;
    if (typeof window === 'undefined') return;
    window._ecr = window._ecr || {}; // eslint-disable-line
    // window._ecr has different values to be populated for different script file and
    // hence this condition of multi is in place
    if (multi) {
      _ecr['ecorebates.zip'] = zipCode; // eslint-disable-line
      _ecr.options = { // eslint-disable-line
        zip: zipCode,
        headingText: '',
        openDetailsText: optionalText,
        openDetailsClasses: rebateClasses
      };
      _ecr[`rebate-${itemId}`] = itemId; // eslint-disable-line
    } else {
      _ecr.ecorebates = itemId; // eslint-disable-line
      _ecr.options = { // eslint-disable-line
        headingText: '',
        zip: zipCode,
        openDetailsText: optionalText,
        openDetailsClasses: rebateClasses
      };
    }
  }

  loadExternalJS = async () => {
    if (Rebates.promise) return Rebates.promise;
    const { multi } = this.props;

    Rebates.promise = new Promise((resolve, reject) => {
      // determine the eco rebates script to be loaded based on the page type or
      // whether multiple rebates scenarios will be displayed or just a single one
      const path = multi ? '/ui/widgets/thd/product_plp.js' : '/ui/widgets/thd/product.js';
      const host = window.location.protocol === 'https:'
        ? 'https://d39vf9bwtb4sua.cloudfront.net'
        : 'http://static.ecorebates.com';

      const ecrScriptUrl = host + path;
      const scr = document.createElement('script');
      scr.src = ecrScriptUrl;
      scr.onload = resolve;
      scr.onerror = reject;
      document.body.appendChild(scr);
    });
    return Rebates.promise;
  }

  render() {
    const { multi, itemId } = this.props;
    return (
      <div>
        {multi && <div className="rebate-container" id={`rebate-${itemId}`} />}
        {!multi && <div id="ecorebates" />}
      </div>
    );
  }
}

Rebates.propTypes = {
    /**
   * To show the Item ID
   */
  itemId: PropTypes.string,
    /**
   * To show the Zipcode
   */
  zipCode: PropTypes.string,
    /**
   * To show the optional text
   */
  optionalText: PropTypes.string,
    /**
   * To show the rebate classes scripts
   */
  rebateClasses: PropTypes.string,
   /**
   * To determine which eco rebates script to be loaded
   */
  multi: PropTypes.bool // prop to determine which eco rebates script to be loaded
};

Rebates.defaultProps = {
  itemId: null,
  optionalText: null,
  rebateClasses: null,
  zipCode: null,
  multi: false
};

export default Rebates;