import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './LifeStyleBanner.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

/**
 * @group Layout
 * Displays Life style banner component
 */

const LifeStyleBanner = (props) => {
  const { opts = {} } = props;
  const {
    id,
    ctaLink,
    heading,
    subHeading,
    backgroundImage = {}
  } = opts;
  const { src, alt = '', height, width } = backgroundImage;
  const imgClass = classNames({
    'img--block': height && width
  });

  return (
    <div
      id="EtchLifeStyleBanner"
      data-module-id={id}
    >
      <CTARenderer ctaLink={ctaLink}>
        <div className="imageBanner">
          <Image alt={alt} src={src} height={height} width={width} className={imgClass} />
        </div>
        <div>
          <h2>{heading}</h2>
          <h4>{subHeading}</h4>
        </div>
      </CTARenderer>
    </div>
  );
};

LifeStyleBanner.propTypes = {
  opts: PropTypes.shape({
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    backgroundImage: PropTypes.shape({
      src: PropTypes.string
    })
  }).isRequired
};

export default LifeStyleBanner;
