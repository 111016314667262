import React from 'react';
import PropTypes from 'prop-types';
import './RowHeader.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const RowHeader = (props) => {
  const { opts = {} } = props;
  const {
    id,
    heading,
    cta,
    ctaLink,
    subHeading
  } = opts;
  return (
    <>
      <div className="row-header" id={id}>
        <div className="row-header__title">
          <span className="row-header__text">{heading}</span>
          {cta
            && (
              <CTARenderer className="row-header__link" ctaLink={ctaLink}>
                {cta}
              </CTARenderer>
            )}
        </div>
      </div>
      <div className="page-subheader">{subHeading}</div>
    </>
  );
};

RowHeader.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
  }).isRequired
};
RowHeader.defaultProps = {
};

export default RowHeader;
