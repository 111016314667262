import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import './EtchBlockHeaderStory.scss';
import './EtchBlockHeaderStoryReverse.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchBlockHeaderStory = (props) => {
  const { opts = {} } = props;
  const {
    cta,
    ctaLink,
    isEtchBlockHeaderStory,
    isEtchBlockHeaderStoryReverse,
    heading,
    id,
    listLink = [{}],
    podList = [{}],
    subHeading,
  } = opts;
  const wrapperClass = classNames({
    EtchBlockHeaderStory: isEtchBlockHeaderStory,
    EtchBlockHeaderStoryReverse: isEtchBlockHeaderStoryReverse
  });
  return (
    <div className={wrapperClass} data-module-id={id}>
      <div className={`${wrapperClass}__padding`}>
        <div className={`${wrapperClass}__copyBlockHeader`}>
          <p className={`${wrapperClass}__heading  u__large u__bold u--paddingBottom`}>
            { heading }
          </p>
          <p className={`${wrapperClass}__subHeading u__medium u--paddingBottom`}>
            { subHeading }
          </p>
          {ctaLink && (
            <CTARenderer className="bttn bttn--primary bttn--inline" ctaLink={ctaLink}>
              <span className="bttn__content">{ cta }</span>
            </CTARenderer>
          )}
          <div className="u--paddingTop">
            <ul className={`${wrapperClass}__list list list--type-plain`}>
              { listLink
                && listLink.map((links, index) => {
                  return (
                    <li className={`${wrapperClass}__listItem list__item`} key={`list-item${index}`}>
                      {links.link
                      && (
                        <CTARenderer ctaLink={links.link} className="list__link">
                          { links.linkText }
                        </CTARenderer>
                      )}
                      {!links.link
                        && <p> { links.linkText } </p>}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className={`${wrapperClass}__podContainer`}>
          { podList
            && podList.map((pod, index) => {
              const { podMedia = {} } = pod;
              const { src, alt, height, width } = podMedia;
              const imgClass = classNames({
                'img--block': height && width
              });
              return (
                <div className={`${wrapperClass}__pod`} key={`pod-${index}`}>
                  <Image
                    className={`${wrapperClass}__podImage ${imgClass}`}
                    src={src}
                    alt={alt}
                    height={height}
                    width={width}
                  />
                  <p className="u__medium u--paddingBottom">{ pod.podHeader }</p>
                  <p className="u--paddingBottom">{ pod.podSubHeader }</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

EtchBlockHeaderStory.propTypes = {
  opts: PropTypes.shape({
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cta: PropTypes.string,
    isEtchBlockHeaderStory: PropTypes.bool,
    heading: PropTypes.string,
    id: PropTypes.string,
    listLink: PropTypes.instanceOf(Array),
    podList: PropTypes.instanceOf(Array),
    subHeading: PropTypes.string,
  }).isRequired
};
EtchBlockHeaderStory.defaultProps = {
};

export default EtchBlockHeaderStory;
