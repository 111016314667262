import React from 'react';
import PropTypes from 'prop-types';

const EtchMyStore = (props) => {

  const { opts = {} } = props;
  const { id } = opts;

  return (
    <div id="myStoreModule" className="etch-analytics" />
  );
};

EtchMyStore.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

EtchMyStore.defaultProps = {
};

export default EtchMyStore;
