import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';

const PLPSEO = (props) => {
  /*
    Adding a feature switch to hide PLPSEO based upon n-values
    in favor of showing a new component ProductCategoryContent.
    Contact: Thomas McGuire (txm17yv)
    Slack Channel: #seo-ing
  */
  const hidePLPSEOByNValues = useConfigService('fs:hidePLPSEOByNValues') || '';
  const { opts = {}, channel, children, navParam } = props;

  if (hidePLPSEOByNValues?.split(',')?.includes(navParam)) {
    return null;
  }

  const {
    options,
    id,
    RowHeadtext,
    Paragraphtext
  } = opts;

  return (
    <div>
      <div className="rowHeaderDiv">
        <h2 className="row-header align-l plp-header">
          <div className="row-header__title">
            {RowHeadtext
            && (
              <span className="row-header__text">
                {RowHeadtext}
              </span>
            )}
            {options.hasLink
            && (
              <a
                href={options.url}
                className="row-header__link"
                target={options.targetBlank ? '_blank' : ''}
                rel="noreferrer"
              >
                {options.linkText}
              </a>
            )}
          </div>
        </h2>
      </div>
      {options.addParagraph
      && (
        <div className="paragraphDiv">
          {options.url ? (
            <p className="content u__regular u__text--dark">
              <a href={options.url}>
                {Paragraphtext}
              </a>
            </p>
          ) : (
            /* eslint-disable-next-line react/no-danger */
            <div dangerouslySetInnerHTML={{ __html: Paragraphtext }} />
          )}
        </div>
      )}
      {options.addList
      && (
        <div className="plpSeo__list">
          {children}
        </div>
      )}
    </div>
  );
};

PLPSEO.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    RowHeadtext: PropTypes.string,
    Paragraphtext: PropTypes.string,
    options: PropTypes.shape({})
  }).isRequired,
  navParam: PropTypes.string,
  channel: PropTypes.string,
  children: PropTypes.instanceOf(Array)
};

PLPSEO.defaultProps = {
  channel: 'desktop',
  children: [],
  navParam: ''
};

export default PLPSEO;
