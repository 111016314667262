import { useEffect } from 'react';
import { newRelicConstants } from '../../constants/newRelicConstants';
import { mkTelemetryField, triggerNewRelic } from '../helpers';

export const useProductListingTelemetry = (props) => {
  const {
    plaNode,
    data,
    loading,
    error,
    product,
    requestedAdCount,
    renderComponent
  } = props;

  /**
   * IMPRESSION
   *
   * Triggers product-pod 'impression' event to NewRelic by listening to the
   * LIFE_CYCLE_EVENT_BUS.
   *
   */
  useEffect(() => {
    if (!product) return undefined;

    const handler = ({ output }) => {
      const { impression, container } = output;

      const isContainerComponentValid = container?.component === 'SponsoredProductListing';
      const isImpressionComponentValid = impression?.component === 'ProductPod';
      const isItemIdValid = product.itemId === impression.id;

      if (!isContainerComponentValid || !isImpressionComponentValid || !isItemIdValid) return;

      const value1 = mkTelemetryField({ key: 'itemId', value: impression?.id });
      const value2 = mkTelemetryField({ key: 'trackSource', value: impression?.trackSource });
      const value3 = mkTelemetryField({ key: 'position', value: impression?.position });
      const value4 = mkTelemetryField({ key: 'slotId', value: impression?.slotId });
      const telemetryFields = `${value1}-${value2}-${value3}-${value4}`;

      const kpiName = `${newRelicConstants.PRODUCT_LISTING_AD}-${telemetryFields}`;
      const kpiValue = newRelicConstants.IMPRESSION;

      triggerNewRelic(kpiName, kpiValue);
    };

    window.LIFE_CYCLE_EVENT_BUS.on('impression.trigger', handler);

    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('impression.trigger', handler);
    };
  }, [product]);

  /**
   * PRODUCT_LISTING_AD_MOUNT
   */
  useEffect(() => {
    // check
    if (plaNode) triggerNewRelic(`${newRelicConstants.PRODUCT_LISTING_AD}`, newRelicConstants.PRODUCT_LISTING_AD_MOUNT);

  }, [plaNode]);

  /**
   * PRODUCT_LISTING_AD_NOT_MOUNTED
   */
  useEffect(() => {
    // check
    if (loading || (!error && product === null) || plaNode || (product && renderComponent)) return; // case: skipped, mounted

    // --
    let reason = 'other';
    if (error) reason = 'error_from_graphql';
    else if (!data?.plaModel?.products?.length) reason = 'zero_products_from_graphql';
    else if (!product) reason = 'zero_products_after_filtering';
    else if (!renderComponent) reason = 'render_flag_is_false';

    // --

    const kpiName = `${newRelicConstants.PRODUCT_LISTING_AD}-${newRelicConstants.PRODUCT_LISTING_AD_NOT_MOUNTED}`;
    const kpiValue = mkTelemetryField({ key: 'reason', value: reason });

    triggerNewRelic(kpiName, kpiValue);

  }, [
    loading,
    error,
    plaNode,
    product,
    renderComponent,
    data?.plaModel?.products?.length,
  ]);

};
