import React from 'react';
import PropTypes from 'prop-types';
import './MyStore.scss';
/**
 * @group Personalization
 * Placeholder for mystore module
 */

const MyStoreModule = (props) => {
  const { opts = {} } = props;
  return (
    <div id="myStoreModule" data-id={opts.id} data-type={opts.type} className="col__12-12" />
  );
};

MyStoreModule.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired
};

export default MyStoreModule;
