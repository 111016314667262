/* eslint-disable */
import classNames from 'classnames';

class FontSelector {
    constructor() {
    }

    get(options = {}) {
      const { titleType = {} } = options;
      return classNames({
        'u__display-font--black': titleType.isHomerveticaBlack,
        'u__display-font--medium': titleType.isHomerveticaMedium,
        'u__normal': titleType.isNormal,
        'u__legal': titleType.isLegal,
        'u__medium': titleType.isMedium,
        'u__husky': titleType.isHusky,
        'u__large': titleType.isLarge,
        'u__xlarge': titleType.isXlarge,
        'u__jumbo': titleType.isJumbo,
        'u__xtreme': titleType.isXtreme
      })
    }
}

export default FontSelector
