export const MetaData = {
  type: 'EtchAtomic',
  label: 'Media Video',
  name: 'EtchVideo',
  version: '2.0'
};

export const Data = {
  name: '',
  mediaData: {
    src: 'https://www.youtube.com/embed/cTF5YFpZ7yc?rel=0&amp;wmode=opaque',
    type: 'video',
    clipStartTime: '',
    clipEndTime: '',
    embedFullVideo: true
  }
};

export const DataConfig = [
  {
    label: 'Name',
    field: 'name',
    type: 'text'
  },
  {
    label: 'Video Source',
    field: 'mediaData',
    type: 'assetLoader',
    assetTypes: ['video']
  }
];
