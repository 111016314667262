import {
  arrayOf as arrayType,
  params,
  string as stringType,
  extend,
  customType
} from '@thd-nucleus/data-sources';
import { VisualCategoryNavigation } from '@thd-olt-component-react/visual-category-navigation';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { Endcap } from '@thd-olt-component-react/endcap';
import { dataModel as dpdSearchModel } from '../hooks/dpdDataModel';

export const dataModel = extend({
  searchModel: params({ keyword: stringType(), storeId: stringType(), navParam: stringType() }).shape({
    templates: arrayType(stringType()),
    partialTemplates: arrayType(stringType())
  }),
  content: params({ name: stringType().isRequired(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    contentName: stringType() }).shape({
    templates: arrayType(stringType())
  })
},
VisualCategoryNavigation,
dpdSearchModel,
SpecialBuyBanner,
Endcap
);
