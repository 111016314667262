import React from 'react';
import PropTypes from 'prop-types';

/**
  * @group THD Home Components
  * SponsoredProducts container
  */

const SponsoredProducts = (props) => {
  const { opts = {} } = props;
  return (
    <div id="rr_homepage_sponsoredproducts" data-id={opts.id} data-type={opts.type} className="col__12-12" />
  );
};

SponsoredProducts.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired
};
SponsoredProducts.defaultProps = {
};

export default SponsoredProducts;
