import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';

const HeroImage = (props) => {
  const { options = {} } = props;
  let imageBackground = options.backgroundImage ? options.backgroundImage : '';
  imageBackground = imageBackground.replace(/^url\(['"]?([^'"]+)['"]?\)/, '$1');
  const { height, width, name, alt = 'image' } = options;
  const imgClass = classNames({
    'img--block': height && width
  });
  if (options.URL) {
    return (
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */
      <a
        className={classNames({
          triggerOverlay: options.openInModal,
          'tip-Opener': options.isToolTip
        })}
        data-overlay-type={options.relationship}
        data-tooltop={(options.isToolTip) ? options.URL : ''}
        href={options.URL}
        target={(options.targetBlank) ? '_blank' : ''}
        rel={`${(options.externalLink) ? 'nofollow' : ''}${(options.targetBlank) ? ' noopener noreferrer' : ''}`}
      >
        <Image
          stretchy
          className={imgClass}
          src={imageBackground}
          id={name}
          alt={alt}
          height={height}
          width={width}
        />
      </a>
    );
  }
  return (
    <Image
      stretchy
      src={imageBackground}
      id={name}
      alt={alt}
      height={height}
      width={width}
      className={imgClass}
    />
  );
};

HeroImage.propTypes = {
  options: PropTypes.shape({}).isRequired
};

export default HeroImage;
