import React, { useState, useRef } from 'react';
import {
  string
} from 'prop-types';
import { Popover, Link, Typography } from '@one-thd/sui-atomic-components';

export const Prop65 = (props) => {
  const { prop65Message } = props;
  const linkRef = useRef();
  const [showPopover, setShowPopover] = useState(false);
  const warningSplit = prop65Message?.split(' - ');
  const warningNoLink = warningSplit?.[0].split('www.p65warnings.ca.gov');
  return (
    <div className="sui-flex sui-flex-row">
      <div>California residents</div>
      &nbsp;
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={() => setShowPopover((show) => !show)} ref={linkRef}>see Prop 65 WARNINGS</Link>
      <Popover
        open={showPopover}
        onClose={() => setShowPopover(false)}
        anchorEl={linkRef.current}
        disablePortal
        title="Warning"
        style={{ 'z-index': '100' }}
      >

        <Typography>
          {warningNoLink?.[0] || 'Warning: Cancer and Reproductive Harm '}
              &nbsp;-
          <Link href="https://www.p65warnings.ca.gov"> www.p65warnings.ca.gov</Link>
        </Typography>
      </Popover>
    </div>
  );
};

Prop65.displayName = 'Prop65';

Prop65.propTypes = {
  prop65Message: string
};

Prop65.defaultProps = {
  prop65Message: null
};
