/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
// eslint-disable-next-line import/no-cycle
import Content from '../Content/Content.component';
import { EtchPlaceholder } from '../Placeholder/EtchPlaceholder';

class Etch extends Component {

  getEtchContent = () => {
    const props = {
      ...this.props
    };
    const {
      channel,
      contentType,
      deliveryZip,
      dimensions,
      disableSwipeNavigation,
      dpdNewTab,
      dpdResponse,
      env,
      favorites,
      isCategory,
      keyword,
      linkType,
      navParam,
      onFavoriteChange,
      onVisNavRender,
      parentNavParam,
      partialTemplates,
      relatedResults,
      showArrows,
      showDpdPod,
      sku,
      storeId,
      templates,
      visualNavPromos,
      showFavoritesCount,
      bannerData
    } = props;
    let etchContent = null;
    const { exclude, include } = this.props;
    if (templates.length) {
      etchContent = templates.reduce((acc, template, templateIndex) => {
        return [
          ...acc,
          ...template.Content.map((content, contentIndex) => (
            <Content
              channel={channel}
              contentType={contentType}
              deliveryZip={deliveryZip}
              dimensions={dimensions}
              disableSwipeNavigation={disableSwipeNavigation}
              dpdNewTab={dpdNewTab}
              dpdResponse={dpdResponse}
              env={env}
              exclude={exclude}
              favorites={favorites}
              include={include}
              isCategory={isCategory}
              key={`etch--${templateIndex}-${content.id}-${contentIndex}`}
              keyword={keyword}
              linkType={linkType}
              navParam={navParam}
              onFavoriteChange={onFavoriteChange}
              onVisNavRender={onVisNavRender}
              opts={content}
              parentNavParam={parentNavParam}
              partialTemplates={partialTemplates}
              showArrows={showArrows}
              sku={sku}
              storeId={storeId}
              visualNavPromos={visualNavPromos}
              visualNavResults={relatedResults}
              showFavoritesCount={showFavoritesCount}
              showDpdPod={showDpdPod}
              bannerData={bannerData}
            />
          ))
        ];
      }, []);
    }
    return etchContent;
  };

  render() {
    const {
      templates = []
    } = this.props;

    const content = this.getEtchContent({ ...this.props });
    // Need to have templates or template path to render
    if (!(templates.length)) {
      return null;
    }
    return (
      <LoadingPlaceholder
        customPlaceholder={EtchPlaceholder()}
        ready
      >
        {content}
      </LoadingPlaceholder>
    );
  }
}

Etch.propTypes = {
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  contentType: PropTypes.string,
  deliveryZip: PropTypes.string,
  dimensions: PropTypes.instanceOf(Array),
  dpdNewTab: PropTypes.bool,
  /** Exclude content of these types */
  exclude: PropTypes.arrayOf(PropTypes.string),
  favorites: PropTypes.arrayOf(PropTypes.number),
  /** Include only content of these types */
  include: PropTypes.arrayOf(PropTypes.string),
  isCategory: PropTypes.bool,
  linkType: PropTypes.string,
  onFavoriteChange: PropTypes.func,
  onVisNavRender: PropTypes.func,
  parentNavParam: PropTypes.string,
  partialTemplates: PropTypes.instanceOf(Array),
  storeId: PropTypes.string,
  /** Path to etch json file */
  templatePath: PropTypes.string,
  /** Array of etch content definitions */
  templates: PropTypes.instanceOf(Array),
  showArrows: PropTypes.bool,
  disableSwipeNavigation: PropTypes.bool,
  showFavoritesCount: PropTypes.bool
};

Etch.defaultProps = {
  channel: 'desktop',
  contentType: 'categorypage',
  deliveryZip: null,
  showArrows: true,
  disableSwipeNavigation: false,
  dimensions: [],
  dpdNewTab: false,
  exclude: [],
  favorites: [],
  include: [],
  isCategory: true,
  linkType: null,
  onFavoriteChange: null,
  onVisNavRender: () => {},
  parentNavParam: '',
  partialTemplates: [],
  storeId: '121',
  templatePath: null,
  templates: [],
  showFavoritesCount: false
};

export default Etch;
