import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HeroImage from './HeroImage.component';
import './Hero.scss';

/**
 * @group Components
 * Displays Hero component
 */

const Hero = (props) => {
  const { opts = {}, children } = props;
  const { options } = opts;
  return (
    <div className="alpha omega  hero_wrapper">
      <HeroImage options={options} />
      <div className={
        classNames(
          'hero_info',
          { 'u__text-shadow--none': options.textShadowNone }
        )
      }
      >
        {children}
      </div>
    </div>
  );
};

Hero.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.instanceOf(Array)
};

Hero.defaultProps = {
  children: []
};

export default Hero;
