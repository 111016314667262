import React from 'react';
import PropTypes from 'prop-types';

const SubHeader = (props) => {
  const { options } = props;
  if (!options.hideSubhead) {
    return (<div className="block-header__subhead">{options.subheadText}</div>);
  }
  return null;
};

/**
 * @group Titles
 * Sub header
 */

SubHeader.propTypes = {
  options: PropTypes.shape({
    hideSubhead: PropTypes.bool,
    subheadText: PropTypes.string
  }).isRequired
};

export default SubHeader;
