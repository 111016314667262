const thdCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;
const mcVisFromWindow = typeof window !== 'undefined' ? window.s?.marketingCloudVisitorID : null;

export const CUSTOMER_TYPES = {
  GUEST: 'b2c-guest',
  B2C: 'b2c',
  B2B: 'b2b',
};

export const AUTHORIZATION_STATUS = {
  AUTHENTICATED: 'authenticated',
  PERSISTED: 'persisted',
  GUEST: 'guest',
};

/**
 * The `x-thd-customer-token` auth header is the JWT. This will only be present for non-guests.
 *
 * So, if we don't see that header, we can assume your customer type is guest. If you have that header,
 * then `customerType` already indicates the type of logged-in customer you are and matches the B2C and B2B
 * constants above.
 */
function deriveCustomerType({ authHeaders, customerType }) {
  if (!authHeaders['x-thd-customer-token']) {
    return CUSTOMER_TYPES.GUEST;
  }

  return customerType;
}

function getSvocId() {
  return thdCustomer?.svocID || null;
}

function _convertCrumbsToJson(crumbs) {
  let propName = null;
  // eslint-disable-next-line id-length
  const crumbData = crumbs.reduce(function _reduce(a, b) {
    if (propName) {
      // eslint-disable-next-line no-param-reassign
      a[propName] = b;
      propName = null;
    } else {
      propName = b;
    }
    return a;
  }, {});

  return crumbData;
}

function getMarketingCloudVisitorIDFromCookie() {
  try {
    const theCookie = window?.cookieUtils?.readBrowserCookie('AMCV_F6421253512D2C100A490D45%40AdobeOrg');
    let crumbs = theCookie ? theCookie.split('|') : [];
    if (crumbs.length > 1) {
      crumbs.shift(); // custom for adobe kill first value
    }
    const crumbData = _convertCrumbsToJson(crumbs);

    return (crumbData && crumbData.MCMID) || '';
    // eslint-disable-next-line id-length
  } catch (e) {
    return null;
  }
}

function getMarketingCloudVisitorID() {
  return thdCustomer?.mcvisID || getMarketingCloudVisitorIDFromCookie() || mcVisFromWindow || '12345-Homepage';
}

function getUserId() {
  return thdCustomer?.userID || null;
}

function getGuestUserId() {
  return thdCustomer?.guestUserID || null;
}

function userAuthorizationStatus() {
  if (thdCustomer?.isLoggedIn) {
    if (!thdCustomer?.isKMSI && thdCustomer?.isPersisted) {
      return AUTHORIZATION_STATUS.PERSISTED;
    }
    return AUTHORIZATION_STATUS.AUTHENTICATED;
  }
  return AUTHORIZATION_STATUS.GUEST;
}

function isGuest() {
  return thdCustomer?.isLoggedIn;
}

function getSegment() {
  return thdCustomer?.segment || null;
}

// This function needs to be able to provide the URL from outside this component
function getPageValue() {
  return typeof window !== 'undefined'
    // eslint-disable-next-line no-mixed-operators
    && window?.document?.location?.pathname.split('/')
      .filter((chunk) => chunk.length)
      // eslint-disable-next-line no-mixed-operators
      .pop() || '';
}

export {
  deriveCustomerType,
  getSvocId,
  getMarketingCloudVisitorID,
  getUserId,
  getGuestUserId,
  getSegment,
  userAuthorizationStatus,
  isGuest,
  getPageValue
};
