import React, { useState, useContext, useEffect } from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { string } from 'prop-types';
import {
  Accordion, AccordionBody, AccordionHeader, Skeleton, SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { Row } from '@thd-olt-component-react/grid';
import { AppContext, withDynamicComponent } from '@thd-nucleus/app-render';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { ProductOverviewAccordionBody } from './Accordion/ProductOverviewAccordionBody';
import { ProductOverviewAccordionTitle } from './Accordion/ProductOverviewAccordionTitle';

export const ProductOverviewAccordionComponent = ({ itemId }) => {
  const itemIdParam = useParams();
  const itemIdFinal = itemId || itemIdParam?.itemId;
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const [expanded, setExpanded] = useState(thdSeoBotDetected || false);
  const qctx = useContext(QueryContext);

  const handleAccordionChange = (canCloseAccordion = true) => {
    if (canCloseAccordion) {
      setExpanded(!expanded);
    }

    if (!canCloseAccordion && !expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-section-overview.click', ({ output = true }) => {
      handleAccordionChange(output);
    });
  }, []);

  return (
    <Accordion
      key="product-details-accordion"
      expanded={expanded}
      onChange={() => handleAccordionChange()}
    >
      <AccordionHeader>
        <ProductOverviewAccordionTitle />
      </AccordionHeader>
      <AccordionBody>
        <ProductOverviewAccordionBody
          itemId={itemIdFinal}
          showReturnMessage
          revJet
          layoutGroup={{
            tag: Row
          }}
          qctx={qctx}
        />
      </AccordionBody>
    </Accordion>
  );
};

ProductOverviewAccordionComponent.displayName = 'ProductOverviewAccordion';
ProductOverviewAccordionComponent.propTypes = {
  itemId: string
};
ProductOverviewAccordionComponent.defaultProps = {
  itemId: null
};

const HydratedComponent = withHydrator({
  delay: 1500,
  pageType: 'pip',
  id: 'product-section-product-overview',
  placeholder: (
    <Skeleton className="sui-w-full">
      <SkeletonBlock height={52} />
    </Skeleton>
  ),
  scrollBuffer: 750
}, ProductOverviewAccordionComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const ProductOverviewAccordion = withErrorBoundary(DynamicComponent);

ProductOverviewAccordion.displayName = 'ProductOverviewAccordion';

ProductOverviewAccordion.dataModel = extend(
  ProductOverviewAccordionBody
);

export { ProductOverviewAccordion };