import React from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../util';

function NestedList(props) {
  const { content } = props;
  if (typeof content !== 'undefined') {
    return (
      <ul>
        {content.map((element, index) => <li key={UUID()}>{element.text}</li>)}
      </ul>
    );
  }
  return null;
}

NestedList.propTypes = {
  content: PropTypes.instanceOf(Array),
};
NestedList.defaultProps = {
  content: []
};

export default NestedList;
