import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UUID } from '../util';
// eslint-disable-next-line import/no-cycle
import Content from '../Content/Content.component';

const TableRow = (props) => {
  const { options } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const content = props.Content;
  return (options.isHeader)
    ? (
      <tr
        className={classNames(
          'thdTable__row',
          {
            'thdTable__row--striped': options.isStriped,
            'thdTable__row--responsive': options.isResponsive
          }
        )}
        id={options.id}
      >
        {content.map((element, index) => (
          <th
            key={UUID()}
            className={classNames(
              'thdTable__head',
              {
                'thdTable__cell--responsiveFullWidth': options.isResponsiveFullWidth,
                'thdTable__head--responsive': options.isResponsive
              }
            )}
          >
            {element.text}
          </th>
        ))}
      </tr>
    )
    : (
      <tr
        className={classNames(
          'thdTable__row',
          {
            'thdTable__row--striped': options.isStriped,
            'thdTable__row--responsive': options.isResponsive,
            'thdTable__row--responsiveFullWidth': options.isResponsiveFullWidth,
          }
        )}
      >
        {content.map((element, index) => (
          <td
            key={UUID()}
            className={classNames(
              'thdTable__cell',
              {
                'thdTable__cell--responsiveFullWidth': options.isResponsiveFullWidth,
                'thdTable__cell--responsive': options.isResponsive
              }
            )}
          >
            {element.Content.map((tdElement) => (
              <Content
                key={UUID()}
                opts={tdElement}
                content={tdElement.Content}
              />
            ))}
          </td>
        ))}
      </tr>
    );
};

TableRow.propTypes = {
  Content: PropTypes.instanceOf(Array).isRequired,
  options: PropTypes.shape({
    isHeader: PropTypes.bool,
    isStriped: PropTypes.bool,
    isResponsive: PropTypes.bool,
    id: PropTypes.string,
    isResponsiveFullWidth: PropTypes.bool
  }).isRequired
};
export default TableRow;
