import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { bool } from 'prop-types';

import { Image } from '@thd-olt-component-react/core-ui';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { ExperienceContext } from '@thd-nucleus/experience-context';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { SpecialBuyNavButton } from '../SpecialBuyNavButton/SpecialBuyNavButton';
import styles from './special-buy-header.module.scss';

const cNames = classNames.bind(styles);
const SpecialBuyHeader = ({ smallResponsiveHeader }) => {
  const {
    endDate,
    startDate,
    logoSrc,
    tagline,
    specialBuyType,
    isFallbackBanner,
    isCondensedLayout,
    isSpecialBuyDataLoading,
  } = useSpecialBuy();
  const isSbotd = specialBuyType === 'sbotd';
  const { customer } = useContext(ExperienceContext);
  const isB2B = customer?.type === 'b2b';
  const hasBlackHeader = specialBuyType === 'pro' && isB2B;

  let subTextMessage;
  if (isFallbackBanner && !isSpecialBuyDataLoading) {
    const endTimeText = isSbotd ? <>Today</> : <>This&nbsp;Week</>;
    subTextMessage = (
      <>
        Browse&nbsp;All Departments, Savings&nbsp;End {endTimeText}!
      </>
    );
  } else {
    subTextMessage = isSbotd
      ? <>Today&nbsp;Only! Free&nbsp;Delivery</>
      : <>Online&nbsp;Only&nbsp;+ Free&nbsp;Delivery</>;
  }

  const headerClassNames = cNames('header', {
    'header--condensed': isCondensedLayout,
    'header--dark': hasBlackHeader,
  });

  const taglineClassNames = cNames(
    'message__tagline',
    'u__display-font--black',
    {
      'message--white': hasBlackHeader,
      'message--small-responsive': smallResponsiveHeader,
      u__xlarge: !isCondensedLayout,
      u__husky: isCondensedLayout,
    },
  );

  const subTextClassNames = cNames('message__sub-text',
    { u__medium: !isCondensedLayout,
      'message__sub-text--white': hasBlackHeader, },
  );

  const timerClassNames = cNames({
    'banner-timer': !hasBlackHeader,
    'banner-timer__white': hasBlackHeader,
  });

  // this confusing code is a work around for an upstream quirk.
  // a story to work out the quirk has been created. Then this will need to be cleaned up
  const consistentEndDate = isSbotd
    ? startDate
    : endDate;

  return (
    <section className={headerClassNames}>
      <Image
        asset={logoSrc}
        alt="Special Buy Logo"
        title="Special Buy Logo"
        className={cNames('logo')}
        width={1}
        height={1}
      />
      <div className={cNames('message')}>
        <h3 className={taglineClassNames}>
          {tagline}
        </h3>
        <span className={subTextClassNames}>
          <span>{subTextMessage}</span>
          {(!isFallbackBanner && consistentEndDate?.length) && (
            <span className={timerClassNames}>
              <span className={timerClassNames}>
                Ends&nbsp;in{' '}
              </span>
              <CountdownTimer
                isPromotionTimer
                endDate={consistentEndDate}
                isSBOTD={isSbotd}
              />
            </span>
          )}
        </span>
      </div>
      {!isCondensedLayout && <SpecialBuyNavButton />}
    </section>
  );
};

SpecialBuyHeader.displayName = 'SpecialBuyHeader';

SpecialBuyHeader.propTypes = {
  smallResponsiveHeader: bool,
};

SpecialBuyHeader.defaultProps = {
  smallResponsiveHeader: false,
};

export { SpecialBuyHeader };
