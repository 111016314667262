import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import './Grid.scss';

/**
 * @group Layout
 * Displays grid component
 */

const Grid = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { opts = {}, children } = props;
  const { content = [], id, isBound, name } = opts;

  const isLeftNav = (data) => {
    if (channel === 'mobile' && data && data[0] && data[0].isColumnRail) {
      return true;
    }
    return false;
  };
  const isWhiteCard = (anchorId) => {
    if (anchorId && anchorId.includes('GC')) {
      return false;
    } if (anchorId && anchorId.includes('WC')) {
      return true;
    }
    return false;
  };

  const gridClass = classNames(
    'grid',
    {
      isBound,
    },
    { 'zone-card': isWhiteCard(name) },
    { 'grid--border': !isWhiteCard(name) },
    { 'grid--hide': isLeftNav(content) },
  );

  return (
    <div
      className={gridClass}
      id={name || id}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  opts: PropTypes.shape({
    isBound: PropTypes.bool,
    content: PropTypes.instanceOf(Array),
  }).isRequired,
  children: PropTypes.node
};

Grid.defaultProps = {
  children: [],
};

export default Grid;
