import {
  extend, params,
  arrayOf as arrayType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';

import { ProductInfoBar } from '@thd-olt-component-react/product-info-bar';
import { RichContent } from './components/RichContent/RichContent';

export const dataModel = extend({
  product: params({ itemId: stringType().isRequired(), dataSource: stringType() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    details: shapeType({
      description: stringType(),
      descriptiveAttributes: arrayType(shapeType({
        name: stringType(),
        value: stringType(),
        bulleted: stringType(),
        sequence: numberType()
      })),
      highlights: arrayType(stringType()),
      additionalResources: shapeType({
        infoAndGuides: arrayType(shapeType({
          name: stringType(),
          url: stringType()
        })),
        installationAndRentals: arrayType(shapeType({
          contentType: stringType(),
          name: stringType(),
          url: stringType()
        })),
        diyProjects: arrayType(shapeType({
          contentType: stringType(),
          name: stringType(),
          url: stringType()
        }))
      })
    }),
    identifiers: shapeType({
      brandName: stringType(),
      brandMainPageUrl: stringType(),
      modelNumber: stringType(),
      rentalCategory: stringType(),
      rentalSubCategory: stringType(),
      storeSkuNumber: stringType(),
      upc: stringType()
    }),
    info: shapeType({
      prop65Warning: boolType(),
      prop65Message: stringType(),
      returnable: stringType()
    }),
    media: shapeType({
      richContent: shapeType({
        content: stringType(),
        displayMode: stringType(),
        salsifyRichContent: stringType()
      })
    })
  })
}, ProductInfoBar, RichContent);
