/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image as AtomicImage } from '@thd-olt-component-react/core-ui';
import './EtchVisualNav.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchVisualNav = (props) => {
  const { opts = {} } = props;
  const {
    id,
    links = [],
    type
  } = opts;

  return (
    <div className="EtchVisualNav" id={id} type={type}>
      <nav className="visualNav__container-sm">
        { links.map(({ image = {}, link, linkText, id: linkId }) => {
          const videoId = (link && link.indexOf('playVideo') > -1) ? link?.match(/(\d+)/gm)?.[0] : '';
          const { src, alt, height, width } = image;
          const imgClass = classNames({
            'img--block': height && width
          });
          return (
            <div className="visualNav" link-id={id} key={linkId}>
              {link
            && (
              <>
                <CTARenderer ctaLink={link}>
                  <AtomicImage
                    src={src}
                    className={`visualNav__img ${imgClass}`}
                    alt={alt}
                    lazy
                    data-video={videoId}
                    height={height}
                    width={width}
                  />
                </CTARenderer>
                <CTARenderer className="visualNav__title-center" ctaLink={link}>
                  {linkText}
                </CTARenderer>
              </>
            )}
              {!link
            && (
              <>
                <AtomicImage
                  src={src}
                  className={`visualNav__img ${imgClass}`}
                  alt={alt}
                  lazy
                  data-video={videoId}
                  height={height}
                  width={width}
                />
                <p className="visualNav__title-center">{linkText}</p>
              </>
            )}
            </div>
          );
        })}
      </nav>
    </div>
  );
};

EtchVisualNav.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};
EtchVisualNav.defaultProps = {
};

export default EtchVisualNav;
