const getVideoId = (link = '') => {
  const isVideo = `${link}`?.includes('Video');
  if (isVideo) {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(link);
    return matches?.[1];
  }
  return null;

};

export {
  getVideoId
};