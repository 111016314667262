import React from 'react';
import PropTypes from 'prop-types';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';

/**
 * @group Personalization
 * Placeholder module for SBOTD injection
 */

const specialBuyTypes = {
  EtchSBOTD: 'sbotd',
  EtchDecorSBOTW: 'decor',
  EtchProSBOTW: 'pro',
};

const SpecialBuyModuleEtch1 = ({ opts }) => {
  const type = opts.type;
  const options = opts.options;

  return (
    <div id={type} data-view={options.view}>
      <SpecialBuyBanner specialBuyType={specialBuyTypes[type]} />
    </div>
  );
};

SpecialBuyModuleEtch1.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({
      view: PropTypes.string
    }),
    type: PropTypes.oneOf(['EtchSBOTD', 'EtchDecorSBOTW', 'EtchProSBOTW'])
  }).isRequired
};
SpecialBuyModuleEtch1.defaultProps = {
};

export default SpecialBuyModuleEtch1;
