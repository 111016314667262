import React from 'react';
import PropTypes from 'prop-types';
import './PopularCategories.scss';
import { Col } from '@thd-olt-component-react/core-ui';

const PopularCategories = (props) => {
  const { opts = {} } = props;
  const {
    alignment,
    html,
    id
  } = opts;

  if (!html) {
    return (null);
  }

  // const sanitizedHtml = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi);

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <Col fallback="6" xs="4" sm="4" md="2" lg="2">
      <div
        id={id}
        className={`etch-popular-categories etch-popular--${alignment}`}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Col>
  );
};

PopularCategories.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    html: PropTypes.string,
    alignment: PropTypes.string,
  }).isRequired,
};

export default PopularCategories;