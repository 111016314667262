import React from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOfType,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames';
import './heading.scss';

const Heading = ({
  centered,
  children,
  className,
  clickable,
  link,
  noborder,
  spaceBetween,
  noMargin,
  nopadding,
  title,
  underline,
  verticalAccentForRow
}) => {
  const rowTitle = title || title === '';
  const header = rowTitle || children;
  if (!header) {
    return null;
  }
  const classes = classNames(
    className
  );
  const headerClasses = classNames('section-heading', {
    'section-heading--nomargin': noMargin,
    'section-heading--noborder': noborder,
    'section-heading--nopadding': nopadding,
    'section-heading--space-between': clickable || spaceBetween,
    'section-heading--centered': centered,
  });

  const headerTextClasses = classNames('section-heading__text', {
    'section-heading__text--plain': !underline,
    'section-heading__text--vertical-accent': verticalAccentForRow,
  });

  const isLinkCTA = clickable && typeof clickable === 'object';
  const preText = clickable && typeof clickable === 'string';
  const caret = 'https://assets.homedepot-static.com/images/v1/caret-orange.svg';

  return (
    <div className={classes}>
      <div className={headerClasses}>
        <div className="section-heading__title">
          <h2 className={headerTextClasses}>{header}</h2>
          {link && <a className="section-heading__link" href={link.href}>{link.title}</a>}
        </div>
        {clickable && !isLinkCTA
          && (
            <div className="section-heading__cta">
              {
                preText
                && <span className="section-heading__cta-text u__default-link">{clickable}</span>
              }
              <span className="section-heading__cta-icon">
                <img
                  width="9px"
                  height="15px"
                  src={caret}
                  alt="More"
                />
              </span>
            </div>
          )}
        {clickable && isLinkCTA
          && (
            <a href={clickable.href} className="section-heading__cta">
              <span className="section-heading__cta-link u__default-link">{clickable.title}</span>
              <img
                width="9px"
                height="15px"
                src={caret}
                alt="More"
              />
            </a>
          )}
      </div>
    </div>
  );
};

Heading.displayName = 'Heading';

Heading.propTypes = {
  centered: bool,
  children: oneOfType([arrayOf(node), node, string]),
  className: string,
  /** Show the orange action button on the header. Can be a string to add a prefix,
   * or a link shape to behave like a link */
  clickable: oneOfType([bool, string, shape({})]),
  title: string,
  link: shape({
    title: string,
    href: string
  }),
  noborder: bool,
  noMargin: bool,
  nopadding: bool,
  spaceBetween: bool,
  /** Add the orange underline */
  underline: bool,
  /** Update the font size and weight for a vertical accent card */
  verticalAccentForRow: bool
};

Heading.defaultProps = {
  centered: false,
  children: null,
  className: null,
  clickable: false,
  link: null,
  noMargin: false,
  nopadding: false,
  noborder: false,
  spaceBetween: false,
  title: null,
  underline: false,
  verticalAccentForRow: false
};

export { Heading };
