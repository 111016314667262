import React from 'react';
import PropTypes from 'prop-types';

import './Certona.scss';

/**
 * @group Personalization
 * Certona Placeholder
 */

class Certona extends React.Component {
  componentDidMount() {
    window.certonaAvailable = true;
  }

  render() {
    const { opts = {} } = this.props;
    return (
      <div
        id={opts.options.schemaTypes}
        className={`col__12-12 ${(opts.options.isRecommendations ? 'recommendations-content' : 'certona-content')}`}
        data-showmore={opts.options.onDemandRV}
      />
    );
  }
}

Certona.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({})
  }).isRequired,
};

export default Certona;
