import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import {
  PodFooter,
  PodSection,
  PodSpacer,
  PodSticker,
  ProductATC,
  ProductBadge,
  ProductCustomOptions,
  ProductIdentifier,
  ProductImage,
  ProductRating,
  ProductSample,
  ProductPod
} from '@thd-olt-component-react/product-pod';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';

const CustomDesktopProductPod = ({
  index, itemId, showFavoritesCount, storeId
}) => {
  return (
    <ProductPod
      analyticsData={{
        parent: 'DPD', position: index
      }}
      itemId={itemId}
      storeId={storeId}
      render={(pod) => {
        return (
          <>
            <PodSticker position="top-left">
              <ProductBadge itemId={pod.itemId} storeId={storeId} />
            </PodSticker>
            <PodSticker position="top-right">
              <PodSpacer padding={['RIGHT', 'TOP']}>
                <Favorite itemId={pod.itemId} showCount={showFavoritesCount} showCircle />
              </PodSpacer>
            </PodSticker>
            <ProductImage
              itemId={pod.itemId}
              showSecondaryImage={pod.showSecondaryImage}
            />
            <PodSection columnAlign relative alignTop>
              <ProductCustomOptions itemId={pod.itemId} />
              <ProductSample itemId={pod.itemId} />
              <SuperSkuMiniSwatches
                itemId={pod.itemId}
                storeId={storeId}
                onChange={pod.onChange}
                onHover={pod.onSSKUHover}
                numberOfMiniSwatches={5}
              />
              <PodSpacer padding={['BOTTOM', 'TOP']}>
                <ProductHeader
                  brand="above"
                  itemId={pod.itemId}
                  brandTitleMaxLine={4}
                  disableShopThisCollection
                />
              </PodSpacer>
              <PodSpacer padding={['TOP']}>
                <ProductRating itemId={pod.itemId} />
              </PodSpacer>
            </PodSection>
            <PodFooter>
              <PodSpacer padding={['BOTTOM']}>
                <Price
                  itemId={pod.itemId}
                  large={false}
                  storeId={pod.storeId}
                  displayEachUom={false}
                  onSavingsCenterToggle={pod.onSavingsCenterToggle}
                />
              </PodSpacer>
              <PodSpacer padding={['TOP']}>
                <ProductATC
                  itemId={pod.itemId}
                  checkGeneric
                  outline
                  storeId={pod.storeId}
                />
              </PodSpacer>
            </PodFooter>
          </>
        );
      }}
    />
  );
};

CustomDesktopProductPod.dataModel = extend(
  Favorite,
  Price,
  ProductATC,
  ProductBadge,
  ProductCustomOptions,
  ProductHeader,
  ProductIdentifier,
  ProductImage,
  ProductPod,
  ProductRating,
  ProductSample,
  SuperSkuMiniSwatches
);

CustomDesktopProductPod.propTypes = {
  index: PropTypes.number,
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  showFavoritesCount: PropTypes.bool
};

CustomDesktopProductPod.defaultProps = {
  index: 1,
  storeId: '121',
  showFavoritesCount: false
};

export { CustomDesktopProductPod };
