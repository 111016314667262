import { htmlAttributes, newRelicConstants } from '../constants';
import {
  getDimensions,
  getTestCoordinates,
  isElementOrChild,
  isTransparent,
  pushPiqImpressionToAdobe,
} from './impressionHelpers';
import { triggerNewRelic, triggerNewRelicTimeLog } from './helpers';
import { newRelicDataTestId } from '../constants/newRelicConstants';

export const isBeaconSent = (element) => {
  let beaconUrl = element.getAttribute(htmlAttributes.ONVIEW_BEACON_NUCLEUS)
    || element.getAttribute(htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS);
  let sentBeaconUrl = element.getAttribute(htmlAttributes.DATA_H1_BEACON);
  if (sentBeaconUrl === 'default-banner') {
    return true;
  }

  let beaconWasSent = beaconUrl && sentBeaconUrl && beaconUrl === sentBeaconUrl;
  return !!beaconWasSent;
};

export const isVisible = (localWindow, element) => {
  // checks for display:none anywhere in parent tree
  let result = element.offsetParent !== null;
  if (result) {
    // false if isTransparent
    result = !isTransparent(element);
  }

  if (result) {
    // Handles things cover the element or it being scrolled out of view
    const elementDimensions = getDimensions(localWindow, element);
    const elementCoordinates = getTestCoordinates(elementDimensions);
    // is the element on top at this coordinate the element?
    const elementAtPoints = elementCoordinates.map((coordinate) => {
      return document.elementFromPoint(coordinate.x, coordinate.y);
    });
    const pointsCovered = elementAtPoints.filter((elementAtPoint) => {
      return !isElementOrChild(elementAtPoint, element);
    });

    // At least 50% visible if no more than 2 points are covered (out of 4 corners + center)
    result = pointsCovered.length <= 2;
  }

  return result;
};

export const triggerBeacon = (element) => {
  const adLoadStartTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP))).getTime();
  const bannerInitTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP))).getTime();
  const innervateScriptTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP))).getTime();
  const beaconAddTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_BEACON_ADD_TIME))).getTime();

  let onviewbeacon = element.getAttribute(htmlAttributes.ONVIEW_BEACON_NUCLEUS)
    || element.getAttribute(htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS);
  const dataTestId = element.getAttribute(htmlAttributes.DATA_TEST_ID);
  if (onviewbeacon) {
    let beaconStr = `<img class="onviewbeacon" style="display:none;" src="https:${onviewbeacon}">`;

    document.body.insertAdjacentHTML('beforeend', beaconStr);
    // Impression Fired Time
    const impressionFiredTime = Date.now();

    if (adLoadStartTime) {
      // SEND TIME FROM AD LOAD TO AD IMPRESSION FIRED
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_LOAD_TO_IMPRESSION,
        adLoadStartTime, impressionFiredTime);
    }
    if (bannerInitTime) {
      // SEND TIME FROM BANNER INITIATED TO AD IMPRESSION FIRED
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INIT_TO_IMPRESSION,
        bannerInitTime, impressionFiredTime);
    }
    if (innervateScriptTime) {
      // SEND TIME FROM INNERVATE SCRIPT INJECTED TO AD IMPRESSION FIRED
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
        innervateScriptTime, impressionFiredTime);
    }
    if (beaconAddTime) {
      // SEND TIME FROM BEACON ADDED TO AD IMPRESSION FIRED
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_BEACON_ADDED_TO_IMPRESSION,
        beaconAddTime, impressionFiredTime);
    }

    element.setAttribute(htmlAttributes.DATA_H1_BEACON, onviewbeacon);
    if (!element.classList.contains('sponsored-product-pod')
      && !element.classList.contains('sponsored-pip-pla-container')) {
      pushPiqImpressionToAdobe(element);
    }
    const newRelicAdType = newRelicDataTestId[dataTestId];
    if (newRelicAdType) {
      triggerNewRelic(newRelicAdType, 'impression');
    }
  } else if (dataTestId === 'default-standard-banner') {
    // set a beacon for default banner to indicate impression sent
    element.setAttribute(htmlAttributes.DATA_H1_BEACON, 'default-banner');
    triggerNewRelic(newRelicConstants.DEFAULT_BANNER, 'impression');
  }
};
