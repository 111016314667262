import React from 'react';
import { string } from 'prop-types';

import { ImpressionProvider } from '@thd-olt-component-react/impression';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { CategoryProducts } from '../CategoryProducts/CategoryProducts';
import { ProductRowPlaceholder } from '../ProductPodPlaceholders/ProductRowPlaceholder';

const SpecialBuyCarousel = ({ entryId }) => {
  const { categories, selectedCategory, isSpecialBuyDataLoading } = useSpecialBuy();

  const impressionData = {
    id: entryId || '',
    name: 'SpecialBuyBanner',
    component: 'SpecialBuyBanner',
    type: 'product',
    position: '',
    category: selectedCategory?.categoryId || ''
  };

  return (
    !categories?.length && isSpecialBuyDataLoading ? (
      <ProductRowPlaceholder />
    ) : (
      <ImpressionProvider data={impressionData}>
        <>
          {categories.map((category) => (
            <CategoryProducts category={category} key={category.categoryId} />
          ))}
        </>
      </ImpressionProvider>
    ));
};

SpecialBuyCarousel.displayName = 'SpecialBuyCarousel';

SpecialBuyCarousel.propTypes = {
  entryId: string
};

SpecialBuyCarousel.defaultProps = {
  entryId: null
};

export { SpecialBuyCarousel };
