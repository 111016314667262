/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image as AtomicImage } from '@thd-olt-component-react/core-ui';
import './EtchVisualNav3AcrossMobile.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchVisualNav3AcrossMobile = (props) => {
  const { opts = {} } = props;
  const {
    id,
    links = [],
    type
  } = opts;

  return (
    <div className="EtchVisualNav3AcrossMobile" id={id} type={type}>
      <nav className="visualNav__container-sm">
        { links.map(({ image = {}, link, linkText }) => {
          const { src, alt, height, width } = image;
          const imgClass = classNames({
            'img--block': height && width
          });
          return (
            <div className="visualNav" key={`${link}_${id}`} link-id={id}>
              {link
            && (
              <>
                <CTARenderer ctaLink={link}>
                  <AtomicImage
                    src={src}
                    className={`visualNav__img ${imgClass}`}
                    alt={alt}
                    lazy
                    height={height}
                    width={width}
                  />
                </CTARenderer>
                <CTARenderer ctaLink={link} className="visualNav__title-center">
                  {linkText}
                </CTARenderer>
              </>
            )}
              {!link
            && (
              <>
                <AtomicImage
                  src={src}
                  className={`visualNav__img ${imgClass}`}
                  alt={alt}
                  lazy
                  height={height}
                  width={width}
                />
                <p className="visualNav__title-center">{linkText}</p>
              </>
            )}
            </div>
          );
        })}
      </nav>
    </div>
  );
};

EtchVisualNav3AcrossMobile.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    parentId: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      linkText: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      })
    })),
    type: PropTypes.string
  }).isRequired
};
EtchVisualNav3AcrossMobile.defaultProps = {
};

export default EtchVisualNav3AcrossMobile;
