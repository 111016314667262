import React from 'react';
import PropTypes from 'prop-types';

/**
 * @group THD Home Components
 * Featured Collection container
 */

function FeaturedCollection(props) {
  const { opts = {} } = props;
  const { options } = opts;
  return (
    <div className="FeaturedCollection" data-collection={options.collection} />
  );
}

FeaturedCollection.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({})
  }).isRequired
};

export default FeaturedCollection;
