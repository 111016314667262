/* eslint react/no-find-dom-node: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { LayoutGroupContext } from './LayoutGroupContext';
import { LayoutManagerContext } from '../LayoutManagerContext';

export class LayoutGroup extends Component {

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {};

  boundClickListener = false;

  componentDidMount() {

    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      const { rules } = this.context;
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('LAYOUT_GROUP.ready', {
        ...this.props,
        ...rules
      });
    }
    try {
      const el = ReactDOM.findDOMNode(this);// eslint-disable-line
      if (el && el.setAttribute) {
        const { name } = this.props;
        el.setAttribute('data-lg-name', name);
      }
    } catch (err) {
      // silence
    }
  }

  componentDidUpdate() {
    if (!this.boundClickListener) {
      if (this.to) {
        clearTimeout(this.to);
      }
      this.to = setTimeout(() => {
        try {
          const el = ReactDOM.findDOMNode(this);// eslint-disable-line
          if (el && el.setAttribute) {
            const { name } = this.props;
            el.setAttribute('data-lg-name', name);
          }
        } catch (err) {
          // silence
        }

      }, 1000);
    }

  }

  componentDidCatch = (error, info) => {
    console.log(error);// eslint-disable-line
    const { id, name } = this.props;
    const { componentStack } = info || {};
    const { onError } = this.context;
    if (onError) {
      onError({ error, info, id, name });
    }
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {

      window.LIFE_CYCLE_EVENT_BUS.trigger('layout-manager.error', {
        error,
        id,
        name,
        componentStack
      });
    }
    return null;
  };

  render() {
    const { id, children, tag, name } = this.props;
    const { rules, manager, useContextForProps } = this.context;
    const managers = rules?.managers || [];
    const group = (manager?.groups || []).find((mgr) => mgr.name === name);
    const layoutGroupContext = {
      managerName: manager?.name,
      groupName: name,
      props: group?.props,
      useContextForProps
    };
    let kids = children;

    const { hasError } = this.state;
    if (hasError) {
      return null;
    }

    if (typeof children === 'boolean') {
      return null;
    }

    if (!children) {
      return null;
    }
    if (Array.isArray(children)) {
      kids = children.filter((exists) => exists);
    }
    let Wrapper = React.Fragment;
    if (tag) {
      let props = {
        ...this.props
      };
      props.children = null;// eslint-disable-line
      props.tag = null;
      if (tag.type) {
        const el = React.cloneElement(tag, { ...props }, kids);
        return el;
      }
      const Tag = tag;
      /* eslint-disable-next-line */
      return (
        <LayoutGroupContext.Provider value={layoutGroupContext}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tag {...props}>{kids}</Tag>
        </LayoutGroupContext.Provider>
      );
    }
    return (
      <LayoutGroupContext.Provider value={layoutGroupContext}>
        {kids}
      </LayoutGroupContext.Provider>
    );
  }
}

LayoutGroup.contextType = LayoutManagerContext;

LayoutGroup.displayName = 'LayoutGroup';

LayoutGroup.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  tag: PropTypes.any// eslint-disable-line
};

LayoutGroup.defaultProps = {
  children: null,
  id: null,
  name: null,
  tag: null
};
