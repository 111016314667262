/* eslint-disable */
import classNames from 'classnames';

class ButtonSelector {
    constructor() {
    }

    getButtonClass(buttonType) {
      return classNames({
        'bttn bttn--primary': buttonType.isPrimary,
        'bttn-outline bttn-outline--primary': buttonType.isBorder,
        'bttn bttn--primary disabled': buttonType.isPrimary_Disabled,
        'bttn bttn--primary--small': buttonType.isPrimary_Small,
        'bttn bttn--primary--small disabled': buttonType.isPrimary_Small_Disabled,
        'bttn-outline bttn-outline--primary disabled': buttonType.isBorder_Disabled,
        'bttn-outline bttn-outline--primary disabled nohover': buttonType.isBorder_Disabled_Nohover,
        'bttn-outline bttn-outline--dark': buttonType.isBorder_Dark,
        'bttn-outline bttn-outline--dark disabled': buttonType.isBorder_Dark_Disabled,
        'bttn-outline bttn-outline--dark disabled nohover': buttonType.isBorder_Dark_Disabled_Nohover,
        'bttn bttn--primary bttn--inline': buttonType.isInline,
        'bttn bttn--primary bttn--inline disabled': buttonType.isInline_Disabled,
        'bttn-outline bttn-outline--primary bttn--inline': buttonType.isInline_Outline,
        'bttn-outline bttn-outline--primary bttn--inline disabled': buttonType.isInline_Outline_Disabled,
        'bttn-outline bttn-outline--dark bttn--inline': buttonType.isInline_Outline_Dark,
        'bttn-outline bttn-outline--dark bttn--inline disabled': buttonType.isInline_Outline_Dark_Disabled,
      })
    }

    get(options) {
      let classString = '';
      if(typeof options.buttonType !== 'undefined') {
        classString=this.getButtonClass(options.buttonType);
      }else{
        classString=classNames('btn', options.display);
      }
      return classString;
    }
}

export default ButtonSelector