import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  ProductPod,
  ProductRating,
  ProductImage
} from '@thd-olt-component-react/product-pod';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import {
  arrayOf, bool, func, shape, string, number, oneOf
} from 'prop-types';

const CustomProductPod = ({ itemId, productIndex, storeId }) => {
  const analyticsData = {
    parent: 'endcap',
    position: productIndex
  };

  return (
    <ProductPod
      itemId={itemId}
      analyticsData={analyticsData}
      storeId={storeId}
      render={(pod) => (
        <>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            onClick={() => {}}
          />
          <ProductHeader
            brand="inline"
            itemId={pod.itemId}
            brandTitleMaxLine={2}
          />
          <ProductRating itemId={pod.itemId} />
          <Price
            itemId={pod.itemId}
            large={false}
            storeId={pod.storeId}
            displayEachUom={false}
            onSavingsCenterToggle={pod.onSavingsCenterToggle}
            hideForProductTypes={['HDQC']}
          />
        </>
      )}
    />
  );
};

CustomProductPod.propTypes = {
  itemId: string,
  productIndex: number,
  storeId: string
};

CustomProductPod.defaultProps = {
  itemId: '',
  productIndex: 1,
  storeId: '8119'
};

CustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  Price,
  ProductRating,
  ProductHeader
);

export { CustomProductPod };