import React from 'react';
import PropTypes from 'prop-types';

/**
 * @group Personalization
 * targeted merchandise component
 */

const TargetedMerchandise = (props) => {
  const { opts, children } = props;
  const { options, id } = opts;

  return (
    <div
      className="targetedMerchandise"
      id={id}
      data-id={id}
      data-type="targetedMerchandise"
      data-campaign-name={options.campaignName}
      data-launch-date={options.launchDate}
      data-cities={options.cities}
      data-states={options.states}
      data-zipcodes={options.zipcodes}
      data-store-ids={options.storeIds}
      data-exclude-stores={options.excludeStores}
    >
      {children}
    </div>
  );
};

TargetedMerchandise.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.shape({
      campaignName: PropTypes.string,
      launchDate: PropTypes.string,
      cities: PropTypes.string,
      states: PropTypes.string,
      zipcodes: PropTypes.string,
      storeIds: PropTypes.string,
      excludeStores: PropTypes.string
    }).isRequired,
  }).isRequired,
  children: PropTypes.node
};

TargetedMerchandise.defaultProps = {
  children: []
};

export default TargetedMerchandise;
