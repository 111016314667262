/* eslint-disable */
import classNames from 'classnames';

class AlignmentSelector {
    constructor() {
    }
    getAlignmentClass(alignOptions){
      return classNames({
        'u__left': alignOptions.isLeft_Align,
        'u__center': alignOptions.isCenter_Align,
        'u__right': alignOptions.isRight_Align,
      })
    }
    get(options) {
      let classString = '';
      if(typeof options.align !== 'undefined') {
        classString=this.getAlignmentClass(options.align);
      }else{
        classString=options.alignment;
      }
      return classString;
    }
}

export default AlignmentSelector;