import React from 'react';
import PropTypes from 'prop-types';
import { UUID } from '../util';

const LinkList = (props) => {
  return props.links.map((link, index) => {
    return <li key={UUID()}><a href={(link.href) ? link.href : link.url}>{link.label}</a></li>;
  });
};

const Dimension = (props) => {
  const { opts = {} } = props;
  return (opts.isCategory)
    ? (
      <div className="gridNavigation">
        <h3 className="title">Browse</h3>
        <ul className="parentLevels">
          { opts.isParentLevel
            && <LinkList links={opts.breadCrumbs} />}
        </ul>
        {!opts.collapse
          && (
            <ul className="activeLevel">
              <li><h3 className="title">{opts.selectedBreadcrumb.label}</h3></li>
              <LinkList links={opts.refinements} />
            </ul>
          )}
      </div>
    ) : (
      <div className="row">
        <div className="withBorder cat-refinements">
          <div className="spad">
            <ul className="linkList">
              <li className="header">${opts.label}</li>
              {opts.refinements.map((refinement, index) => (
                <li key={UUID()}>
                  <a href={refinement.url}>
                    {(refinement.isRating)
                      ? `<img src="/static/images/plp/${refinement.imageLabel}.png" style="width:72px; height:12px;">`
                      : `${refinement.label}`}
                  </a>
                </li>
              ))}
              { opts.showAllLink
                && (
                  <li className="option">
                    <a href={`${opts.data.canonical.metaUrl}&brand=All&catStyle=ShowProducts`}>
                      ${opts.MORE_BRAND_TEXT}
                    </a>
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>
    );
};
Dimension.propTypes = {
  opts: PropTypes.shape({
    isCategory: PropTypes.bool,
    isParentLevel: PropTypes.bool,
    collapse: PropTypes.bool,
    refinements: PropTypes.instanceOf(Array),
    showAllLink: PropTypes.bool,
    breadCrumbs: PropTypes.instanceOf(Array),
    selectedBreadcrumb: PropTypes.objectOf(PropTypes.string),
    label: PropTypes.string,
    data: PropTypes.objectOf(PropTypes.string),
    MORE_BRAND_TEXT: PropTypes.string
  }).isRequired
};

/**
 * @group Components
 * Category Navigation link list
 */

const CategoryNavigation = (props) => {
  const { opts = {} } = props;
  const { dimensions } = opts;
  return (
    <div className="category-navigation">
      {dimensions && dimensions.map((dimension, index) => (
        <Dimension
          key={UUID()}
          opts={dimension}
        />
      ))}
    </div>
  );
};

CategoryNavigation.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    dimensions: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.shape({
      canonical: PropTypes.shape({})
    })
  }).isRequired
  ,
};

export default CategoryNavigation;
