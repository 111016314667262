import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image as AtomicImage } from '@thd-olt-component-react/core-ui';
import CTARenderer from '../CTARenderer/CTARenderer.component';
import './Image.scss';

/**
 * @group Layout
 * Displays Etch Image component
 */

const Image = (props) => {
  const { opts = {}, channel, shouldPrioritizeCtaImageFetch } = props;

  const {
    id,
    link = '',
    mediaData = {},
    mediaMobileData = {},
    isStretchy,
    name
  } = opts;
  const isMobile = channel === 'mobile';
  const media = isMobile ? mediaMobileData : mediaData;
  const { src, alt, height, width } = media;
  const imgClass = classNames({
    'img--block': height && width,
    stretchy: isStretchy
  });

  return (
    <div className="content_image" data-testid="etch-image" id={id}>
      {link
        && (
          <CTARenderer ctaLink={link} videoStill={src}>
            <AtomicImage
              src={src}
              className={imgClass}
              alt={alt}
              title={name}
              lazy={shouldPrioritizeCtaImageFetch}
              height={height}
              width={width}
              fetchpriority={shouldPrioritizeCtaImageFetch ? 'low' : 'high'}
            />
          </CTARenderer>
        )}
      {!link
        && (
          <AtomicImage
            src={src}
            className={imgClass}
            alt={alt}
            title={name}
            lazy
            height={height}
            width={width}
          />
        )}
    </div>
  );
};

Image.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isStretchy: PropTypes.bool,
    mediaData: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    name: PropTypes.string
  }).isRequired,
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  shouldPrioritizeCtaImageFetch: PropTypes.bool,
};

Image.defaultProps = {
  channel: 'desktop',
  shouldPrioritizeCtaImageFetch: false,
};

export default Image;