import React from 'react';
import PropTypes from 'prop-types';
import './BlockHeader.scss';

const BlockHeader = (props) => {
  const { opts = {} } = props;
  const {
    id,
    heading,
    subHeading
  } = opts;
  return (
    <div className="block-header etch-analytics" id={id} data-module-id={id}>
      <div className="block-header__border" />
      <div className="block-header__subhead">{subHeading}</div>
      <div className="block-header__header">{heading}</div>
      <div className="block-header__border" />
    </div>
  );
};

BlockHeader.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    options: PropTypes.shape({
      hideSubhead: PropTypes.bool,
      subheadText: PropTypes.string
    })
  }).isRequired
};
BlockHeader.defaultProps = {
};

export default BlockHeader;
