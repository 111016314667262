import React, {
  createContext, useContext, useEffect, useState, useMemo
} from 'react';
import { node, string, bool } from 'prop-types';
import {
  params, string as StringType, arrayOf as ArrayType, useDataModel, customType, shape as ShapeType
} from '@thd-nucleus/data-sources';
import { noop } from '../utils/utils';

const getLogoSrc = (specialBuyType) => {
  const logoSrc = (specialBuyType === 'sbotd')
    ? 'sbotd-logo.png'
    : 'sbotw-badge.svg';

  return logoSrc;
};

const getSpecialBuyEndpoint = (specialBuyType) => {
  const specialBuyEndpoints = {
    sbotd: '/SpecialBuy/SpecialBuyOfTheDay',
    pro: '/SpecialBuy/ProSpecialBuyOfTheWeek',
  };

  return specialBuyEndpoints[specialBuyType];
};

const getIconSrcForCategory = (category, isCondensedLayout) => {
  const imageSize = isCondensedLayout ? '400' : '65';
  return category.image.url.replace('<SIZE>', imageSize);
};
const getCategoryId = ({ name }) => name.replace(/[^A-Za-z]/g, '');

const getFallbackValues = (specialBuyType) => ({
  specialBuyType,
  logoSrc: getLogoSrc(specialBuyType),
  tagline: (specialBuyType === 'sbotd')
    ? 'Today\'s Big Savings All in One Place'
    : 'This Week\'s Big Savings All in One Place',
  endDate: null,
  categories: [],
  selectedCategory: null,
  specialBuyEndpoint: getSpecialBuyEndpoint(specialBuyType),
  isFallbackBanner: true,
});

const isSanitaryCategory = (category) => Boolean(
  category?.name
  && category.tagline
  && category.itemIds?.length
  && category.image?.url
);

export const defaultContextValue = {
  specialBuyType: '',
  logoSrc: '',
  tagline: '',
  startDate: null,
  endDate: null,
  categories: [],
  specialBuyEndpoint: '',
  isFallbackBanner: true,
  isSpecialBuyDataLoading: false,
  isCondensedLayout: false,
  selectedCategory: null,
  setSelectedCategory: noop,
  simpleBanner: false
};
const SpecialBuyContext = createContext(defaultContextValue);
const useSpecialBuy = () => useContext(SpecialBuyContext);

const SpecialBuyProvider = ({ children, specialBuyType, isCondensedLayout, simpleBanner }) => {
  const dealType = specialBuyType === 'sbotd' ? 'DAY' : specialBuyType.toUpperCase();
  const { data, loading } = useDataModel('specialBuyMetadata', {
    ssr: false,
    variables: {
      dealType
    }
  });
  const [specialBuyData, setSpecialBuyData] = useState(getFallbackValues(specialBuyType));
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const categories = (data?.specialBuyMetadata?.categoryMetadata || [])
      .filter(isSanitaryCategory)
      .map((category) => ({
        ...category,
        image: {
          url: getIconSrcForCategory(category, isCondensedLayout)
        },
        categoryId: getCategoryId(category)
      }));

    const isUsableSpecialBuyData = Boolean(
      data?.specialBuyMetadata?.tagline
      && data.specialBuyMetadata.startDate
      && categories.length
    );

    const newSpecialBuyData = isUsableSpecialBuyData
      ? {
        specialBuyType,
        tagline: data.specialBuyMetadata.tagline,
        startDate: data.specialBuyMetadata.startDate,
        endDate: data.specialBuyMetadata.endDate,
        categories,
        logoSrc: getLogoSrc(specialBuyType),
        specialBuyEndpoint: getSpecialBuyEndpoint(specialBuyType),
        isFallbackBanner: false,
      }
      : getFallbackValues(specialBuyType);

    setSpecialBuyData(newSpecialBuyData);
    setSelectedCategory(categories[0] || null);
  }, [specialBuyType, data, setSpecialBuyData, setSelectedCategory, isCondensedLayout]);

  const contextValue = useMemo(() => ({
    ...specialBuyData,
    isSpecialBuyDataLoading: loading,
    isCondensedLayout,
    simpleBanner,
    selectedCategory,
    setSelectedCategory,
  }), [specialBuyData, loading, isCondensedLayout, simpleBanner, selectedCategory]);

  return (
    <SpecialBuyContext.Provider value={contextValue}>
      {children}
    </SpecialBuyContext.Provider>
  );
};

SpecialBuyProvider.propTypes = {
  children: node.isRequired,
  specialBuyType: string.isRequired,
  isCondensedLayout: bool.isRequired,
  simpleBanner: bool
};

SpecialBuyProvider.defaultProps = {
  simpleBanner: false
};

SpecialBuyProvider.displayName = 'SpecialBuyProvider';

SpecialBuyProvider.dataModel = {
  specialBuyMetadata: params({
    dealType: customType('DealType').enum(['DAY', 'PRO'], 'DAY'),
  })
    .shape({
      endDate: StringType(),
      startDate: StringType(),
      tagline: StringType(),
      categoryMetadata: ArrayType({
        itemIds: ArrayType(StringType()),
        tagline: StringType(),
        name: StringType(),
        image: ShapeType({
          url: StringType()
        })
      })
    })
};
export { SpecialBuyProvider, SpecialBuyContext, useSpecialBuy };
