import React from 'react';
import PropTypes from 'prop-types';
import './HiddenContent.scss';

/**
 * @group Components
 * Displays hidden content
 */

const HiddenContent = (props) => {
  const { opts = {}, children } = props;
  const { options } = opts;
  return (
    <div className="hiddenContent hide" id={options.name}>
      <div className="col__12-12">
        {options.title && <h3 className="title l">{options.title}</h3>}
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
};

HiddenContent.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
  }).isRequired,
  children: PropTypes.instanceOf(Array)
};

HiddenContent.defaultProps = {
  children: []
};

export default HiddenContent;
