import React from 'react';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import { arrayOf, shape, string, bool } from 'prop-types';
import { DocumentList } from './DocumentList';
import { AdditionalResourcesLink } from './AdditionalResourcesLink';
import { trackDocumentLinkClick } from '../product-overview-analytics';

export const InfoGuides = (props) => {
  const {
    additionalResources, brandName, brandMainPageUrl, returnMessage, showReturnMessage
  } = props;
  const { infoAndGuides, diyProjects, installationAndRentals } = additionalResources;

  return (
    <div className="sui-bg-subtle sui-p-4 sui-flex sui-gap-4 sui-flex-col">
      <Typography
        variant="h3"
        weight="bold"
      >
        Additional Resources
      </Typography>
      {
        brandName && brandName.toLowerCase() !== 'unbranded' && brandMainPageUrl && (
          <Link
            href={brandMainPageUrl}
            target="_blank"
            onClick={() => trackDocumentLinkClick(('Shop All ' + brandName).toLowerCase())}
          >
            {'Shop All ' + brandName}
          </Link>
        )
      }
      {
        infoAndGuides.length > 0 && (
          <div>
            <Typography
              variant="h5"
              weight="bold"
            >
              From the Manufacturer
            </Typography>
            <DocumentList
              documents={infoAndGuides}
              returnMessage={returnMessage}
              showReturnMessage={showReturnMessage}
            />
          </div>
        )
      }
      {
        installationAndRentals.length > 0 && (
          <>
            <Typography
              variant="h5"
              weight="bold"
            >
              Installation, Service &amp; Rentals
            </Typography>
            <ul className="sui-list-disc sui-px-6">
              {installationAndRentals.map((rental) => (
                <li key={rental?.name}>
                  <AdditionalResourcesLink
                    name={rental?.name}
                    url={rental?.url}
                    contentType={rental?.contentType}
                  />
                </li>
              ))}
            </ul>
          </>
        )
      }
      {
        diyProjects.length > 0 && (
          <>
            <Typography
              variant="h5"
              weight="bold"
            >
              DIY Projects &amp; Ideas
            </Typography>
            <ul className="sui-list-disc sui-px-6">
              {diyProjects.map((project) => (
                <li key={project?.name}>
                  <AdditionalResourcesLink
                    name={project?.name}
                    url={project?.url}
                    contentType={project?.contentType}
                  />
                </li>
              ))}
            </ul>
          </>
        )
      }
    </div>
  );
};

InfoGuides.displayName = 'InfoGuides';

InfoGuides.propTypes = {
  additionalResources: shape({
    infoAndGuides: arrayOf(shape({
      name: string,
      url: string
    })),
    diyProjects: arrayOf(shape({
      contentType: string,
      name: string,
      url: string
    })),
    installationAndRentals: arrayOf(shape({
      contentType: string,
      name: string,
      url: string
    }))
  }).isRequired,
  brandName: string,
  brandMainPageUrl: string,
  returnMessage: string,
  showReturnMessage: bool
};
InfoGuides.defaultProps = {
  brandName: '',
  brandMainPageUrl: '',
  returnMessage: '',
  showReturnMessage: false
};