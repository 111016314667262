import React from 'react';
import PropTypes from 'prop-types';
import './EtchCustomNavigation.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchCustomNavigation = (props) => {
  const { channel, opts = {} } = props;
  const {
    heading = '',
    ctaLink,
    links = []
  } = opts;

  return (
    <div className="EtchCustomNavigation etch-analytics">
      <nav className="customNav__container">
        {
          ctaLink
          && (
            <CTARenderer className="customNav__heading" ctaLink={ctaLink}>
              {heading}
            </CTARenderer>
          )
        }
        {
          !ctaLink
          && <p className="customNav__heading">{heading}</p>
        }
        <ul className="list--type-plain">
          {links?.length > 0 && links.map((ln, index) => {
            const {
              linkText,
              link
            } = ln || {};
            return (
              <li className="list__item--padding-none" key={`${linkText}--${index}`}>
                {
                  link ? <CTARenderer ctaLink={link}>{linkText}</CTARenderer> : <p>{linkText}</p>
                }
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

EtchCustomNavigation.displayName = 'EtchCustomNavigation';

EtchCustomNavigation.propTypes = {
  opts: PropTypes.shape({
    heading: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    links: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      linkText: PropTypes.string
    })).isRequired,
  }),
  channel: PropTypes.string
};

EtchCustomNavigation.defaultProps = {
  opts: {},
  channel: 'desktop'
};

export default EtchCustomNavigation;
