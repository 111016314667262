import {
  arrayOf as arrayType,
  bool as boolType,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';

export const dataModel = {
  searchModel: params({ keyword: stringType(), navParam: stringType() }).shape({
    relatedResults: shapeType({
      visualNavs: arrayType(shapeType({
        label: stringType(),
        imageId: stringType(),
        webUrl: stringType(),
        categoryId: stringType(),
        imageURL: stringType()
      })),
      visualNavContainsEvents: boolType()
    }),
    dimensions: arrayType(shapeType({
      label: stringType(),
      refinements: arrayType(shapeType({
        refinementKey: stringType(),
      }))
    }))
  })
};
