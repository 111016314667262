import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FontColorSelector from '../../helpers/fontColorSelector';
import AlignmentSelector from '../../helpers/textAlignmentSelector';
import './Paragraph.style.scss';

const alignmentSelector = new AlignmentSelector();
const colorPicker = new FontColorSelector();

/**
 * @group Components
 * Paragraph
 */

const Paragraph = (props) => {
  let classString;

  const { opts = {} } = props;
  const { options } = opts;
  if (options) {
    const textAlignment = (options.textAlignment) ? alignmentSelector.get(options) : options.align;
    const colorString = (options.colorPicker) ? colorPicker.get(options) : options.colorclass;
    classString = classNames(
      textAlignment,
      colorString,
      'paragraph__wrapper'
    );
  }

  let paragraphText = null;
  if (options && options.URL) {
    paragraphText = (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label, react/no-danger
      <a href={opts.options.URL} dangerouslySetInnerHTML={{ __html: opts.text }} />
    );
  } else {
    // eslint-disable-next-line react/no-danger
    paragraphText = <span dangerouslySetInnerHTML={{ __html: opts.text }} />;
  }
  return (
    <p className={classString}>
      {paragraphText}
    </p>
  );
};

Paragraph.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    text: PropTypes.string
  }).isRequired
};

Paragraph.defaultProps = {
};

export default Paragraph;
