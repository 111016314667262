import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './EtchStoryWithImage.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchStoryWithImage = (props) => {
  const { opts = {} } = props;
  const {
    description,
    id,
    images = [{}],
    cta,
    ctaLink,
    title,
    type
  } = opts;
  return (
    <div className="EtchStoryWithImage etch-analytics" id={id} data-module-id={id} data-module-type={type}>
      <div className="storyInfo">
        <h3 className="storyInfo__title">{title}</h3>
        <p className="storyInfo__description">{description}</p>
        {cta
        && (
          <CTARenderer className="storyInfo__btn bttn bttn--primary bttn--inline" ctaLink={ctaLink}>
            <span className="story__btn__content bttn__content">{cta}</span>
          </CTARenderer>
        )}
      </div>
      <div className="storyImage">
        {images
          && images.map((imageList, index) => {
            const { image = {} } = imageList;
            const { src, alt, height, width } = image;
            const imgClass = classNames({
              'img--block': height && width
            });

            return (
              <div className="storyImage__img" key={`story-image-${index}`}>
                <Image
                  stretchy
                  className={imgClass}
                  src={src}
                  alt={alt}
                  height={height}
                  width={width}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

EtchStoryWithImage.propTypes = {
  opts: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    images: PropTypes.instanceOf(Array),
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired
};
EtchStoryWithImage.defaultProps = {
};

export default EtchStoryWithImage;
