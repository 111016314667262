import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './global.scss';
import './BoxHero.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const BoxHero = (props) => {
  const { opts = {}, channel } = props;
  const {
    id,
    ctaLink,
    heroMedia = {},
    heroMediaMobile = {},
    backgroundMedia = {},
    eventLogo = {},
    eventLogoMobile = {},
    heading = '',
    cta,
    collectionLink,
    cornerCaption,
    subHeading
  } = opts;
  const isMobile = channel === 'mobile';
  const backgroundStyle = backgroundMedia.src
    ? {
      'background-image': `'url(${backgroundMedia.src})';
      'background-repeat':'no-repeat';
      'background-size':'100% 100%';`
    }
    : {
      backgroundColor: '#eeeeee'
    };

  const renderHeroMedia = () => {
    const media = isMobile ? heroMediaMobile : heroMedia;
    const { src, alt, height, width } = media;
    const imgClass = classNames({
      'img--block': height && width
    });
    return (
      <picture className="EtchBoxHero__media">
        <source media="(min-width: 640px)" srcSet={src} />
        <Image src={src} className={imgClass} alt={alt} height={height} width={width} />
      </picture>
    );
  };

  const renderEventLogo = () => {
    const logo = isMobile ? eventLogoMobile : eventLogo;
    const { src, alt, height, width } = logo;
    const imgClass = classNames({
      'img--block': height && width
    });
    return (
      <picture>
        <source media="(max-width: 640px)" srcSet={src} />
        <Image
          src={src}
          className={`EtchBoxHero__body__eventLogo ${imgClass}`}
          alt={alt}
          height={height}
          width={width}
        />
      </picture>
    );
  };

  return (
    <div className="EtchBoxHero etch-analytics" data-module-id={id} style={backgroundStyle}>
      {ctaLink && (
        <CTARenderer ctaLink={ctaLink} className="EtchBoxHero__link">
          {renderHeroMedia()}
        </CTARenderer>
      )}
      <div className="EtchBoxHero__body u__text-shadow--none">
        {renderEventLogo()}
        <p className="EtchBoxHero__body__heading u__xlarge u__display-font--black">
          {ctaLink ? (
            <CTARenderer ctaLink={ctaLink} className="EtchBoxHero__link">
              {heading}
            </CTARenderer>
          ) : heading}
        </p>
        <p className="EtchBoxHero__body__subHeading u__medium">
          {ctaLink ? (
            <CTARenderer ctaLink={ctaLink} className="EtchBoxHero__link">
              {subHeading}
            </CTARenderer>
          )
            : subHeading}
        </p>
        {cta && (
          ctaLink
            ? (
              <CTARenderer className="EtchBoxHero__btn bttn bttn--primary bttn--inline" ctaLink={ctaLink}>
                <span className="EtchBoxHero__btn__content bttn__content">{cta}</span>
              </CTARenderer>
            )
            : (
              <span className="EtchBoxHero__btn bttn bttn--primary bttn--inline">
                <span className="EtchBoxHero__btn__content bttn__content">{cta}</span>
              </span>
            )
        )}
      </div>
      <div className="EtchBoxHero__corner">
        {
          collectionLink ? (
            <CTARenderer ctaLink={ctaLink} className="EtchBoxHero__corner__caption">
              {cornerCaption}
            </CTARenderer>
          ) : <p className="EtchBoxHero__corner__caption">{cornerCaption}</p>
        }
      </div>
    </div>
  );
};

BoxHero.propTypes = {
  // eslint-disable-next-line react/require-default-props
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  channel: PropTypes.string
};

BoxHero.defaultProps = {
  channel: 'mobile'
};

export default BoxHero;
