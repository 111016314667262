import classNames from 'classnames';

class ContentAlignmentSelector {
  constructor() {
    this.options = {};
  }

  getAlignmentClass() {
    return classNames({
      'flexbox--left-top': this.options.isLeft_Top,
      'flexbox--left-center': this.options.isLeft_Center,
      'flexbox--left-bottom': this.options.isLeft_Bottom,
      'flexbox--center-top': this.options.isCenter_Top,
      'flexbox--center-center': this.options.isCenter_Center,
      'flexbox--center-bottom': this.options.isCenter_Bottom,
      'flexbox--right-top': this.options.isRight_Top,
      'flexbox--right-center': this.options.isRight_Center,
      'flexbox--right-bottom': this.options.isRight_Bottom,
    });
  }

  get(options) {
    this.options = options || {};
    return this.getAlignmentClass();
  }
}

export default ContentAlignmentSelector;
