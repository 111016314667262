import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/image';
import './visual-navigation-tile.scss';

export const VisualNavigationTile = ({
  href, label, src, isMobileSlider, classes, onClick
}) => {
  return (
    <div className={`visual-category-navigation ${classes}`}>
      <a
        className="vis-nav__link"
        href={href}
        title={label}
        onClick={onClick}
      >
        <div className="vis-nav__image--frame">
          <Image
            src={src}
            alt={label}
            title={label}
            className={classNames('vis-nav__image', { stretchy: !isMobileSlider })}
            height={1}
            width={1}
            lazy
          />
        </div>
        <div className="vis-nav__title">{label}</div>
      </a>
    </div>
  );
};

const displayName = 'VisualNavigationTile';

const propTypes = {
  classes: PropTypes.string,
  href: PropTypes.string.isRequired,
  isMobileSlider: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired
};

const defaultProps = {
  classes: null,
  isMobileSlider: false,
  label: '',
  onClick: () => {}
};

VisualNavigationTile.displayName = displayName;
VisualNavigationTile.propTypes = propTypes;
VisualNavigationTile.defaultProps = defaultProps;
