import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @group Components
 * Accordion component
 */
const Accordion = ({ opts = {}, children, accordionBody }) => {
  const { options = {}, Content = [] } = opts;
  const { accordionSize = {} } = options;
  const accordionClass = classNames({
    'accordion__wrapper ': accordionSize.isLarge,
    'accordion__wrapper accordion__wrapper--medium': accordionSize.isMedium,
    'accordion__wrapper accordion__wrapper--noborders': accordionSize.isLarge_No_Borders,
    'accordion__wrapper accordion__wrapper--medium accordion__wrapper--noborders': accordionSize.isMedium_No_Borders
      || (typeof options.accordionSize === 'undefined'),
  });
  return (
    <section className={accordionClass}>
      {Content.map(function (element, index) {
        return (
          <div className="accordion__item" key={element.row_id}>
            <input
              className="accordion__input"
              id={element.row_id}
              name={`${options.id}fwb_accordionthd`}
              type={(options.singleCollapsible) ? 'radio' : 'checkbox'}
              defaultChecked={element?.options?.openByDefault}
            />
            <label className="accordion__label" htmlFor={element.row_id}>
              <div className="accordion__trigger">
                <div className="accordion__plus" />
              </div>
              {element.text}
            </label>
            <div className="accordion__content-wrapper">
              <div className="accordion__content">
                {accordionBody && accordionBody(index)}
                {children}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

Accordion.propTypes = {
  accordionBody: PropTypes.func,
  children: PropTypes.instanceOf(Array),
  opts: PropTypes.shape({
    Content: PropTypes.instanceOf(Array),
    options: PropTypes.shape({})
  }).isRequired
};

Accordion.defaultProps = {
  children: [],
  accordionBody: null
};

export default Accordion;
