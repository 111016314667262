export const getParentNavParam = (navParam, navigationFilters) => {
  const SEPARATOR = 'Z';
  const navParams = navParam.split(SEPARATOR);
  const paramValue = navParams.map((param) => {
    return parseInt(param, 36);
  });
  const parentParamValue = paramValue.join('+');
  return `${parentParamValue}+${navigationFilters}`;
};

export const getParams = (props) => {
  const {
    opts, parentNavParam, host, channel, storeId
  } = props;

  const NEW_ITEM_PARAM = '+4295117280';
  const NAV_FILTER_PARAM = '10000003+';
  const options = opts.Data ? opts.Data : opts.options;
  const { sortBy, activeRefmts, featuredRecords } = options;
  const ret = {};

  let navParams = options.navigationFilters && options.navigationFilters.join('+');
  let orderField = 'TOP_SELLERS';
  switch (sortBy) {
  case 'bestmatch':
    orderField = 'MOST_POPULAR';
    break;
  case 'toprated':
    orderField = 'TOP_RATED';
    break;
  case 'productname':
    orderField = 'PRODUCT_NAME';
    break;
  case 'brandname':
    orderField = 'BRAND_NAME';
    break;
  case 'price':
    orderField = 'PRICE';
    break;
  case 'mostpopular':
    orderField = 'MOST_POPULAR';
    break;
  case 'newitems':
    orderField = 'NEW_ITEMS';
    navParams += NEW_ITEM_PARAM;
    break;
  default:
    orderField = 'TOP_SELLERS';
    break;

  }

  if (activeRefmts === 'true' && parentNavParam) {
    const navParam = getParentNavParam(parentNavParam, navParams);
    ret.channel = channel;
    ret.navParam = navParam;

  } else if (options.featuredRecords && options.featuredRecords.length > 0) {
    const itemIds = options.featuredRecords;
    if (itemIds?.length) {
      ret.channel = channel;
      ret.itemIds = itemIds;
    }
  } else if (options.navigationFilters && options.navigationFilters.length > 0) {
    ret.channel = channel;
    ret.navParam = `${NAV_FILTER_PARAM}${navParams}`;
  }
  ret.storeId = storeId;
  ret.pageSize = parseInt(options.pageSize, 10);
  ret.startIndex = 0;
  ret.filter = {
    storeFilter: 'ALL'
  };
  ret.orderBy = {
    field: orderField,
    order: options.sortOrder.toUpperCase() === 'DESC'
      ? 'DESC'
      : 'ASC'
  };

  return ret;
};
