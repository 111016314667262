import React from 'react';
import PropTypes from 'prop-types';

/**
 * @group Components
 * Partial
 */

const PartialContent = (props) => {
  const { opts = {}, children } = props;
  const { options } = opts;
  return (
    <div>
      {children}
    </div>
  );
};

PartialContent.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.instanceOf(Array)
};

PartialContent.defaultProps = {
  children: []
};

export default PartialContent;
