import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ContentComponent from '../Content/Content.component';
import './Steps.scss';

/**
 * @group Components
 * Displays article steps
 */

const Steps = (props) => {
  const {
    channel, contentType, deliveryZip, dimensions, disableSwipeNavigation,
    dpdResponse, env, exclude, favorites, host, isCategory, keyword, linkType,
    navParam, onFavoriteChange, onVisNavRender, parentNavParam,
    partialTemplates, plpBanner, showArrows, showFavoritesCount,
    showDpdPod, sku, storeId, visualNavPromos, visualNavResults, children, opts
  } = props;

  const { Content } = opts;

  return (
    <div className="step">
      {children}
      {Content.map((step, index) => {
        return (
          <div key={step.name}>
            <div className="step-header">
              <span id={step.name} className="stepnum">{step.stepCount}</span>
              <div className="step-title">
                {step.text}
              </div>
            </div>
            <div>
              {step.Content.map((item, i) => {
                return (
                  <ContentComponent
                    channel={channel}
                    contentType={contentType}
                    deliveryZip={deliveryZip}
                    dimensions={dimensions}
                    disableSwipeNavigation={disableSwipeNavigation}
                    dpdResponse={dpdResponse}
                    env={env}
                    exclude={exclude}
                    favorites={favorites}
                    host={host}
                    isCategory={isCategory}
                    key={item.id || i}
                    keyword={keyword}
                    linkType={linkType}
                    navParam={navParam}
                    onFavoriteChange={onFavoriteChange}
                    onVisNavRender={onVisNavRender}
                    opts={item}
                    parentNavParam={parentNavParam}
                    partialTemplates={partialTemplates}
                    plpBanner={plpBanner}
                    showArrows={showArrows}
                    showFavoritesCount={showFavoritesCount}
                    showDpdPod={showDpdPod}
                    sku={sku}
                    storeId={storeId}
                    visualNavPromos={visualNavPromos}
                    visualNavResults={visualNavResults}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

Steps.propTypes = {
  opts: PropTypes.shape({
    stepCount: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
    Content: PropTypes.instanceOf(Array),
  }).isRequired,
  children: PropTypes.instanceOf(Array),
  channel: PropTypes.string,
  contentType: PropTypes.string,
  deliveryZip: PropTypes.string,
  dimensions: PropTypes.shape({}),
  disableSwipeNavigation: PropTypes.bool,
  dpdResponse: PropTypes.shape({}),
  env: PropTypes.string,
  exclude: PropTypes.instanceOf(Array),
  favorites: PropTypes.instanceOf(Array),
  host: PropTypes.string,
  isCategory: PropTypes.bool,
  keyword: PropTypes.string,
  linkType: PropTypes.string,
  navParam: PropTypes.string,
  onFavoriteChange: PropTypes.string,
  onVisNavRender: PropTypes.string,
  parentNavParam: PropTypes.string,
  partialTemplates: PropTypes.instanceOf(Array),
  plpBanner: PropTypes.string,
  showArrows: PropTypes.bool,
  showFavoritesCount: PropTypes.bool,
  showDpdPod: PropTypes.bool,
  sku: PropTypes.string,
  storeId: PropTypes.string,
  visualNavPromos: PropTypes.shape({}),
  visualNavResults: PropTypes.shape({})
};

Steps.defaultProps = {
  children: [],
  channel: '',
  contentType: '',
  deliveryZip: '',
  dimensions: {},
  disableSwipeNavigation: false,
  dpdResponse: {},
  env: '',
  exclude: [],
  favorites: [],
  host: '',
  isCategory: false,
  keyword: '',
  linkType: '',
  navParam: '',
  onFavoriteChange: '',
  onVisNavRender: '',
  parentNavParam: '',
  partialTemplates: [],
  plpBanner: '',
  showArrows: false,
  showFavoritesCount: false,
  showDpdPod: false,
  sku: '',
  storeId: '',
  visualNavPromos: {},
  visualNavResults: {}
};

export default Steps;
