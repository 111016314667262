import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import classnames from 'classnames';

export const BulletList = (props) => {
  const {
    bullets,
    margin,
    isMobile,
    hideRichContents
  } = props;

  const listClasses = classnames('sui-w-full sui-list-disc sui-px-6 sui-flex sui-flex-col sui-gap-2', {
    'sui-mt-2': margin
  });
  if (!bullets?.length) {
    return null;
  }

  const filteredBullets = bullets.filter((bullet) => {
    const isString = typeof bullet.value === 'string';
    const hasRichContents = isString
            && bullet.value.match(/(http(s?):)([/|.|\w|\s|(|,|)|-])*\.(?:jpg|gif|png)/gi)?.length;
    const hasSkippableEmbeddedImage = isString
            && bullet.value.indexOf('<img') > 0
            && bullet.value.indexOf('href=') < 0;
    const shouldSkipLink = isString
            && bullet.value.indexOf('<img') < 0
            && bullet.value.match(/(?<=>)(.*?)(?=<\/a>)/gi)?.pop() === '';

    return !((hasRichContents && (hideRichContents || isMobile)) || hasSkippableEmbeddedImage || shouldSkipLink);
  });

  return (
    <ul className={listClasses} data-testid="bullet-list">
      {filteredBullets.map((bullet, bulletIndex) => {
        const isString = typeof bullet.value === 'string';

        return (
          <li key={bullet.guid || `bullet-${bulletIndex}`} data-testid="bullet-list-item">
            {isString
            // eslint-disable-next-line react/no-danger
              ? <span dangerouslySetInnerHTML={{ __html: bullet.value.replace(/^'*(.*)'$/, '$1') }} />
              : bullet.value}
          </li>
        );
      })}
    </ul>
  );
};

BulletList.displayName = 'BulletList';

BulletList.propTypes = {
  bullets: arrayOf(shape({})).isRequired,
  margin: bool,
  isMobile: bool,
  hideRichContents: bool
};

BulletList.defaultProps = {
  margin: false,
  isMobile: false,
  hideRichContents: false
};
