import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import Content from '../Content';
import BackGroundColor from '../../helpers/backgroundColors';
import BackGroundStyles from '../../helpers/backgroundStyles';
import ContentAlignmentSelector from '../../helpers/contentAlignmentSelector';

const styleHelper = new BackGroundStyles();
const colorPicker = new BackGroundColor();
const contentAlignmentSelector = new ContentAlignmentSelector();

const getColumnSize = (options) => {
  return classNames(
    ` grid_${options.width}`,
    {
      ' rail': options.rail,
    },
  );
};

/**
 * @group Layout
 * Displays legacy column component for deprecated content grid
 */

const LegacyColumn = (props) => {
  const { opts = {}, children } = props;
  const { options } = opts;
  const background = options.background || {};
  const styles = styleHelper.get(background);
  const columnSize = getColumnSize(options);
  const columnAlignment = contentAlignmentSelector.get(options.contentAlignment);
  const bgColor = (background.bgPicker) ? colorPicker.get(background.bgPicker) : `${background.color}-bg`;
  const gridClass = classNames(
    columnSize,
    {
      'spad pod': options.spad,
      alpha: options.alpha,
      omega: options.omega,
      bottomBorder: options.bottomBorder,
      'Vertical-Align--center': (!options.contentAlignment && options.vAlignCenter),
    },
    bgColor,
    columnAlignment
  );

  return (
    <div
      className={gridClass}
      style={styles}
      id={opts.id}
    >
      <div className="content">
        {children}
      </div>
    </div>
  );
};

LegacyColumn.propTypes = {
  children: PropTypes.instanceOf(Array),
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    id: PropTypes.string,
  }).isRequired
};
LegacyColumn.defaultProps = {
  children: []
};

export default LegacyColumn;
