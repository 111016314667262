export default {

  getParentNavParam: function getParentNavParam(navParam, navigationFilters) {
    let navParams = navParam.split('Z');
    const paramValue = navParams.map((param) => {
      return parseInt(param, 36);
    });

    const parentParamValue = paramValue.join('+');

    const parentNavParam = parentParamValue + '+' + navigationFilters;

    return parentNavParam;
  }
};