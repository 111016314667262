import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FontSelector from '../../helpers/fontSelector';
import FontColorSelector from '../../helpers/fontColorSelector';
import AlignmentSelector from '../../helpers/textAlignmentSelector';

const fontSelector = new FontSelector();
const alignmentSelector = new AlignmentSelector();
const colorPicker = new FontColorSelector();

const TextCompiler = (props) => {
  const { options, text } = props;
  if (options.URL) {
    return (
      <a /* eslint-disable-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, react/jsx-no-target-blank, max-len, */
        className={
          classNames({
            triggerOverlay: options.openInModal,
            'tip-Opener': options.isTooltip,
          })
        }
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: text }}
        data-contentid={options.popUpId}
        data-overlay-type={options.relationship}
        data-tooltip={(options.isTooltip) ? 'options.URL' : null}
        data-videoid={options.popUpId}
        href={options.URL}
        rel={`${(options.externalLink) ? 'nofollow' : ''}${(options.targetBlank) ? ' noopener noreferrer' : ''}`}
        target={(options.targetBlank) ? '_blank' : ''}
      />
    );
  }
  /* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/no-danger */
  return (<span dangerouslySetInnerHTML={{ __html: text }} />);
};

/**
 * @group Titles
 * Displays title component
 */

const Title = (props) => {
  const { opts = {} } = props;
  const { options } = opts;
  const fontString = (options.titleType) ? fontSelector.get(options) : options.display;
  const textAlignment = (options.textAlignment) ? alignmentSelector.get(options) : options.align;
  const colorString = (options.colorPicker) ? colorPicker.get(options)
    : options.colorclass;
  const classString = classNames(
    fontString,
    textAlignment,
    colorString,
    {
      u__bold: options.u__bold,
      u__italic: options.italic,
      u__underline: options.underline
    },
  );
  const HeadingLevel = `h${options.level}`;
  return (
    <HeadingLevel
      className={classString}
      id={options.name}
    >
      <TextCompiler options={options} text={opts.text} />
    </HeadingLevel>
  );
};

TextCompiler.propTypes = {
  options: PropTypes.shape({
    align: PropTypes.string,
    colorPicker: PropTypes.shape({}),
    externalLink: PropTypes.bool,
    isTooltip: PropTypes.bool,
    italic: PropTypes.bool,
    level: PropTypes.string,
    openInModal: PropTypes.bool,
    popUpId: PropTypes.string,
    relationship: PropTypes.string,
    targetBlank: PropTypes.bool,
    textAlignment: PropTypes.shape({}),
    titleType: PropTypes.shape({}),
    u__bold: PropTypes.bool,
    underline: PropTypes.bool,
    URL: PropTypes.string
  }).isRequired,
  text: PropTypes.string
};

TextCompiler.defaultProps = {
  text: ''
};

Title.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    text: PropTypes.string
  }).isRequired,
};

export default Title;
