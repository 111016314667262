import React from 'react';
import { CustomOptions } from '@one-thd/sui-icons';
import { params, shape, string, useDataModel } from '@thd-nucleus/data-sources';
import { string as stringProp } from 'prop-types';
import { CONFIGURABLE_BLINDS } from '../../constants';

export const ProductCustomOptions = ({ itemId }) => {
  const { data } = useDataModel('product', {
    variables: {
      itemId,
    }
  });

  const product = data?.product || {};
  const customOptionsAvailableClass = 'sui-pl-1 sui-text-sm sui-text-primary';

  const {
    globalCustomConfigurator = {}
  } = product?.info || {};
  const {
    productType
  } = product?.identifiers || {};

  const customExperience = globalCustomConfigurator?.customExperience;
  const configureProduct = productType === CONFIGURABLE_BLINDS;

  if (!customExperience || !configureProduct) {
    return null;
  }

  return (
    <div className="sui-flex sui-items-center sui-mt-1 sui-w-full">
      <CustomOptions size="small" />
      <span className={customOptionsAvailableClass}>Custom Options</span>
    </div>
  );
};

ProductCustomOptions.propTypes = {
  itemId: stringProp.isRequired
};

ProductCustomOptions.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      productType: string(),
    }),
    info: shape({
      globalCustomConfigurator: shape({
        customExperience: string(),
      }),
    }),
  }),
};

ProductCustomOptions.displayName = 'ProductCustomOptions';
