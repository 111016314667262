import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AlignmentSelector from '../../helpers/textAlignmentSelector';
import ListClassSelector from '../../helpers/listClassSelector';
import FontColorSelector from '../../helpers/fontColorSelector';
import NestedList from './NestedList';
import { UUID } from '../util';

const alignmentSelector = new AlignmentSelector();
const listClassSelector = new ListClassSelector();
const fontColorSelector = new FontColorSelector();

/**
 * @group Components
 * Displays list component
 */

const List = (props) => {
  const { opts = {} } = props;
  const { options } = opts;
  const listTypeObj = options.listType || {};
  const ListType = `${(listTypeObj.isOrdered) ? 'ol' : 'ul'}`;
  const textAlignment = (options.textAlignment) ? alignmentSelector.get(options) : options.align;
  const listClass = listClassSelector.get(options);
  const fontClass = fontColorSelector.get(options);
  const classString = classNames('list', textAlignment, listClass, fontClass);
  return (
    <ListType
      id={options.name}
      className={classString}
    >
      {opts.Content.map((element, index) => (
        <li
          key={UUID()}
          className={listClassSelector.get(element)}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: element.text }} />
          <NestedList content={element.Content} />
        </li>
      ))}
    </ListType>
  );
};

List.propTypes = {
  opts: PropTypes.shape({
    Content: PropTypes.instanceOf(Array),
    options: PropTypes.shape({})
  }).isRequired
};

export default List;
