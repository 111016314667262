/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ComponentType from './ComponentType';

const ContentComponent = ({
  dpdNewTab,
  exclude,
  include,
  linkType,
  opts,
  ...rest
}) => {
  if (exclude && exclude.find((type) => type === opts.type)) {
    return null;
  }
  if (include.length > 0) {
    if (include.find((type) => type === opts.type)) {
      return <ComponentType opts={opts} linkType={linkType} dpdNewTab={dpdNewTab} {...rest} />;
    } if (Array.isArray(opts.content) && opts.content.length > 0) {
      return opts?.content?.map
        ? (opts.content.map((cont, i) => (
          <ContentComponent
            include={include}
            linkType={linkType}
            key={cont.id || i}
            opts={cont}
            {...rest}
          />
        )))
        : null;
    }
    return null;
  }
  return <ComponentType exclude={exclude} opts={opts} {...rest} linkType={linkType} dpdNewTab={dpdNewTab} />;
};

export const SpreadContent = (props) => {
  const { opts = { content: [] } } = props;
  return opts?.content?.map
    ? (opts.content.map((cont, i) => (
      <ContentComponent
        {...props}
        opts={cont}
        key={cont.id || i}
      />
    )))
    : null;
};

ContentComponent.propTypes = {
  /** Exclude content of these types */
  dpdNewTab: PropTypes.bool,
  exclude: PropTypes.arrayOf(PropTypes.string),
  showArrows: PropTypes.bool,
  disableSwipeNavigation: PropTypes.bool,
  /** Include only content of these types */
  include: PropTypes.arrayOf(PropTypes.string),
  linkType: PropTypes.string,
  opts: PropTypes.shape({
    content: PropTypes.instanceOf(Array),
    id: PropTypes.string,
    options: PropTypes.shape({}),
    type: PropTypes.string
  }).isRequired
};

ContentComponent.defaultProps = {
  dpdNewTab: false,
  exclude: [],
  include: [],
  linkType: '_self',
  showArrows: true,
  disableSwipeNavigation: false,
};

export default ContentComponent;
