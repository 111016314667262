import React from 'react';
import PropTypes from 'prop-types';

const formMap = {
  isNhance: { endpoint: 'n-hance' },
  isTubShowerInstallation: { endpoint: 'tub-shower-installation' },
  isBathRemodeling: { endpoint: 'bath-remodeling' },
  isClosetAndStorageInstallation: { endpoint: 'custom-home-organization' },
  isElectricalServicesAndInstalltion: { endpoint: 'electrical-services' },
  isFenceInstallation: { endpoint: 'fence-installation' },
  isGeneratorInstallation: { endpoint: 'generator-installation' },
  isInteriorShutters: { endpoint: 'interior-shutters' },
  isSolarPowerSystems: { endpoint: 'solar-power-systems' },
  isSunroomAndPatioCoverInstallation: { endpoint: 'sunroom-patio-cover-installation' },
  isWaterHeaterInstallation: { endpoint: 'water-heater-installation' },
  isWaterSoftnerInstallation: { endpoint: 'water-softener-filters' },
  isWaterHeaterRepair: { endpoint: 'water-heater-repair' },
  isHeatingAndCoolingSystemReplacement: { endpoint: 'hvac-replacement' },
  isHvacMaintenance: { endpoint: 'hvac-maintenance' },
  isHeatingAndCoolingSystemRepair: { endpoint: 'hvac-repair' },
  isTanklessWaterHeaterInstallation: { endpoint: 'tankless-water-heater-installation' },
  isKitchenRemodelCore: { endpoint: 'kitchen-remodel-core' },
  isShowerDoorInstallation: { endpoint: 'shower-door-installation' },
  isWalkinTubs: { endpoint: 'walk-in-tubs' },
};

/**
 * @group Legacy
 * RedBeacon form
 */

const RedBeacon = (props) => {
  const { serviceType } = props;
  return (
    // eslint-disable-next-line max-len
    <iframe src={`//services.homedepot.com/${formMap[serviceType].endpoint}/?source=if`} title="RedBeacon" height="320" width="100%" frameBorder="0" />
  );
};

RedBeacon.propTypes = {
  serviceType: PropTypes.string
};
RedBeacon.defaultProps = {
  serviceType: ''
};

export default RedBeacon;
