import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentAlignmentSelector from '../../helpers/contentAlignmentSelector';
import './Standout.scss';

const contentAlignmentSelector = new ContentAlignmentSelector();

/**
 * @group Components
 * Standout widget renderer
 */

const Standout = (props) => {
  const { children, opts } = props;
  const { options } = opts;
  const bgColorClass = (typeof options.backgroundColor !== 'undefined') ? `${options.backgroundColor}-bg` : '';
  const styles = {
    backgroundImage: (typeof options.backgroundImage !== 'undefined') ? options.backgroundImage : '',
  };
  const alignmentClass = contentAlignmentSelector.get(options.contentAlignment);
  return (
    <div className="stand-out" id={opts.id}>
      <div
        className={classNames(
          'content',
          bgColorClass,
          alignmentClass
        )}
        style={styles}
      >
        {children}
      </div>
    </div>
  );
};

Standout.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundImage: PropTypes.string,
      contentAlignment: PropTypes.string
    }),
  }).isRequired,
  children: PropTypes.instanceOf(Array)
};

Standout.defaultProps = {
  children: []
};
export default Standout;
