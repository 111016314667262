/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { StoryCard } from './StoryCard.component';
import './EtchStoryCard.scss';

const EtchStoryCard = (props) => {
  const { opts = {} } = props;
  const { cards, id, type } = opts;

  return (
    <div className="etchStory etch-analytics" data-module-id={id} data-module-type={type}>
      <div className="etchStory__container">
        {cards.map((card, index) => (
          <div className="etchStory__card" data-module-content-id={id} key={index}>
            <StoryCard {...card} />
          </div>
        ))}
      </div>
    </div>
  );
};

EtchStoryCard.propTypes = {
  opts: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        grandParentId: PropTypes.string,
        hasVideoID: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        image: PropTypes.shape({
          alt: PropTypes.string.isRequired,
          src: PropTypes.string.isRequired,
        }).isRequired,
        link: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool
        ]),
        linkText: PropTypes.string.isRequired,
        parentId: PropTypes.string,
        type: PropTypes.string,
        videoID: PropTypes.string,
      })
    ).isRequired,
    displayType: PropTypes.string,
    grandParentId: PropTypes.string,
    id: PropTypes.string,
    isColumn: PropTypes.bool,
    isEtchStoryCard: PropTypes.bool,
    parentId: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

EtchStoryCard.defaultProps = {
};

EtchStoryCard.displayName = 'EtchStoryCard';

export { EtchStoryCard };
