import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LayoutProviderContext } from './LayoutProviderContext';
import { sanitize, uuidv4 } from './util';
import { LayoutManagerContext } from './LayoutManagerContext';

const CachedExpressions = {};

export class LayoutManager extends Component {

  static dataSourcesData = {};

  state = {
    debug: false
  };

  dataDebouncer = null;

  componentDidMount() {
    const { rules } = this.context;
    const { data: hasData } = this.props;

    const input = {
      ...(this.props.input || {}),//eslint-disable-line
      data: { ...LayoutManager.dataSourcesData },
      managerName: this.props.name//eslint-disable-line
    };

    const data = {
      ...this.props,
      input,
      ...rules
    };

    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('LAYOUT_MANAGER.ready', data);
      window.LIFE_CYCLE_EVENT_BUS.on('LAYOUT_MANAGER.debug', () => {
        this.setState({ debug: true });
      });

      if (hasData) {
        window.LIFE_CYCLE_EVENT_BUS.on('data-sources.data', this.dsListener);
      }
    }

    if (typeof CustomEvent !== 'undefined' && typeof document !== 'undefined') {
      const evt = new CustomEvent('LAYOUT_MANAGER_READY', {
        detail: sanitize(data)
      });
      document.dispatchEvent(evt);
    }

  }

  componentWillUnmount() {
    if (typeof CustomEvent !== 'undefined' && typeof document !== 'undefined') {
      const { name } = this.props;
      const evt = new CustomEvent('LAYOUT_MANAGER_UNMOUNT', {
        detail: {
          name
        }
      });
      document.dispatchEvent(evt);
    }
    window.LIFE_CYCLE_EVENT_BUS.off('data-sources.data', this.dsListener);
  }

  getManagerByName = ({ name: managerName, managers = [] }) => {
    return managers.find((manager) => manager.name === managerName);
  };

  getExpressionById({ id }) {
    const { rules = {} } = this.context;
    const { expressions = [] } = rules;
    return expressions.find((exp) => exp.id === id);
  }

  dsListener = ({ output }) => {
    const { type, variables } = output;
    let productChanged = false;
    let collectionDetailChanged = false;
    if (type === 'product') {
      const { itemId } = variables || {};
      // the lm has an itemId attr on the group then the product must match
      const existingProduct = LayoutManager.dataSourcesData?.product || {};
      const incomingProduct = output.data?.product || output.data?.clientOnlyProduct || {};
      if (this.props?.input?.itemId === variables.itemId) {
        if (existingProduct?.itemId && existingProduct?.itemId !== variables.itemId) {
          productChanged = true;
        }
        LayoutManager.dataSourcesData.product = {
          ...existingProduct,
          ...incomingProduct
        };
      } else if (!this.props?.input?.itemId) {
        LayoutManager.dataSourcesData.product = {
          ...existingProduct,
          ...incomingProduct
        };
      }
    } else {
      if (type === 'collectionDetail') {
        const existingCollectionDetail = LayoutManager.dataSourcesData?.collectionDetail?.data?.collection || {};
        if (existingCollectionDetail?.metadata?.canonicalUrl !== output?.data?.collection?.metadata?.canonicalUrl) {
          collectionDetailChanged = true;
        }
      }
      LayoutManager.dataSourcesData[type] = output;
    }
    if (this.dataDebouncer) {
      clearTimeout(this.dataDebouncer);
    }
    this.dataDebouncer = setTimeout(() => {
      const evt = new CustomEvent('LAYOUT_MANAGER_DATA_UPDATE', {
        detail: JSON.stringify(LayoutManager.dataSourcesData)
      });
      document.dispatchEvent(evt);
    }, 2000);

    if (productChanged || collectionDetailChanged) {
      productChanged = false;
      collectionDetailChanged = false;
      this._cache = null;
      this.forceUpdate();
    }
  };

  processFilterRule = (rule, props = {}, index, context) => {
    const { input } = this.props;
    if (Object.keys(LayoutManager.dataSourcesData).length) {
      input.data = LayoutManager.dataSourcesData;//eslint-disable-line
    }

    input.managerName = this.props.name;//eslint-disable-line
    if (rule.value) {
      return rule.value;
    }
    let expression = this.getExpressionById({
      id: rule.expressionId,
      context
    });
    if (expression) {
      try {
        // ! for opposite of how filter function works
        if (CachedExpressions[rule.expressionId]) {
          expression.fn = CachedExpressions[rule.expressionId];
        } else if (typeof expression.value === 'string') {
          expression.fn = eval( // eslint-disable-line
            `
            (function() {
              return function __process(context, props, index) {
                return ${expression.value};
              }
            })()
          `);
          CachedExpressions[rule.expressionId] = expression.fn;
        }
        if (typeof expression.fn === 'function') {
          const result = expression.fn(input, props, index);
          expression.result = result;
          return !result;
        }
      } catch (err) {
        console.log(err);// eslint-disable-line
        return true;
      }
    }
    return true;
  };

  processSortForGroup = (group, props = {}, index) => {
    const { input } = this.props;
    if (Object.keys(LayoutManager.dataSourcesData).length) {
      input.data = LayoutManager.dataSourcesData;//eslint-disable-line
    }

    input.managerName = this.props.name;//eslint-disable-line

    const sorts = group.sort || [];

    const results = sorts.map((rule) => {

      let expression = this.getExpressionById({ id: rule.expressionId });
      if (expression) {

        try {
          if (CachedExpressions[rule.expressionId]) {
            expression.fn = CachedExpressions[rule.expressionId];
          } else if (typeof expression.value === 'string') {
            expression.fn = eval( // eslint-disable-line
              `
              (function() {
                return function __process(context, props, index) {
                  return ${expression.value};
                }
              })()
            `);
            CachedExpressions[rule.expressionId] = expression.fn;
          }

          if (typeof expression.fn === 'function') {
            const result = expression.fn(input, props, index);
            expression.result = result;
            if (result) {
              const processedIndex = parseFloat(rule.index);
              if (!isNaN(processedIndex)) return processedIndex;//eslint-disable-line
            }
          }
        } catch (err) {
          console.log('error processing rule for rule');//eslint-disable-line
          console.log(err);//eslint-disable-line
        }
      }
      return null;
    })
      .filter((num) => typeof num === 'number');

    if (results && results.length) {
      return Math.min(...results);
    }
    return null;
  };

  sort = (children, manager, input) => {
    let mapped = children.map((child, index) => {

      const { id, name: propName } = child.props || {};
      let name;
      if (child.props?.layoutGroup?.name) {
        name = child.props.layoutGroup.name;
      } else if (child?.type?.layoutGroupName) {
        name = child.type?.layoutGroupName;
      } else {
        name = propName;
      }
      const group = manager.groups.find((grp) => grp.name === name);

      const processedIndex = group
        ? this.processSortForGroup(group, { ...child.props }, index)
        : null;

      return {
        defaultIndex: index,
        child,
        processedIndex
      };
    });

    mapped = mapped.map((rule, index, self) => {
      if (typeof rule.processedIndex === 'number') {
        const existingIndex = self.findIndex((ru) => ru.processedIndex === rule.processedIndex);
        if (existingIndex > -1 && index !== existingIndex) {
          if (typeof console !== 'undefined' && typeof window !== 'undefined' && window.isDebugMode) {
            console.error('WARNING: layout rules have the same index, offsetting by 0.0001');//eslint-disable-line
            const ruleName = rule.child.type?.layoutGroupName || rule.child.props.name;
            const existingIndexName = self[existingIndex]?.child?.type?.layoutGroupName
              || self[existingIndex].child.props.name;
            console.log(`%c rule: ${ruleName} & ${existingIndexName}`, 'background: #222; color: #bada55');//eslint-disable-line
          }
          rule.processedIndex += 0.0001;// eslint-disable-line
        }
      }
      return rule;
    });

    mapped.sort((a, b) => {//eslint-disable-line
      const aProcessed = typeof a.processedIndex === 'number';
      const aIndex = aProcessed
        ? a.processedIndex
        : a.defaultIndex;

      const bProcessed = typeof b.processedIndex === 'number';
      const bIndex = bProcessed
        ? b.processedIndex
        : b.defaultIndex;

      if (aIndex === bIndex) {
        if (aProcessed) {
          return -1;
        }
        return 1;
      }
      if (aIndex < bIndex) {
        return -1;
      }
      if (aIndex > bIndex) {
        return 1;
      }
      return 0;
    });

    return mapped;
  };

  filter = (children = [], manager, input) => {

    const result = children.filter((child, index) => {

      const { id, name: propName } = child.props || {};
      let name;
      if (child.props?.layoutGroup?.name) {
        name = child.props.layoutGroup.name;
      } else if (child?.type?.layoutGroupName) {
        name = child.type?.layoutGroupName;
      } else {
        name = propName;
      }

      const group = manager.groups.find((grp) => grp.name === name);
      if (!group) return true;

      if (group.filter.length === 0) return true;

      return group.filter.every((rule) => {
        return this.processFilterRule(rule, { ...child.props }, index);
      });
    });
    return result;
  };

  processPropRules = (group, props, index) => {
    const { input } = this.props;

    if (Object.keys(LayoutManager.dataSourcesData).length) {
      input.data = LayoutManager.dataSourcesData;//eslint-disable-line
    }

    input.managerName = this.props.name;//eslint-disable-line
    const propsArray = group.prop.map((rule) => {
      const expression = this.getExpressionById({ id: rule.expressionId });
      if (expression) {
        try {
          if (CachedExpressions[rule.expressionId]) {
            expression.fn = CachedExpressions[rule.expressionId];
          } else if (typeof expression.value === 'string') {
            expression.fn = eval( // eslint-disable-line
              `
              (function() {
                return function __process(context, props, index) {
                  return ${expression.value};
                }
              })()
            `);
            CachedExpressions[rule.expressionId] = expression.fn;
          }
          if (typeof expression.fn === 'function') {
            const result = expression.fn(input, props, index);
            expression.result = result;
            if (result) {
              return {
                [rule.name]: rule.value
              };
            }
          }
          return null;
        } catch (err) {
          console.log('error processing rule for rule');//eslint-disable-line
          console.log(err);//eslint-disable-line
        }
        return null;
      }
      return null;
    })
      .filter((exists) => exists);

    const reduced = propsArray.reduce((acc, prop) => {
      return {
        ...acc,
        ...prop
      };
    }, {});
    return reduced;
  };

  overrideProps = (children = [], manager = {}, input) => {
    const { useContextForProps } = this.props;
    let mapped = children.map((childWrapper, index) => {
      const { child } = childWrapper;
      const { id, name: propName } = child.props || {};
      let name;
      if (child.props?.layoutGroup?.name) {
        name = child.props.layoutGroup.name;
      } else if (child?.type?.layoutGroupName) {
        name = child.type?.layoutGroupName;
      } else {
        name = propName;
      }

      const group = (manager.groups || []).find((grp) => grp.name === name);

      if (group && group.prop && group.prop.length) {

        const newProps = this.processPropRules(group, { ...child.props }, index);
        // eslint-disable-next-line max-len
        // @todo useContextForProps is a migration. It should always be true after migration and no more cloning elements
        group.props = newProps;
        if (useContextForProps) {
          return null;
        }
        // delete newProps.children;
        // children could be array or object
        let newChildren;
        if (Array.isArray(child.props.children)) {
          newChildren = child.props.children
            .filter((exists) => exists)
            .map((childObj) => {
              const args = [childObj, {
                key: uuidv4(),
                ...newProps
              }];
              if (childObj.props.children) {
                args.push(childObj.props.children);
              }

              return React.cloneElement.apply(null, args);
            });
        } else if (child.props.children) {
          const args = [child.props.children, {
            key: uuidv4(),
            ...newProps
          }];
          if ((child.props.children && child.props.children.props.children)) {
            args.push(child.props.children.props.children);
          }

          newChildren = React.cloneElement.apply(null, args);
        }

        return {
          ...childWrapper,
          child: React.cloneElement(
            child, {
              ...child.props
            },
            newChildren
          )
        };
      }

      return childWrapper;
    });

    if (useContextForProps) {
      return children;
    }

    return mapped;
  };

  toChildren = (children = []) => {
    return children.map(({ child }) => child);
  };

  render() {
    const { name, input, useContextForProps } = this.props;
    const { debug } = this.state;
    const { rules, onError } = this.context;
    const managers = rules?.managers || [];

    const manager = this.getManagerByName({
      name: this.props.name,
      managers
    });
    if (!manager) {
      return this.props.children;//eslint-disable-line
    }

    const children = React.Children.toArray(this.props.children);//eslint-disable-line
    const managerContext = {
      name,
      input,
      manager,
      rules,
      onError,
      useContextForProps
    };

    return (
      <LayoutManagerContext.Provider value={managerContext}>
        {this.toChildren(
          this.overrideProps(
            this.sort(
              this.filter(children, manager, input), // returns children
              manager,
              input
            ), manager, input
          )
        )}
      </LayoutManagerContext.Provider>
    );
  }
}

LayoutManager.contextType = LayoutProviderContext;

LayoutManager.propTypes = {
  children: PropTypes.node,
  input: PropTypes.shape({
    itemId: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    filters: PropTypes.shape({}),
    sort: PropTypes.shape({})
  }),
  data: PropTypes.bool,
  useContextForProps: PropTypes.bool
};

LayoutManager.defaultProps = {
  children: null,
  input: {},
  rules: {},
  data: false,
  useContextForProps: false
};
