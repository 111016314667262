import {
  arrayOf as arrayType,
  bool as boolType,
  shape as shapeType,
  string as stringType,
  number as numberType,
  client,
  extend,
  alias,
  customType,
  params
} from '@thd-nucleus/data-sources';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import {
  ProductATC,
  ProductBadge,
  ProductCustomOptions,
  ProductIdentifier,
  ProductImage,
  ProductPod,
  ProductRating,
  ProductSample
} from '@thd-olt-component-react/product-pod';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';

const Product = {
  itemId: stringType(),
  dataSources: stringType(),
  info: shapeType({
    hidePrice: boolType(),
    isBrioSku: boolType().client(),
  }),
  identifiers: shapeType({
    itemId: stringType(),
    brandName: stringType()
  }),
  fulfillment: params({ storeId: stringType() }).shape({
    fulfillmentOptions: arrayType(shapeType({
      type: stringType(),
      fulfillable: boolType(),
      services: arrayType(shapeType({
        type: stringType(),
        locations: arrayType(shapeType({
          inventory: shapeType({
            isInStock: boolType(),
            isOutOfStock: boolType(),
            isLimitedQuantity: boolType()
          }),
          isAnchor: boolType()
        }))
      }))
    }))
  }),
  availabilityType: shapeType({
    type: stringType(),
    discontinued: boolType()
  })
};

const wrappedProduct = extend(
  Product,
  Favorite.dataModel.product,
  FulfillmentPodShipping.dataModel.product,
  FulfillmentPodStore.dataModel.product,
  Price.dataModel.product,
  ProductATC.dataModel.product,
  ProductBadge.dataModel.product,
  ProductCustomOptions.dataModel.product,
  ProductHeader.dataModel.product,
  ProductIdentifier.dataModel.product,
  ProductImage.dataModel.product,
  ProductPod.dataModel.product,
  ProductRating.dataModel.product,
  ProductSample.dataModel.product,
  SuperSkuMiniSwatches.dataModel.product
);

export const dataModel = {
  dpdSearchModel: alias('searchModel').params({
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    navParam: stringType(),
    keyword: stringType(),
    itemIds: arrayType(stringType()),
    storeId: stringType(),
    storefilter: customType('StoreFilter').enum(['IN_STORE', 'ALL', 'ONLINE'], 'ALL'),
    additionalSearchParams: customType('AdditionalParams').shape({
      callback: stringType()
    })
  }).shape({
    id: stringType(),
    products: params({
      pageSize: numberType(),
      startIndex: numberType(),
      orderBy: customType('ProductSort').shape({ field: customType('ProductSortField')
        .enum([
          'TOP_SELLERS',
          'MOST_POPULAR',
          'TOP_RATED',
          'NEW_ITEMS',
          'BEST_MATCH',
          'PRODUCT_NAME',
          'BRAND_NAME',
          'PRICE'],
        'TOP_SELLERS'),
      order: customType('SortOrder').enum(['ASC', 'DESC'], 'ASC') })
    }).arrayOf(shapeType(wrappedProduct)),
    searchReport: shapeType({
      totalProducts: numberType(),
      sortBy: stringType(),
    }),
    metadata: shapeType({
      canonicalUrl: stringType()
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayType(shapeType({
        dimensionName: stringType(),
        label: stringType(),
        refinementKey: stringType()
      }))
    })
  })
};
