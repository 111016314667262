import React from 'react';
import classNames from 'classnames';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { CategoryBlockPropType } from './Helper';

const CategoryBlock = ({
  categoryName,
  categoryImageURL,
  properUrl,
  impressionData
}) => {

  const style = {
    categoryBlockWrapper: classNames(
      'category-block',
      'sui-flex',
      'sui-items-center',
      'sui-justify-center',
      'sui-border-1',
      'sui-border-primary',
      'sui-border-solid',
      'sui-w-full',
      'sui-p-1',
      'sm:sui-px-3'
    ),
    categoryBlockInnerContainer: classNames(
      'sui-flex',
      'sui-flex-col',
      'sui-items-center'
    ),
    categoryBlockImageWrapper: classNames(
      'sui-flex',
      'sui-justify-center',
      'sui-w-28',
      'sui-my-3'
    )
  };

  const impressionContextData = useImpression({
    data: {
      ...impressionData,
      category: '',
      component: 'Tile',
      name: 'ShopAll',
      position: 0,
      id: impressionData.category
    }
  });

  return (
    <div
      ref={impressionContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionContextData.clickID}
      className={style.categoryBlockWrapper}
    >
      <div className={style.categoryBlockInnerContainer}>
        <Typography variant="h4" align="center">{categoryName !== 'All' ? categoryName : ''}</Typography>
        <div className={style.categoryBlockImageWrapper}>
          <img
            className="sui-w-auto sui-max-w-full"
            src={categoryImageURL}
            width="1"
            height="1"
            alt={categoryName}
          />
        </div>
        <Button
          href={properUrl}
          variant="text"
        >Shop All
        </Button>
      </div>
    </div>
  );
};

CategoryBlock.propTypes = CategoryBlockPropType;

export { CategoryBlock };