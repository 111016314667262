/* eslint-disable */
import classNames from 'classnames';

class BackGroundColor {
  constructor() {
  }

  get(picker) {
    return classNames({
      'u__bgColor--brand': picker.isthdOrange,
      'thdBlack-bg': picker.isthdBlack,
      'u__bgColor--dark': picker.isthdGray20,
      'thdGray40-bg': picker.isthdGray40,
      'thdGray-bg': picker.isthdGray,
      'thdGray60-bg': picker.isthdGray60,
      'thdGrayBB-bg': picker.isthdGrayBB,
      'thdGray80-bg': picker.isthdGray80,
      'thdGrayDD-bg': picker.isthdGrayDD,
      'u__bgColor--faded': picker.isthdGrayEE,
      'u__bgColor--reverse': picker.isthdWhite,
      'thdListhighlight-bg': picker.isthdListhighlight,
      'thdSeasonLink-bg': picker.isthdSeasonLink,
      'thdHeaderBg-bg': picker.isthdHeaderBg,
      'thdGardenClub-bg': picker.isthdGardenClub,
      'thdHomeService-bg': picker.isthdHomeService,
      'thdSpecialBuy-bg': picker.isthdSpecialBuy,
      'thdHoliday-bg': picker.isthdHoliday,
      'thdClearance-bg': picker.isthdClearance,
      'thdBulkPrice-bg': picker.isthdBulkPrice,
      'thdNLPGreen-bg': picker.isthdNLPGreen,
      'thdOutdoorGarden-bg': picker.isthdOutdoorGarden
    })
  }
}

export default BackGroundColor;
