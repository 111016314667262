export const carouselTypes = {
  PLA: 'pla',
  SEO: 'seo'
};

export const BANNER = 'banner';
export const PRODUCT = 'product';

export const bannerTypes = {
  HERO: 'hero',
  LEADERBOARD: 'leaderboard',
  MIDDLE: 'middle', // pip
  SKYSCRAPER: 'skyscraper'
};

export const PIP_BANNER_AB_TESTING_HEADER_KEY = 'use-data-model-for-targeting';
export const schemas = {
  SEARCH_SPONSORED: 'search_sponsored',
  BROWSE_SPONSORED: 'browse_sponsored',
  CAT_SPONSORED: 'cat_sponsored',
  PIP_SPONSORED: 'pip_sponsored',
  LOCALAD_SPONSORED: 'localad_sponsored',
  OC_SPONSORED: 'oc_sponsored',
  NO_RESULTS_FOUND_SPONSORED: 'no_results_found_sponsored',
  HP_SPONSORED: 'hp_sponsored',
  HP_SPONSORED_AUTH: 'hp_sponsored_auth',
  STOREPAGE_SPONSORED: 'storepage_sponsored',
  THANKYOUPAGE_SPONSORED: 'thankyoupage_sponsored',
  INGRID_SPONSORED: 'ingrid_sponsored',
  CART_SPONSORED: 'cart_sponsored',
  EVENT_BROWSE_SPONSORED: 'event_browse_sponsored',
  ATC_SPONSORED: 'atc_sponsored',
};
