import React from 'react';
import PropTypes from 'prop-types';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';

const specialBuyTypes = {
  EtchSBOTD: 'sbotd',
  EtchDecorSBOTW: 'decor',
  EtchProSBOTW: 'pro',
};

const SpecialBuyModule = ({ opts }) => {
  const id = opts.id;
  const type = opts.type;

  return (
    <div className="etch-analytics" data-module-id={id}>
      <SpecialBuyBanner specialBuyType={specialBuyTypes[type]} />
    </div>
  );
};

SpecialBuyModule.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default SpecialBuyModule;
