export const UUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return 'ss-s-s-s-sss'.replace(/s/g, s4);
};

export default {
  UUID
};