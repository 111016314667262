import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';

class Video extends Component {
  static loaded = false;

  componentDidMount() {
    if (Video.loaded) return;

    const script = document.createElement('script');
    script.src = '//players.brightcove.net/66036796001/4JE-ofbdg_default/index.min.js';
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    script.onLoad = () => {
      Video.loaded = true;
    };
    document.body.appendChild(script);
  }

  render() {
    const { options = {} } = this.props;
    const { height, width } = options;
    const imgClass = classNames({
      'img--block': height && width
    });
    return (options.imageSrc)
      ? (
        <div
          className="content_image"
          id={options.name}
          style={(options.bcpopup) ? {}
            : {
              height: options.height + 'px',
              width: options.width + 'px',
            }}
        >
          <button
            type="button"
            data-video-id={options.ID}
            data-video-target={(options.bcpopup) ? '' : '#{options.name}'}
          >
            <Image
              src={options.imageSrc}
              alt={options.name}
              stretchy
              height={height}
              width={width}
              className={imgClass}
            />
          </button>
        </div>
      )
      : (
        <div style={(options.height) ? { height: options.height + 'px' } : {}}>
          <video
            data-account="66036796001"
            data-player="4JE-ofbdg"
            data-video-id={options.ID}
            data-embed="default"
            className="bc-video video-js"
            controls
            muted
          />
        </div>
      );
  }
}

/**
 * @group Components
 * BrightCove player
 */

function BrightCove(props) {
  let { opts = {} } = props;
  return (
    <Video options={opts} />
  );
}

Video.propTypes = {
  options: PropTypes.shape({
    bcpopup: PropTypes.bool,
    height: PropTypes.number,
    ID: PropTypes.string,
    imageSrc: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.number
  }).isRequired
};

BrightCove.propTypes = {
  opts: PropTypes.shape({
    bcpopup: PropTypes.bool,
    height: PropTypes.number,
    ID: PropTypes.string,
    imageSrc: PropTypes.string,
  }).isRequired
};

export default BrightCove;
