import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './global.scss';
import './EtchDynamicProduct.scss';

const EtchDynamicProduct = (props) => {
  const {
    opts = {}, channel, displayLabel = '', categoryLabel = '', shopAllLink = '', isManual
  } = props;
  const {
    curationType,
    cta,
    ctaLink,
    heading,
    subHeading,
    productDisplay,
    overwriteContent,
    bayBackgroundColor = '#FFF',
    bayBackgroundMobile = {},
    bayBackgroundDesktop = {},
    eventLogo = {},
    wrapText
  } = opts;

  const isMobile = channel === 'mobile';

  let bayHeading = ''; let baySubheading = ''; let bayBackgroundStyle = {
    background: 'transparent;'
  }; let bayCTA = 'Shop All'; let bayCTALink = shopAllLink; let
    bayEventLogo = {};

  if (overwriteContent || isManual) {
    bayHeading = heading;
    baySubheading = subHeading;
  } else {
    bayHeading = categoryLabel;
    baySubheading = displayLabel;
  }

  if (isManual) {
    bayCTA = cta;
    bayCTALink = ctaLink;
  }

  bayBackgroundStyle = {
    backgroundColor: `${bayBackgroundColor}`
  };

  if (eventLogo?.src) {
    bayEventLogo.src = eventLogo.src;
    bayEventLogo.alt = eventLogo.alt;
  }

  if (bayBackgroundDesktop?.src) {
    bayBackgroundStyle = {
      backgroundImage: `url('${bayBackgroundDesktop.src}')`
    };
  }

  if (isMobile && bayBackgroundMobile?.src) {
    bayBackgroundStyle = {
      backgroundImage: `url('${bayBackgroundMobile.src}')`
    };
  }

  if (!bayCTALink && !bayHeading) return null;

  const wrapperClass = classNames('EtchDynamicProduct', {
    'EtchDynamicProduct--wrap': wrapText,
  });

  const { height, width, src, alt } = bayEventLogo;

  const imgClass = classNames({
    'img--block': height && width
  });

  return (
    <div className={wrapperClass} data-test-id="etch-dynamic-product" style={bayBackgroundStyle}>
      {
        src && (
          <div className="icon_wrapper">
            <Image className={`stretchy ${imgClass}`} height={height} width={width} src={src} alt={alt} />
          </div>
        )
      }
      {bayHeading && (
        <div className="header_title">
          <span>{bayHeading}</span>
        </div>
      )}
      {baySubheading && (
        <div className="header_subtitle">
          <span>
            {baySubheading}
          </span>
        </div>
      )}
      {bayCTA && bayCTALink
      && (
        <button className="bttn bttn--primary bttn--inline EtchDynamicProduct_shop-all" type="button">
          <span className="bttn__content cta--link">
            {bayCTALink ? <a href={bayCTALink} alt={bayCTALink}>{bayCTA}</a> : bayCTA}
          </span>
        </button>
      )}
    </div>
  );
};

EtchDynamicProduct.propTypes = {
  // eslint-disable-next-line react/require-default-props
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  channel: PropTypes.string,
  isManual: PropTypes.bool,
  displayLabel: PropTypes.string,
  categoryLabel: PropTypes.string,
  shopAllLink: PropTypes.string
};

EtchDynamicProduct.defaultProps = {
  channel: 'desktop',
  isManual: false,
  displayLabel: '',
  categoryLabel: '',
  shopAllLink: ''
};

export {
  EtchDynamicProduct
};
