import React, { useContext } from 'react';
import { PaintSwatches } from '@one-thd/sui-icons';
import PropTypes from 'prop-types';
import {
  useDataModel, params, string, shape, bool
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';

export const ProductSample = ({ itemId }) => {
  const { channel } = useContext(ExperienceContext);
  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const { product } = data || {};
  const isMobile = channel === 'mobile';
  const canonicalUrl = product?.identifiers?.canonicalUrl;
  const samplesAvailable = product?.info?.samplesAvailable;
  const target = isMobile ? '_self' : '_blank';

  if (!samplesAvailable) return null;

  return (
    <a
      data-testid="product-samples"
      className="sui-flex sui-items-center sui-mt-1 sui-w-full"
      href={canonicalUrl}
      target={target}
    >
      <PaintSwatches size={isMobile ? 'regular' : 'large'} />
      <span className="sui-pl-1 sui-text-sm sui-text-primary">Samples Available</span>
    </a>
  );
};

ProductSample.displayName = 'ProductSample';

ProductSample.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      samplesAvailable: bool(),
    }),
    identifiers: shape({
      canonicalUrl: string(),
    }),
  }),
};

ProductSample.propTypes = {
  itemId: PropTypes.string.isRequired,
};
