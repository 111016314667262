import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { CategoryTab } from '../CategoryTab/CategoryTab';
import { MobileCategoryTab } from '../MobileCategoryTab/MobileCategoryTab';
import styles from './special-buy-categories.module.scss';

const cNames = classNames.bind(styles);

const generateKey = (categoryId, index) => `category-tab-${categoryId || index}`;

const SpecialBuyCategories = () => {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    isCondensedLayout,
    isSpecialBuyDataLoading
  } = useSpecialBuy();

  const categoriesMemo = useMemo(() => {
    const TabType = isCondensedLayout ? MobileCategoryTab : CategoryTab;
    return categories.map((category, index) => (
      <TabType
        category={category}
        isSelected={category === selectedCategory}
        key={generateKey(category.categoryId, index)}
        onClick={() => setSelectedCategory(category)}
      />
    ));
  }, [categories, selectedCategory, setSelectedCategory, isCondensedLayout]);

  return (
    <section className={cNames('special-buy-categories')}>
      <LoadingPlaceholder
        type="rect"
        ready={!isSpecialBuyDataLoading}
        showLoadingAnimation
        color="#eeeeee"
        style={{
          height: isCondensedLayout ? '160px' : '50px',
          marginTop: isCondensedLayout ? '10px' : 0,
          marginBottom: '10px',
          marginRight: 0,
          width: '700px',
          maxWidth: '100%',
        }}
      >
        {categoriesMemo}
      </LoadingPlaceholder>
    </section>
  );
};

SpecialBuyCategories.displayName = 'SpecialBuyCategories';

export { SpecialBuyCategories };
