/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@thd-olt-component-react/carousel';
import { extend } from '@thd-nucleus/data-sources';
import { dataModel as dpdSearchModel } from '../../../../hooks/dpdDataModel';
import { CustomDesktopProductPod } from './CustomDesktopProductPod';
import { CustomMobileProductPod } from './CustomMobileProductPod';

const SCREEN_MAX_SIZE = 1024;

const DPDCarousel = (props) => {

  const {
    carouselConfig,
    skus = [],
    channel,
    showFavoritesCount,
    storeId,
    storeZip
  } = props;

  const isMobile = channel === 'mobile';
  if (typeof window !== 'undefined' && window.innerWidth > 768) {
    carouselConfig.screenMaxSize = SCREEN_MAX_SIZE + 20; // 20 for padding
  }

  return (
    <Carousel {...carouselConfig}>
      {skus.map((sku, index) => {
        const { identifiers = {} } = sku;
        const { itemId } = identifiers;
        const key = `${itemId || 'missing-item-id'}-${index}`;
        return (
          <div
            className="product-pod-v7"
            key={key}
          >
            {isMobile
              && (
                <CustomMobileProductPod
                  index={index}
                  itemId={itemId}
                  showFavoritesCount={showFavoritesCount}
                  storeId={storeId}
                  storeZip={storeZip}
                />
              )}
            {!isMobile
              && (
                <CustomDesktopProductPod
                  index={index}
                  itemId={itemId}
                  showFavoritesCount={showFavoritesCount}
                  storeId={storeId}
                />
              )}
          </div>
        );
      })}
    </Carousel>
  );
};

DPDCarousel.dataModel = extend(
  {},
  dpdSearchModel,
  CustomDesktopProductPod,
  CustomMobileProductPod
);

DPDCarousel.propTypes = {
  channel: PropTypes.string,
  skus: PropTypes.instanceOf(Array),
  storeId: PropTypes.string,
  storeZip: PropTypes.string,
  showFavoritesCount: PropTypes.bool,
  carouselConfig: PropTypes.shape({
    screenMaxSize: PropTypes.string
  })
};

DPDCarousel.defaultProps = {
  channel: 'desktop',
  skus: [],
  storeId: undefined,
  storeZip: undefined,
  showFavoritesCount: false,
  carouselConfig: {
    screenMaxSize: '2000'
  }
};

export default DPDCarousel;
