import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './EtchCopyBlockHeader.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchCopyBlockHeader = (props) => {

  const { opts = {} } = props;
  const {
    logoMedia = {},
    heading,
    cta,
    ctaLink,
    linkList,
    subHeading,
    id
  } = opts;

  const { src, alt, height, width } = logoMedia;
  const imgClass = classNames({
    'img--block': height && width
  });

  return (
    <div className="EtchCopyBlockHeader etch-analytics">
      <div className="EtchCopyBlockHeader__padding">
        {src
          && <Image src={src} alt={alt} height={height} width={width} className={imgClass} />}
        {ctaLink
          ? (
            <CTARenderer ctaLink={ctaLink}>
              <p className="EtchCopyBlockHeader__heading u__large u__display-font--black">
                {heading}
              </p>
            </CTARenderer>
          )
          : (
            <p className="EtchCopyBlockHeader__heading u__large u__display-font--black">
              {heading}
            </p>
          )}
        {(ctaLink && subHeading)
          && (
            <CTARenderer ctaLink={ctaLink}>
              <p className="EtchCopyBlockHeader__subHeading u__medium ">{subHeading}</p>
            </CTARenderer>
          )}
        {(!ctaLink && subHeading)
          && <p className="EtchCopyBlockHeader__subHeading u__medium ">{subHeading}</p>}
        {cta
          && (
            <CTARenderer className="bttn bttn--primary bttn--inline" ctaLink={ctaLink}>
              <span className="bttn__content">{cta}</span>
            </CTARenderer>
          )}
        {linkList?.length > 0
          && (
            <ul className="EtchCopyBlockHeader__link  list list--type-plain" data-module-content-id={id}>
              {linkList.map((ln, index) => {
                const { link, linkText } = ln;
                return link
                  ? (
                    <li className="list__item" key={`link-text-${index}`}>
                      <a className="list__link" href={link}>{linkText}</a>
                    </li>
                  )
                  : (
                    <li className="list__item" key={`link-text-${index}`}>{linkText}</li>
                  );
              })}
            </ul>
          )}
      </div>
    </div>
  );
};

EtchCopyBlockHeader.displayName = 'EtchCopyBlockHeader';

EtchCopyBlockHeader.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    logoMedia: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    heading: PropTypes.string,
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    linkList: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      linkText: PropTypes.string
    })),
    subHeading: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

EtchCopyBlockHeader.defaultProps = {
};

export default EtchCopyBlockHeader;
