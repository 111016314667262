import React, { useRef, useEffect } from 'react';
import { string } from 'prop-types';

export const Salsify = ({ salsifyRichContent, parentId }) => {
  const iframeRef = useRef();

  useEffect(() => {
    // Unfortunate but necessary due to the dynamic scripts in the Salsify content changing
    // the layout as they load in and causing the iframe to scroll.
    const interval = setInterval(() => {
      if (iframeRef.current
        // to ensure that scrollheight is actually available before proceeding with height adjustment
        && iframeRef.current.contentWindow?.document?.body?.scrollHeight !== undefined
        && iframeRef.current.contentWindow?.document?.body?.scrollHeight !== null
        && iframeRef.current.style.height !== iframeRef.current.contentWindow.document.body.scrollHeight + 20 + 'px') {
        iframeRef.current.style.height = iframeRef.current.contentWindow.document.body.scrollHeight + 20 + 'px';
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <iframe
      title="salisfy-content"
      ref={iframeRef}
      id={parentId}
      /* eslint-disable no-script-url */
      srcDoc={salsifyRichContent}
      data-testid="salsify"
      className="sui-w-full"
    />
  );
};

Salsify.displayName = 'Salsify';

Salsify.propTypes = {
  salsifyRichContent: string.isRequired,
  parentId: string.isRequired
};