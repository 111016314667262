import { useDataModel } from '@thd-nucleus/data-sources';
import { useContext, useRef } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { getParams } from './dpd.helpers';
import Helpers from '../components/etch2/components/DPD/DPDHelpers';

export const useDPD = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { storeId, storeZip } = useStore();
  const params = getParams(props);

  const usableStoreId = params.storeId || props.storeId || storeId;
  const usableStoreZip = params.storeZip || params.zipCode || props.storeZip || props.zipCode || storeZip;
  const skuRef = useRef([]);
  const storedMetadata = useRef({});
  const hookOpts = {
    channel: channel?.toUpperCase(),
    navParam: params.navParam || '',
    keyword: params.keyword || '',
    orderBy: params.orderBy || {},
    itemIds: params.itemIds,
    pageSize: params.itemIds
      ? params.itemIds.length
      : Math.min((25 - (props.startIndex || 0)), props.pageSize),
    startIndex: params.itemIds
      ? 0
      : props.startIndex,
    storeId: usableStoreId,
    zipCode: usableStoreZip
  };

  const loadedStoreId = useRef(usableStoreId);
  const loadedStoreZip = useRef(usableStoreZip);

  const { data, loading, error, variables } = useDataModel('dpdSearchModel', {
    variables: {
      ...hookOpts,
      additionalSearchParams: {
        callback: JSON.stringify(hookOpts.orderBy)
      },
      storefilter: 'ALL'
    },
    skip: (!hookOpts.keyword && !hookOpts.navParam && !hookOpts.itemIds) || hookOpts.startIndex >= 24,
    ssr: true
  });

  if (loading) {
    return {
      loading,
      storeId: loadedStoreId.current,
      storeZip: loadedStoreZip.current,
      skus: skuRef.current,
      ...storedMetadata.current
    };
  }

  if (variables?.storeId) {
    loadedStoreId.current = variables.storeId;
  }
  if (variables?.zipCode || variables?.storeZip) {
    loadedStoreZip.current = variables?.zipCode || variables.storeZip;
  }

  // logic parsing response here:
  let skus = data?.searchModel?.products || [];
  const { removeOOSSkus } = props;
  if (removeOOSSkus) {
    const filteredSkus = skus.filter((sku) => {
      if (sku.fulfillment) {
        return ProductPodUtils.isATCEnabled(sku);
      }
      return true;
    });
    skus = filteredSkus.length ? filteredSkus : skus;
  }
  let categoryLabel = '';
  let shopAllLink = '';
  let totalProducts = Math.min(data?.searchModel?.searchReport?.totalProducts || 0, 24);

  if (skus && skus.length > 0) {
    const { metadata = {}, searchReport = {}, taxonomy = {} } = data?.searchModel;
    const { breadCrumbs = [] } = taxonomy || {};
    const sortByResponseParam = searchReport?.sortBy;
    const { canonicalUrl } = metadata || {};
    shopAllLink = canonicalUrl
      ? `${canonicalUrl}?sortby=${sortByResponseParam}&catStyle=ShowProducts`
      : '';
    if (breadCrumbs.length > 0) {
      const leafCategory = breadCrumbs.filter((crumb) => (crumb.dimensionName === 'Category')).pop();
      const filterLabel = Helpers.setRefinementFilterLabel(
        breadCrumbs,
        sortByResponseParam
      );
      const leafNodeLabel = (leafCategory && leafCategory.label);
      categoryLabel = Helpers.getCategoryLabel({ filterLabel, leafNodeLabel });
    }
  }
  if (hookOpts.itemIds) {
    totalProducts = skus.length;
    skuRef.current = [...skus];
  } else {
    skuRef.current.splice(hookOpts.startIndex, hookOpts.pageSize, ...skus);
  }

  storedMetadata.current = {
    shopAllLink,
    categoryLabel,
    totalProducts
  };
  return {
    skus: skuRef.current,
    shopAllLink,
    categoryLabel,
    storeId: loadedStoreId.current,
    storeZip: loadedStoreZip.current,
    totalProducts
  };
};
