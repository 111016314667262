import React from 'react';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import Content from '../Content/Content.component';
import { EtchPlaceholder } from '../Placeholder/EtchPlaceholder';

const getEtchContent = ({
  dpdNewTab,
  shouldPrioritizeCtaImageFetch,
  env,
  templates,
  linkType,
  ...rest
}) => templates.reduce((acc, template, templateIndex) => [
  ...acc,
  ...template.content.map((content, contentIndex) => (
    <Content
      // host={utils.getHost({ env })}
      key={`etch--${templateIndex}-${content.id}-${contentIndex}`}
      opts={content}
      linkType={linkType}
      dpdNewTab={dpdNewTab}
      shouldPrioritizeCtaImageFetch={shouldPrioritizeCtaImageFetch}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  ))
], []);

const Etch = ({
  apiKey,
  dpdNewTab,
  shouldPrioritizeCtaImageFetch,
  env,
  preview,
  templatePath,
  templates = [],
  linkType,
  ...rest
}) => {

  // Need to have templates or template path to render
  if (!(templates.length)) {
    return null;
  }
  // const goToAnchor = () => {
  //   if (typeof window !== 'undefined') {
  //     const { hash } = window && window.location;
  //     if (hash !== '') {
  //       setTimeout(() => {
  //         const id = hash.replace('#', '');
  //         const element = document.getElementById(id);
  //         if (element) {
  //           element.scrollIntoView(true);
  //           if (rest.channel === 'mobile') {
  //             // Need to reduce fixed header height for mobile
  //             const offsetHeight = 50;
  //             const { scrollY } = window;
  //             if (scrollY) {
  //               window.scroll(0, scrollY - offsetHeight);
  //             }
  //           }
  //         }
  //       }, 1000);
  //     }
  //   }
  // };
  const content = getEtchContent({
    ...rest,
    dpdNewTab,
    shouldPrioritizeCtaImageFetch,
    env,
    templates,
    linkType
  });
  return (
    <LoadingPlaceholder
      customPlaceholder={EtchPlaceholder()}
      ready
    >
      {content}
    </LoadingPlaceholder>
  );
};

Etch.propTypes = {
  apiKey: PropTypes.string,
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  shouldPrioritizeCtaImageFetch: PropTypes.bool,
  contentType: PropTypes.string,
  dimensions: PropTypes.instanceOf(Array),
  dpdNewTab: PropTypes.bool,
  env: PropTypes.oneOf([
    'dev',
    'stage',
    'prod-beta',
    'prod'
  ]),
  /** Exclude content of these types */
  exclude: PropTypes.arrayOf(PropTypes.string),
  favorites: PropTypes.arrayOf(PropTypes.number),
  /** Include only content of these types */
  include: PropTypes.arrayOf(PropTypes.string),
  isCategory: PropTypes.bool,
  linkType: PropTypes.string,
  onFavoriteChange: PropTypes.func,
  onVisNavRender: PropTypes.func,
  parentNavParam: PropTypes.string,
  partialTemplates: PropTypes.instanceOf(Array),
  preview: PropTypes.bool,
  sku: PropTypes.instanceOf(Array),
  storeId: PropTypes.string,
  /** Path to etch json file */
  templatePath: PropTypes.string,
  /** Array of etch content definitions */
  templates: PropTypes.instanceOf(Array),
  visualNavPromos: PropTypes.instanceOf(Array)
};

Etch.defaultProps = {
  apiKey: null,
  channel: 'desktop',
  shouldPrioritizeCtaImageFetch: false,
  contentType: 'categorypage',
  dimensions: [],
  dpdNewTab: false,
  env: 'prod',
  exclude: [],
  favorites: [],
  include: [],
  isCategory: true,
  linkType: null,
  onFavoriteChange: null,
  onVisNavRender: () => {},
  parentNavParam: '',
  partialTemplates: [],
  preview: false,
  sku: [],
  storeId: '121',
  templatePath: null,
  templates: [],
  visualNavPromos: []
};

export default Etch;
