import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonSelector from '../../helpers/buttonClass';
import AlignmentSelector from '../../helpers/alignmentSelector';
import * as analytics from '../../analytics';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
const VideoPlayer = React.lazy(() => (
  import(/* webpackChunkName: "video-player" */ '@thd-olt-component-react/video-player')
).then((module) => ({ default: module.VideoPlayer })));

const buttonSelector = new ButtonSelector();
const alignmentSelector = new AlignmentSelector();

/**
 * @group Components
 * Content type parser component
 */

const Button = (props) => {
  const { opts = {} } = props;
  const [overlay, setOverlay] = useState(false);
  const { options, id } = opts;
  const buttonStyle = (['string', 'undefined'].indexOf(typeof options.align) !== -1)
    ? {
      top: `${options.positionY}px`,
      left: `${options.positionX}px`,
      position: 'relative'
    }
    : {};

  const buttonType = buttonSelector.get(options);
  const alignmentClass = alignmentSelector.get(options);
  const buttonClass = classNames(
    buttonType,
    alignmentClass,
    {
      triggerOverlay: options.openInModal,
      'tip-Opener': options.isToolTip,
    },
  );
  const onButtonClick = (event) => {
    if (options?.videoID) {
      event.preventDefault();
      setOverlay(true);
    }

    analytics.track('click', {
      eventType: '', widgetId: id, options, component: 'button'
    });
  };

  return (
    <div className="etch-widgets__button_container">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
      <a
        href={(options.URL && options.URL.indexOf('playVideo') === -1) ? options.URL : '#'}
        style={buttonStyle}
        className={buttonClass}
        target={(options.targetBlank) ? '_blank' : ''}
        rel={`${(options.externalLink) ? 'nofollow' : ''}${(options.targetBlank) ? ' noopener noreferrer' : ''}`}
        id={options.name}
        data-overlay-type={options.relationship}
        data-tooltop={(options.isToolTip) ? options.URL : ''}
        onClick={onButtonClick}
      >
        <span className={(typeof options.buttonType !== 'undefined') ? 'bttn__content' : ''}>{options.text}</span>
      </a>
      {overlay && (
        <Suspense fallback={<div />}>
          <Overlay
            onClose={() => setOverlay(false)}
            open
            fit
            closeButton
            modalClass="etch-widgets__video-overlay__modal"
          >
            <div className="etch-widgets__video-overlay" data-testid="button-video-overlay">
              <VideoPlayer
                videoId={options?.videoID}
                borderColor="black"
                autoPlay
                className="etch-widgets__video-overlay__player"
              />
            </div>
          </Overlay>
        </Suspense>
      )}
    </div>
  );
};

Button.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    id: PropTypes.string
  }).isRequired
};

export default Button;
