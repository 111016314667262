import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @group Components
 * Video
 */

const Video = (props) => {
  const { opts = {} } = props;
  const { options } = opts;
  const autoPlayString = (options.autoPlay) ? '&autoplay=1' : '';
  const videoLoopString = (options.videoLoop) ? `&loop=1&playlist=${options.ID}` : '';
  const videoControlString = (options.videoControl) ? '&controls=0' : '';
  const videoSoundString = (options.videoSound) ? '&enablejsapi=1' : '';
  return (
    <iframe
      width={options.width}
      className={classNames({ muteYTVideo: options.videoSound })}
      height={options.height}
      src={`${options.videoSrc}${autoPlayString}${videoLoopString}${videoControlString}${videoSoundString}`}
      allowFullScreen=""
      frameBorder="0"
      id={options.name}
      title="video"
    />
  );
};

Video.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
  }).isRequired
};

export default Video;
