import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import styles from '../../styles/exchange-pricing.module.scss';

const cx = classNames.bind(styles);

export const ExchangePricingBadge = (props) => {

  const { large } = props;

  const exchangePricingWrapperClasses = cx('exchange-pricing__wrapper', {
    'exchange-pricing__wrapper--side-padding': large
  });

  return (
    <div className={exchangePricingWrapperClasses}>
      <Image
        className={cx('exchange-pricing__badge')}
        asset="ExchangePricing-badge.svg"
        alt="Exchange Pricing Badge"
        title="Exchange Pricing" 
      />
    </div>
  );
};

ExchangePricingBadge.propTypes = {
  large: PropTypes.bool
};

ExchangePricingBadge.defaultProps = {
  large: false
};