import React from 'react';
import PropTypes from 'prop-types';

import './Item.scss';

const Item = (props) => {
  const { opts = {} } = props;
  const { text } = opts;
  if (text) {
    return (
      /* eslint-disable-next-line react/no-danger */
      <ul className="item"><li dangerouslySetInnerHTML={{ __html: opts.text }} /></ul>
    );
  }
  return '';
};

Item.propTypes = {
  opts: PropTypes.shape({
    text: PropTypes.string
  }).isRequired,
};

export default Item;
