import React from 'react';
import PropTypes from 'prop-types';

/**
 * @group Personalization
 * Placeholder module for recommendation injection
 */

const PersonalizedCategory = (props) => {
  const { opts = {} } = props;
  return (
    <div id={opts.options.schemaTypes} className="pcr certona-content col__12-12" />
  );
};

PersonalizedCategory.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({})
  }).isRequired,
};

export default PersonalizedCategory;
