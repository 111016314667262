/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { oneOf, bool, string } from 'prop-types';
import classNames from 'classnames/bind';

import { extend } from '@thd-nucleus/data-sources';
import { debounce } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';

import { SpecialBuyHeader } from './SpecialBuyHeader/SpecialBuyHeader';
import { useSearchProducts } from '../hooks/useSearchProducts';
import { SpecialBuyCarousel } from './SpecialBuyCarousel/SpecialBuyCarousel';
import { SpecialBuyCategories } from './SpecialBuyCategories/SpecialBuyCategories';
import { SpecialBuyNavButton } from './SpecialBuyNavButton/SpecialBuyNavButton';
import { SpecialBuyProvider } from '../context/SpecialBuyProvider';
import styles from './special-buy-banner.module.scss';

const cNames = classNames.bind(styles);

// How large is the component when layouts shift.
const condensedLayoutBreakpoint = 768;

const SpecialBuyBanner = ({ specialBuyType, smallResponsiveHeader, entryId, isForceCondensedLayout }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('special-buy-banner.ready'); }, []);
  const { channel } = useContext(ExperienceContext);
  const [isCondensedLayout, setIsCondensedLayout] = useState(isForceCondensedLayout || channel === 'mobile');
  const bannerRef = useRef(null);

  useEffect(() => {
    if (isForceCondensedLayout) {
      setIsCondensedLayout(true);
    } else if (bannerRef.current) {
      const calculateAndSetIsCondensedLayout = () => {
        const width = bannerRef.current.getBoundingClientRect()?.width;
        if (width && channel !== 'mobile') {
          setIsCondensedLayout(width < condensedLayoutBreakpoint);
        } else {
          setIsCondensedLayout(true);
        }
      };
      calculateAndSetIsCondensedLayout();
      const handleResize = debounce(50, calculateAndSetIsCondensedLayout);
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }
  }, [channel, isForceCondensedLayout, setIsCondensedLayout]);

  let bannerId = '';
  if (specialBuyType === 'sbotd') bannerId = 'sbotdBanner';
  if (specialBuyType === 'pro') bannerId = 'sbotwProBanner';

  const specialBuyBannerClassNames = cNames('special-buy-banner', {
    'special-buy-banner--condensed': isCondensedLayout,
  });

  return (
    // TODO: take isCondensedLayout out of the provider
    <SpecialBuyProvider specialBuyType={specialBuyType} isCondensedLayout={isCondensedLayout}>
      <article
        className={specialBuyBannerClassNames}
        id={bannerId}
        ref={bannerRef}
        data-component="SpecialBuyBanner"
      >
        <SpecialBuyHeader smallResponsiveHeader={smallResponsiveHeader} />
        <div className={cNames('special-buy-banner__content')}>
          <SpecialBuyCategories />
          {!isCondensedLayout
            ? <SpecialBuyCarousel entryId={entryId} />
            : <SpecialBuyNavButton isFullWidth />}
        </div>
      </article>
    </SpecialBuyProvider>
  );
};

SpecialBuyBanner.dataModel = extend({}, useSearchProducts, SpecialBuyProvider);

SpecialBuyBanner.displayName = 'SpecialBuyBanner';

SpecialBuyBanner.propTypes = {
  /**
   * Identifies the type of special buy for the banner. May be one of (sbotd', 'pro')
   */
  specialBuyType: oneOf(['sbotd', 'pro']),
  smallResponsiveHeader: bool,
  entryId: string,
  isForceCondensedLayout: bool,
};

SpecialBuyBanner.defaultProps = {
  specialBuyType: 'sbotd',
  smallResponsiveHeader: false,
  entryId: null,
  isForceCondensedLayout: false,
};

export { SpecialBuyBanner };
