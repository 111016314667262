import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './EtchSubHeroModule.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchSubHeroModule = ({ channel, opts = {} }) => {

  const {
    heading,
    ctaLink,
    subHeading,
    heroMedia = {},
    heroMediaMobile = {},
    cta
  } = opts;

  const isMobile = channel === 'mobile';

  const Picture = () => {

    const media = isMobile ? heroMediaMobile : heroMedia;
    const { src, alt, height, width } = media;
    const imgClass = classNames({
      'img--block': height && width
    });

    return (
      <picture className="subHero__media">
        <source media="(min-width: 0px)" srcSet={src} />
        <Image
          className={`stretchy subHero__main ${imgClass}`}
          src={src}
          alt={alt}
          height={height}
          width={width}
        />
      </picture>
    );
  };

  const SubHeroInfo = () => {
    return (
      <div className="subHero__info u__text-shadow--none">
        <h2 className="subHero__heading u__display-font--black u__jumbo">
          {heading}
        </h2>
        {
          subHeading
          && <h3 className="subHero__subHeading u__large">{subHeading}</h3>

        }
        {
          cta && (
            <button type="button" className="subHero__btn bttn bttn--primary bttn--inline">
              <span className="subHero__btn__content bttn__content">{cta}</span>
            </button>
          )
        }
      </div>
    );
  };

  return (
    <div className="subHero etch-analytics">
      {
        ctaLink && (
          <CTARenderer ctaLink={ctaLink} className="subHero__link">
            {Picture()}
          </CTARenderer>
        )
      }
      {!ctaLink && Picture()}
      {
        ctaLink
        && (
          <CTARenderer ctaLink={ctaLink}>
            {SubHeroInfo()}
          </CTARenderer>
        )
      }

    </div>
  );
};

EtchSubHeroModule.displayName = 'EtchSubHeroModule';

EtchSubHeroModule.propTypes = {
  opts: PropTypes.shape({
    heading: PropTypes.string,
    cta: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    heroMediaMobile: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
  }).isRequired,
  channel: PropTypes.string
};

EtchSubHeroModule.defaultProps = {
  channel: ''
};

export default EtchSubHeroModule;
