import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const constructImgUrl = ({
  host, type, size, id
}) => `${host}/catalog/${type}/${size}/${id.slice(0, 2)}/${id}_${size}.jpg`;

/**
 * THD Image component. */
const Image = ({
  alt,
  asset,
  className,
  height,
  host,
  id,
  lazy,
  size,
  src,
  stretchy,
  type,
  title,
  width,
  ...subProps
}) => {
  if (!(src || id || asset)) return null;
  const assetHost = 'https://assets.thdstatic.com';
  const imgSize = (type === 'swatchImages') ? '35' : size;
  const globalImgPath = `${assetHost}/images/v1/`;
  let imgPath = asset ? `${globalImgPath}${asset}` : src;

  imgPath = imgPath || constructImgUrl({
    host, type, size: imgSize, id
  });
  const imgClasses = classNames(className, { stretchy });

  const attrProps = {
    title,
    ...subProps
  };
  if (lazy) attrProps.loading = 'lazy';

  return (
    <img
      src={imgPath}
      alt={alt}
      className={imgClasses}
      width={width}
      height={height}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attrProps}
    />
  );
};

Image.propTypes = {
  /** Image Alternate Text. */
  alt: PropTypes.string.isRequired,
  /** name of static asset */
  asset: PropTypes.string,
  /** Extend Styles. */
  className: PropTypes.string,
  host: PropTypes.oneOf([
    'https://images.thdstatic.com',
    'https://storage.googleapis.com/hd-digitaldecor-dev-lifestyleimages'
  ]),
  /** Image Height */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Image GUID, (required if src prop not provided) */
  id: PropTypes.string,
  /** Image Lazy Load */
  lazy: PropTypes.bool,
  size: PropTypes.oneOf(['65', '100', '145', '300', '400', '400_compressed', '600', '800', '1000']),
  /** Image Source. (required if id prop not provided) */
  src: PropTypes.string,
  /** Add stretchy class. */
  stretchy: PropTypes.bool,
  /** Image Title Text. */
  title: PropTypes.string,
  /** Image Type */
  type: PropTypes.oneOf(['productImages', 'swatchImages']),
  /** Image Width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Image.defaultProps = {
  asset: null,
  className: null,
  height: null,
  host: 'https://images.thdstatic.com',
  id: null,
  lazy: true,
  size: '400',
  src: null,
  stretchy: false,
  title: null,
  type: 'productImages',
  width: null
};

export { Image };
