import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderLink = (props) => {
  const { options } = props;
  if (options.hasLink) {
    return (
      <a
        className="row-header__link"
        href={options.URL}
        target={(options.targetBlank) ? '_blank' : ''}
        rel="noreferrer"
      >
        {options.linkText}
      </a>
    );
  }
  return null;
};

/**
 * @group Titles
 * Header module for sections
 */

const RowHeader = ({ opts, noborder, underline, titleSize }) => {
  const rowTextClass = classNames('row-header__text',
    { u__legal: titleSize === 'legal' },
    { u__base: titleSize === 'base' },
    { u__medium: titleSize === 'medium' },
    { u__husky: titleSize === 'husky' },
    { u__large: titleSize === 'large' },
    { u__xlarge: titleSize === 'xlarge' },
    { u__jumbo: titleSize === 'jumbo' },
    { u__xtreme: titleSize === 'xtreme' },
    { 'row-header--no-border': !underline }
  );
  const rowHeaderClass = classNames('row-header',
    { 'row-header--no-border': noborder }
  );
  return (
    <h2 className={rowHeaderClass}>
      <div className="row-header__title">
        {/* eslint-disable-next-line react/no-danger */}
        <span className={rowTextClass} dangerouslySetInnerHTML={{ __html: opts.text }} />
        <HeaderLink options={opts.options} />
      </div>
    </h2>
  );
};

HeaderLink.propTypes = {
  options: PropTypes.shape({
    URL: PropTypes.string,
    targetBlank: PropTypes.bool,
    linkText: PropTypes.string,
    hasLink: PropTypes.bool
  }).isRequired
};

RowHeader.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    text: PropTypes.string,
  }).isRequired,
  noborder: PropTypes.bool,
  underline: PropTypes.bool,
  titleSize: PropTypes.oneOf(['legal', 'base', 'medium', 'husky', 'large', 'xlarge', 'jumbo', 'xtreme'])
};

RowHeader.defaultProps = {
  noborder: false,
  underline: false,
  titleSize: 'medium'
};

export default RowHeader;
