/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable id-length */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import * as analytics from '../../analytics';
import Item from '../Item/Item.component';
import {
  Accordion,
  BlockHeader,
  BrightCove,
  Button,
  CategoryNavigation,
  Certona,
  CTA,
  DPD,
  EtchEndCap,
  FeaturedCollection,
  Grid,
  GridColumn,
  Hero,
  HiddenContent,
  Image,
  LegacyColumn,
  LegacySupport,
  List,
  MyStoreModule,
  Paragraph,
  PartialContent,
  PersonalizedCategory,
  PLPSEO,
  RowHeader,
  SpecialBuyModuleEtch1,
  Slider,
  SocialIcon,
  SponsoredProducts,
  Standout,
  Steps,
  Table,
  TargetedMerchandise,
  Title,
  TitleBar,
  Video,
  VisualCategoryNavigation,
} from '../../Etch1';

function ComponentType(props) {
  const { isDebugMode } = useContext(ExperienceContext);
  if (!props || !props.opts) {
    return null;
  }

  if (props.opts.type === 'ruleDrivenPartialWidget'
    && (!props.partialTemplates
      || !props.partialTemplates.length > 0
      || !props.partialTemplates[0].PLP_Banner)
  ) {
    return null;
  }

  if (props.opts.type === 'visualNavigation'
    && (
      (!props.isCategory
        && !props.plpBanner)
    )
  ) {
    return null;
  }

  if ((props.opts.type === 'guidedNavigationSlot'
    || props.opts.type === 'resultsList'
  ) && !props.isCategory) {
    return null;
  }

  if (props.opts.type === 'banner'
    || props.opts.type === 'defaultVisualNav') {
    return null;
  }

  const renderAccordionContent = (accIndex = 0) => {
    const options = props?.opts?.Content?.[accIndex]?.Content || [];
    return options?.map?.((opts, index) => {
      if (opts) {
        return (
          <ContentComponent
            channel={props.channel}
            contentType={props.contentType}
            deliveryZip={props.deliveryZip}
            dimensions={props.dimensions}
            dpdResponse={props.dpdResponse}
            env={props.env}
            exclude={props.exclude}
            favorites={props.favorites}
            host={props.host}
            isCategory={props.isCategory}
            key={opts.id || index}
            keyword={props.keyword}
            linkType={props.linkType}
            navParam={props.navParam}
            onFavoriteChange={props.onFavoriteChange}
            onVisNavRender={props.onVisNavRender}
            opts={opts}
            parentNavParam={props.parentNavParam}
            partialTemplates={props.partialTemplates}
            plpBanner={props.plpBanner}
            showDpdPod={props.showDpdPod}
            sku={props.sku}
            storeId={props.storeId}
            visualNavPromos={props.visualNavPromos}
            visualNavResults={props.visualNavResults}
          />
        );
      }
      return null;
    });
  };

  const renderTargetedMerchandise = (c, index) => {
    return (
      <ContentComponent
        channel={props.channel}
        contentType={props.contentType}
        deliveryZip={props.deliveryZip}
        dimensions={props.dimensions}
        disableSwipeNavigation={props.disableSwipeNavigation}
        dpdResponse={props.dpdResponse}
        env={props.env}
        exclude={props.exclude}
        favorites={props.favorites}
        host={props.host}
        isCategory={props.isCategory}
        key={c.id || index}
        keyword={props.keyword}
        linkType={props.linkType}
        navParam={props.navParam}
        onFavoriteChange={props.onFavoriteChange}
        onVisNavRender={props.onVisNavRender}
        opts={c}
        parentNavParam={props.parentNavParam}
        partialTemplates={props.partialTemplates}
        plpBanner={props.plpBanner}
        showArrows={props.showArrows}
        showFavoritesCount={props.showFavoritesCount}
        showDpdPod={props.showDpdPod}
        sku={props.sku}
        storeId={props.storeId}
        visualNavPromos={props.visualNavPromos}
        visualNavResults={props.visualNavResults}
      />
    );
  };

  /* In cases where the Carousel needs to dynamically set multiItem to false,
  We will check for the default value size '12-12' in options */
  const multiCheck = props?.opts?.Content?.[0]?.options?.size?.defaultValue || '';
  const multiFlag = multiCheck !== '12-12';
  let visNavData;

  switch (props.opts.type) {
  case 'button':
    return (<Button opts={props.opts} />);
  case 'accordion':
    return (
      <Accordion opts={props.opts} accordionBody={(index) => renderAccordionContent(index, props)}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </Accordion>
    );
  case 'paragraph':
    return (<Paragraph opts={props.opts} />);
  case 'personalizedCategory':
    return (<PersonalizedCategory opts={props.opts} />);
  case 'certona':
    return (<Certona opts={props.opts} />);
  case 'EtchSBOTD':
  case 'EtchDecorSBOTW':
  case 'EtchProSBOTW':
    return (<SpecialBuyModuleEtch1 opts={props.opts} />);
  case 'rowHeaderStandard':
    return (<RowHeader opts={props.opts} underline />);
  case 'rowHeader':
    return (<RowHeader opts={props.opts} titleSize="husky" noborder />);
  case 'myStoreModule':
    return (<MyStoreModule opts={props.opts} />);
  case 'cta':
    return (<CTA opts={props.opts} />);
  case 'list':
    return (<List opts={props.opts} />);
  case 'blockHeader':
    return (<BlockHeader opts={props.opts} />);
  case 'brightcove':
    return (<BrightCove opts={props.opts} />);
    // curation is currently not supported
  case 'curation':
    return null;
  case 'categoryNavigation':
    return (<CategoryNavigation opts={props.opts} />);
  case 'title':
    return (<Title opts={props.opts} />);
  case 'partialWidget':
    return (
      <PartialContent opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </PartialContent>
    );
  case 'ruleDrivenPartialWidget':
    return (
      <PartialContent opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.partialTemplates?.[0].PLP_Banner?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </PartialContent>
    );
  case 'row':
    return (
      <Grid
        opts={props.opts}
        channel={props.channel}
      >{props?.opts?.Content?.map?.((c, i) => {
        return (
          <ContentComponent
            channel={props.channel}
            contentType={props.contentType}
            deliveryZip={props.deliveryZip}
            dimensions={props.dimensions}
            disableSwipeNavigation={props.disableSwipeNavigation}
            dpdResponse={props.dpdResponse}
            env={props.env}
            exclude={props.exclude}
            favorites={props.favorites}
            host={props.host}
            isCategory={props.isCategory}
            key={c.id || i}
            keyword={props.keyword}
            linkType={props.linkType}
            navParam={props.navParam}
            onFavoriteChange={props.onFavoriteChange}
            onVisNavRender={props.onVisNavRender}
            opts={c}
            parentNavParam={props.parentNavParam}
            partialTemplates={props.partialTemplates}
            plpBanner={props.plpBanner}
            showArrows={props.showArrows}
            showFavoritesCount={props.showFavoritesCount}
            showDpdPod={props.showDpdPod}
            sku={props.sku}
            storeId={props.storeId}
            visualNavPromos={props.visualNavPromos}
            visualNavResults={props.visualNavResults}
          />
        );
      })}
      </Grid>
    );
  case 'grid':
    return (
      <Grid
        opts={props.opts}
        channel={props.channel}
      >{props?.opts?.Content?.map?.((c, i) => {
        return (
          <ContentComponent
            channel={props.channel}
            contentType={props.contentType}
            deliveryZip={props.deliveryZip}
            dimensions={props.dimensions}
            disableSwipeNavigation={props.disableSwipeNavigation}
            dpdResponse={props.dpdResponse}
            env={props.env}
            exclude={props.exclude}
            favorites={props.favorites}
            host={props.host}
            isCategory={props.isCategory}
            key={c.id || i}
            keyword={props.keyword}
            linkType={props.linkType}
            navParam={props.navParam}
            onFavoriteChange={props.onFavoriteChange}
            onVisNavRender={props.onVisNavRender}
            opts={c}
            parentNavParam={props.parentNavParam}
            partialTemplates={props.partialTemplates}
            plpBanner={props.plpBanner}
            showArrows={props.showArrows}
            showFavoritesCount={props.showFavoritesCount}
            showDpdPod={props.showDpdPod}
            sku={props.sku}
            storeId={props.storeId}
            visualNavPromos={props.visualNavPromos}
            visualNavResults={props.visualNavResults}
          />
        );
      })}
      </Grid>
    );
  case 'hero':
    return (
      <Hero opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </Hero>
    );
  case 'adaptiveHero':
    return (
      <Hero opts={props.opts}>
        {props.opts.Content
            && props.opts.Content.length > 0
            && analytics.track('load', {
              eventType: 'load',
              widgetId: props.opts.id
            })}
        {props.opts.Content
            && props.opts.Content.length > 0
            && props.opts.Content.map((c, i) => {
              return (
                <ContentComponent
                  channel={props.channel}
                  contentType={props.contentType}
                  deliveryZip={props.deliveryZip}
                  dimensions={props.dimensions}
                  disableSwipeNavigation={props.disableSwipeNavigation}
                  dpdResponse={props.dpdResponse}
                  env={props.env}
                  exclude={props.exclude}
                  favorites={props.favorites}
                  host={props.host}
                  isCategory={props.isCategory}
                  key={c.id || i}
                  keyword={props.keyword}
                  linkType={props.linkType}
                  navParam={props.navParam}
                  onFavoriteChange={props.onFavoriteChange}
                  onVisNavRender={props.onVisNavRender}
                  opts={c}
                  parentNavParam={props.parentNavParam}
                  partialTemplates={props.partialTemplates}
                  plpBanner={props.plpBanner}
                  showArrows={props.showArrows}
                  showFavoritesCount={props.showFavoritesCount}
                  showDpdPod={props.showDpdPod}
                  sku={props.sku}
                  storeId={props.storeId}
                  visualNavPromos={props.visualNavPromos}
                  visualNavResults={props.visualNavResults}
                />
              );
            })}
      </Hero>
    );
  case 'hiddenContent':
    return (
      <HiddenContent opts={props.opts}>
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </HiddenContent>
    );
  case 'column':
    return (
      <LegacyColumn opts={props.opts}>
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </LegacyColumn>
    );
  case 'sectionV1':
    return (
      <Grid
        opts={props.opts}
        channel={props.channel}
      >{props?.opts?.Content?.map?.((c, i) => {
        return (
          <ContentComponent
            channel={props.channel}
            contentType={props.contentType}
            deliveryZip={props.deliveryZip}
            dimensions={props.dimensions}
            disableSwipeNavigation={props.disableSwipeNavigation}
            dpdResponse={props.dpdResponse}
            env={props.env}
            exclude={props.exclude}
            favorites={props.favorites}
            host={props.host}
            isCategory={props.isCategory}
            key={c.id || i}
            keyword={props.keyword}
            linkType={props.linkType}
            navParam={props.navParam}
            onFavoriteChange={props.onFavoriteChange}
            onVisNavRender={props.onVisNavRender}
            opts={c}
            parentNavParam={props.parentNavParam}
            partialTemplates={props.partialTemplates}
            plpBanner={props.plpBanner}
            showArrows={props.showArrows}
            showFavoritesCount={props.showFavoritesCount}
            showDpdPod={props.showDpdPod}
            sku={props.sku}
            storeId={props.storeId}
            bannerData={props.bannerData}
            visualNavPromos={props.visualNavPromos}
            visualNavResults={props.visualNavResults}
          />
        );
      })}
      </Grid>
    );
  case 'standout':
    return (
      <Standout opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </Standout>
    );
  case 'steps':
    return (
      <Steps {...props}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </Steps>
    );
  case 'gridColumn':
    return (
      <GridColumn
        opts={props.opts}
        channel={props.channel}
        isCategory={props.isCategory}
        bannerData={props.bannerData}
      >{props?.opts?.Content?.map?.((c, i) => {
        return (
          <ContentComponent
            channel={props.channel}
            contentType={props.contentType}
            deliveryZip={props.deliveryZip}
            dimensions={props.dimensions}
            disableSwipeNavigation={props.disableSwipeNavigation}
            dpdResponse={props.dpdResponse}
            env={props.env}
            exclude={props.exclude}
            favorites={props.favorites}
            host={props.host}
            isCategory={props.isCategory}
            key={c.id || i}
            keyword={props.keyword}
            linkType={props.linkType}
            navParam={props.navParam}
            onFavoriteChange={props.onFavoriteChange}
            onVisNavRender={props.onVisNavRender}
            opts={c}
            parentNavParam={props.parentNavParam}
            partialTemplates={props.partialTemplates}
            plpBanner={props.plpBanner}
            showArrows={props.showArrows}
            showFavoritesCount={props.showFavoritesCount}
            showDpdPod={props.showDpdPod}
            sku={props.sku}
            storeId={props.storeId}
            bannerData={props.bannerData}
            visualNavPromos={props.visualNavPromos}
            visualNavResults={props.visualNavResults}
          />
        );
      })}
      </GridColumn>
    );
  case 'targetedMerchandise':
    return (
      <TargetedMerchandise opts={props.opts}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          if (!c?.type && c?.Content?.length) {
            return c?.Content?.map?.((_c, index) => {
              return renderTargetedMerchandise(_c, index);
            });
          }

          return renderTargetedMerchandise(c, i);

        })}
      </TargetedMerchandise>
    );
  case 'slider':
    return (
      <Slider opts={props.opts} multiItem={false}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </Slider>
    );
  case 'sliderV2':
    return (
      <Slider opts={props.opts} multiItem={multiFlag} dots>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </Slider>
    );
  case 'plpSeo':
    return (
      <PLPSEO opts={props.opts} navParam={props.navParam}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
        {props?.opts?.Content?.map?.((c, i) => {
          return (
            <ContentComponent
              channel={props.channel}
              contentType={props.contentType}
              deliveryZip={props.deliveryZip}
              dimensions={props.dimensions}
              disableSwipeNavigation={props.disableSwipeNavigation}
              dpdResponse={props.dpdResponse}
              env={props.env}
              exclude={props.exclude}
              favorites={props.favorites}
              host={props.host}
              isCategory={props.isCategory}
              key={c.id || i}
              keyword={props.keyword}
              linkType={props.linkType}
              navParam={props.navParam}
              onFavoriteChange={props.onFavoriteChange}
              onVisNavRender={props.onVisNavRender}
              opts={c}
              parentNavParam={props.parentNavParam}
              partialTemplates={props.partialTemplates}
              plpBanner={props.plpBanner}
              showArrows={props.showArrows}
              showFavoritesCount={props.showFavoritesCount}
              showDpdPod={props.showDpdPod}
              sku={props.sku}
              storeId={props.storeId}
              visualNavPromos={props.visualNavPromos}
              visualNavResults={props.visualNavResults}
            />
          );
        })}
      </PLPSEO>
    );
  case 'legacySupport':
    return (<LegacySupport opts={props.opts} />);
  case 'image':
    return (<Image channel={props.channel} opts={props.opts} />);
  case 'table':
    return (<Table opts={props.opts} />);
  case 'titlebar':
    return (<TitleBar opts={props.opts} />);
  case 'sponsoredProducts':
    return (<SponsoredProducts opts={props.opts} />);
  case 'featuredCollection':
    return (<FeaturedCollection opts={props.opts} />);
  case 'video':
    return (<Video opts={props.opts} />);
  case 'socialicons':
    return (<SocialIcon opts={props.opts} />);
  case 'dpd':
    return (
      <DPD
        channel={props.channel}
        deliveryZip={props.deliveryZip}
        disableSwipeNavigation={props.disableSwipeNavigation}
        dpdResponse={props.dpdResponse}
        env={props.env}
        favorites={props.favorites}
        host={props.host}
        keyword={props.keyword}
        linkType={props.linkType}
        navParam={props.navParam}
        onFavoriteChange={props.onFavoriteChange}
        opts={props.opts}
        parentNavParam={props.parentNavParam}
        removeOOSSkus
        showArrows={props.showArrows}
        showFavoritesCount={props.showFavoritesCount}
        showDpdPod={props.showDpdPod}
        storeId={props.storeId}
      >
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </DPD>
    );
  case 'visualNavigation':
    props.onVisNavRender();
    if (props.visualNavResults && props.dimensions) {
      visNavData = {
        searchModel: {
          dimensions: props.dimensions,
          relatedResults: props.visualNavResults
        }
      };
    }
    return (
      <VisualCategoryNavigation
        data={visNavData}
        keyword={props.keyword}
        navParam={props.navParam}
        slider
        env={props.env}
        channel={props.channel}
        showArrows={false}
        disableSwipeNavigation={props.channel === 'mobile'}
      />
    );
  case 'item':
    return (<Item opts={props.opts} />);
  case 'EndCap':
  case 'topEventEndCap':
    return (
      <EtchEndCap opts={props.opts} channel={props.channel} navParam={props.navParam}>
        {analytics.track('load', {
          eventType: 'load',
          widgetId: props.opts.id
        })}
      </EtchEndCap>
    );
  default:
    if (isDebugMode) return (<div>component type not found: {props.opts.type}</div>);
    return null;
  }
}

ComponentType.propTypes = {
  bannerData: PropTypes.shape({}),
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  contentType: PropTypes.string,
  deliveryZip: PropTypes.string,
  dimensions: PropTypes.instanceOf(Array),
  dpdResponse: PropTypes.shape({}),
  env: PropTypes.oneOf([
    'local',
    'dev',
    'stage',
    'prod-beta',
    'prod'
  ]),
  /** Exclude content of these types */
  exclude: PropTypes.arrayOf(PropTypes.string),
  favorites: PropTypes.arrayOf(PropTypes.number),
  host: PropTypes.string,
  isCategory: PropTypes.bool,
  onFavoriteChange: PropTypes.func,
  onVisNavRender: PropTypes.func,
  opts: PropTypes.shape({
    Content: PropTypes.instanceOf(Array),
    id: PropTypes.string,
    options: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object,
        PropTypes.instanceOf(Array), PropTypes.number])
    ),
    type: PropTypes.string
  }).isRequired,
  parentNavParam: PropTypes.string,
  partialTemplates: PropTypes.instanceOf(Array),
  plpBanner: PropTypes.bool,
  sku: PropTypes.instanceOf(Array),
  storeId: PropTypes.string,
  visualNavResults: PropTypes.shape({
    visualNavs: PropTypes.instanceOf(Array),
    visualNavContainsEvents: PropTypes.bool
  }),
  visualNavPromos: PropTypes.instanceOf(Array),
  keyword: PropTypes.string,
  navParam: PropTypes.string,
  disableSwipeNavigation: PropTypes.bool,
  showArrows: PropTypes.bool,
  linkType: PropTypes.string,
  showFavoritesCount: PropTypes.bool,
  showDpdPod: PropTypes.bool
};

ComponentType.defaultProps = {
  bannerData: {},
  channel: 'desktop',
  contentType: 'categorypage',
  deliveryZip: null,
  dimensions: [],
  dpdResponse: null,
  env: 'prod',
  exclude: [],
  favorites: [],
  host: 'https://www.homedepot.com',
  isCategory: true,
  onFavoriteChange: null,
  onVisNavRender: () => {
  },
  parentNavParam: '',
  partialTemplates: [],
  plpBanner: false,
  sku: [],
  storeId: '121',
  visualNavResults: {},
  visualNavPromos: [],
  keyword: '',
  navParam: '',
  disableSwipeNavigation: false,
  showArrows: true,
  linkType: '_self',
  showFavoritesCount: false,
  showDpdPod: false
};

/**
 * @group Components
 * Content type parser component
 */
const ContentComponent = ({
  exclude,
  include,
  linkType,
  opts,
  ...rest
}) => {
  if (exclude && exclude.find((type) => type === opts.type)) {
    return null;
  }
  if (include.length > 0) {
    if (include.find((type) => type === opts.type)) {
      return <ComponentType opts={opts} linkType={linkType} {...rest} />;
    } if (Array.isArray(opts.Content) && opts.Content.length > 0) {
      return opts?.Content?.map
        ? opts.Content.map((c, i) => (
          <ContentComponent
            include={include}
            linkType={linkType}
            key={c.id || i}
            opts={c}
            {...rest}
          />
        ))
        : null;
    }
    return null;
  }
  return <ComponentType exclude={exclude} opts={opts} {...rest} linkType={linkType} />;
};

ContentComponent.propTypes = {
  bannerData: PropTypes.shape({}),
  deliveryZip: PropTypes.string,
  /** Exclude content of these types */
  exclude: PropTypes.arrayOf(PropTypes.string),
  showArrows: PropTypes.bool,
  disableSwipeNavigation: PropTypes.bool,
  /** Include only content of these types */
  include: PropTypes.arrayOf(PropTypes.string),
  linkType: PropTypes.string,
  opts: PropTypes.shape({
    Content: PropTypes.instanceOf(Array),
    id: PropTypes.string,
    options: PropTypes.shape({}),
    type: PropTypes.string
  }).isRequired,
  showFavoritesCount: PropTypes.bool,
  showDpdPod: PropTypes.bool
};

ContentComponent.defaultProps = {
  bannerData: {},
  deliveryZip: null,
  exclude: [],
  include: [],
  linkType: '_self',
  showArrows: true,
  disableSwipeNavigation: false,
  showFavoritesCount: false,
  showDpdPod: false
};

ComponentType.displayName = 'ComponentType';

ContentComponent.displayName = 'ContentComponent';

export default ContentComponent;
