import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames/bind';

import { Button } from '@thd-olt-component-react/core-ui';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import styles from './special-buy-nav-button.module.scss';

const cNames = classNames.bind(styles);

const SpecialBuyNavButton = ({ isFullWidth }) => {
  const { specialBuyType, isFallbackBanner, specialBuyEndpoint } = useSpecialBuy();

  return (
    <Button
      tag="a"
      href={specialBuyEndpoint}
      className={cNames('button', {
        'button--full-width': isFullWidth,
        'button--with-margin': !isFullWidth,
      })}
    >
      {isFallbackBanner && <>Shop&nbsp;All&nbsp;Savings</>}
      {!isFallbackBanner && (
        <>
          {(specialBuyType === 'sbotd') && <>Shop&nbsp;Today&apos;s&nbsp;Specials</>}
          {(specialBuyType === 'pro') && <>Shop&nbsp;Pro&nbsp;Specials</>}
        </>
      )}
    </Button>
  );
};

SpecialBuyNavButton.displayName = 'SpecialBuyNavButton';

SpecialBuyNavButton.propTypes = {
  isFullWidth: bool,
};

SpecialBuyNavButton.defaultProps = {
  isFullWidth: false,
};

export { SpecialBuyNavButton };
