const EVENT_LIST = [
  {
    label: 'Cyber Savings'
  },
  {
    label: 'Pre Black Friday'
  },
  {
    label: 'Black Friday'
  }
];
export default class DPDHelpers {

    static getDimensionsByName = ({ crumbs, name }) => {
      return crumbs.filter((crumb) => {
        return crumb.dimensionName
          ? crumb.dimensionName.toLowerCase().match((name || '').toLowerCase())
          : false;
      });
    };

    static getDisplayLabel = ({ sortByName, sortOrder = '' }) => {
      let displayLabel = '';
      if (sortByName) {
        if (sortByName === 'topsellers') {
          displayLabel = 'Top Sellers';
        }
        if (sortByName === 'toprated') {
          displayLabel = 'Top Rated';
        }
        if (sortByName === 'newitems') {
          displayLabel = 'New Arrivals';
        }
        if (sortByName === 'bestmatch') {
          displayLabel = 'Most Popular';
        }
        if (sortByName === 'price') {
          if (sortOrder.toUpperCase() === 'ASC') {
            displayLabel = 'price low to high';
          } else if (sortOrder.toUpperCase() === 'DESC') {
            displayLabel = 'price high to low';
          }
        }
      }
      return displayLabel;
    }

    static getEventLabel = (label) => {
      const eventRegex = new RegExp('Savings+$');
      return eventRegex.test(label) ? label : `${label} Savings`;
    }

    static isEventListExist = (filterLabels) => {
      return !!EVENT_LIST.find((event) => {
        return this.getEventLabel(event.label) === filterLabels;
      });
    };

    static getSingleDimensionValue = (filterLabels = '', firstRefinement) => {
      if (filterLabels === 'Savings'
        || filterLabels === 'Labor Day Savings'
        || this.isEventListExist(filterLabels)
      ) {
        return filterLabels;
      }
      if (firstRefinement && firstRefinement.length > 0) {
        const { label, dimensionName } = firstRefinement[0];
        if (dimensionName
          && dimensionName.toUpperCase() === 'REVIEW RATING'
          && label) {
          let reviewRatingLabel = label;
          reviewRatingLabel = '';
          return reviewRatingLabel;
        }
        if (label) {
          return label;
        }
      }
      return '';
    }

    static setRefinementFilterLabel = (crumbs = [], sortBy) => {
      let filterLabels = '';
      // eslint-disable-next-line max-len
      const firstRefinement = crumbs.filter((crumb) => (crumb.dimensionName !== 'Category') && crumb.refinementKey !== (4295117280).toString(36));

      const specialValues = DPDHelpers.getDimensionsByName({ crumbs, name: 'SPECIAL VALUES' });
      const specialValuesDimension = specialValues.length === 1 ? specialValues[0] : {};
      const specialValuesLabel = specialValuesDimension.label || '';
      if (specialValuesLabel.toUpperCase() === 'SPECIAL VALUES'
        || specialValuesLabel.toUpperCase() === 'SPECIAL BUYS') {
        filterLabels += 'Savings';
      }

      const savingsCenter = DPDHelpers.getDimensionsByName({ crumbs, name: 'SAVINGS CENTER' });
      const savingsCenterDimension = savingsCenter.length === 1 ? savingsCenter[0] : {};
      const savingsCenterLabel = savingsCenterDimension.label || '';
      if (savingsCenterLabel.toUpperCase() === 'LABOR DAY') {
        filterLabels = 'Labor Day Savings';
      } else if (savingsCenterLabel.toUpperCase() === 'SPECIAL VALUES'
        || savingsCenterLabel.toUpperCase() === 'SPECIAL BUYS') {
        filterLabels = 'Savings';
      }

      const holidayEvent = DPDHelpers.getDimensionsByName({ crumbs, name: 'EVENT LIST' });
      const holidayEventDimension = holidayEvent.length === 1 ? holidayEvent[0] : {};

      const selectedEvent = EVENT_LIST.find((event) => event.label === holidayEventDimension.label);
      if (selectedEvent) {
        const { label } = selectedEvent;
        filterLabels = this.getEventLabel(label);
      }

      if (firstRefinement && firstRefinement.length > 0) {
        // If single dimension
        if (firstRefinement && firstRefinement.length === 1) {
          return DPDHelpers.getSingleDimensionValue(filterLabels, firstRefinement);
        }

        // SEO logic if moe than one dimesnion and do not change the order

        // GET THE REVIEW RATING [feature currently on hold, we may use it later]

        // const reviewRatings = this.getDimensionsByName({ crumbs, name: 'REVIEW RATING' });
        // if (reviewRatings.length === 1
        //       && reviewRatings[0].label === '5' && sortBy !== 'toprated') {
        //   filterLabels += 'Best Rated ';
        //   return filterLabels;
        // }

        // PRICE
        const price = DPDHelpers.getDimensionsByName({ crumbs, name: 'PRICE' });
        if (price && price.length > 0) {
          filterLabels += DPDHelpers.getPriceRange(price);
        }

        // GET THE SIZE NAMES
        const sizeNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'SIZE' });
        if (sizeNames && sizeNames.length > 0
              && sizeNames.indexOf(sizeNames[0].label) === -1) {
          filterLabels += sizeNames[0].label + ' ';
        }

        // GET THE MEASUREMENT NAMES
        const measurements = DPDHelpers.getDimensionsByName({ crumbs, name: 'MEASUREMENTS' });
        if (measurements && measurements.length > 0
              && measurements.indexOf(measurements[0].label) === -1) {
          filterLabels += measurements[0].label + ' ';
        }

        // GET THE SHAPE NAMES
        const shapeNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'SHAPE' });
        if (shapeNames && shapeNames.length > 0
              && shapeNames.indexOf(shapeNames[0].label) === -1) {
          filterLabels += shapeNames[0].label + ' ';
        }

        // GET THE COLOR NAMES
        const colorNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'COLOR' });
        if (colorNames && colorNames.length > 0
              && colorNames.indexOf(colorNames[0].label) === -1) {
          filterLabels += colorNames[0].label + ' ';
        }

        // GET THE PATTERN NAMES
        const patterns = DPDHelpers.getDimensionsByName({ crumbs, name: 'PATTERN' });
        if (patterns && patterns.length > 0
              && patterns.indexOf(patterns[0].label) === -1) {
          filterLabels += patterns[0].label + ' ';
        }

        // GET THE STYLE NAMES
        const styleNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'STYLE' });
        if (styleNames && styleNames.length > 0
              && styleNames.indexOf(styleNames[0].label) === -1) {
          filterLabels += styleNames[0].label + ' ';
        }

        // GET THE MATERIAL LABEL
        const materialNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'MATERIAL' });
        if (materialNames && materialNames.length > 0
              && materialNames.indexOf(materialNames[0].label)) {
          filterLabels += materialNames[0].label + ' ';
        }

        // GET THE BRAND NAMES
        const brandNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'BRAND' });
        if (brandNames.length) {
          if (brandNames.length === 2) {
            filterLabels += brandNames.map((brand) => brand.label).join(' and ');
          } else if (brandNames.length > 2) {
            const labels = brandNames.map((brand) => brand.label);
            if (labels !== undefined && labels.length > 2) {
              filterLabels += labels.slice(0, -1).join(', ') + ' and ' + labels.slice(-1);
            }
          } else if (brandNames.length === 1) {
            filterLabels += brandNames.map((brand) => brand.label).join(' ');
          }
        }

        // GET INSTALLATION DIMENSIONS
        const installationNames = DPDHelpers.getDimensionsByName({ crumbs, name: 'INSTALLATION' });
        if (installationNames.length > 0) {
          filterLabels += ' - ' + installationNames[0].label;
        }

        // LET US DO IT FOR YOU
        const letUsDoItForYouLabels = DPDHelpers.getDimensionsByName({ crumbs, name: 'LET US DO IT FOR YOU' });
        if (letUsDoItForYouLabels.length > 0) {
          filterLabels += ' - ' + letUsDoItForYouLabels[0].label;
        }

        // GET THE SAVING BY PERCENTAGE OFF
        const savingPercentOff = DPDHelpers.getDimensionsByName({ crumbs, name: 'SAVING BY PERCENTAGE OFF' });
        if (savingPercentOff.length > 0 && (!specialValues && !savingsCenter)) {
          filterLabels += savingPercentOff[0].label + ' ';
        }
      }
      return filterLabels;
    };

    static getPriceRange = (price) => {
      let priceValue = '';
      if (price.length > 1) {
        const priceVal = price.map((brand) => brand.label).join(' ');
        const minMaxPrice = priceVal.split(' ');
        const filterPrice = minMaxPrice
          .map((val) => val.replace('$', ''));
        let priceIntArray = filterPrice
          .filter((validPrice) => !Number.isNaN(parseInt(validPrice, 10)))
          .map((priceArray) => parseInt(priceArray, 10));
        if (priceIntArray && priceIntArray.length > 0) {
          priceIntArray.sort((min, max) => { return min - max; });
          if (priceIntArray[0]) {
            const minPrice = priceIntArray[0];
            const maxPrice = priceIntArray[priceIntArray.length - 1];
            const priceRange = `${'$'}${minPrice} - ${'$'}${maxPrice}`;
            priceValue = `${priceRange} `;
          }
        }
      } else {
        priceValue = `${price[0].label} `;
      }
      return priceValue;
    }

    static getEventFilter = (filterLabel) => {
      const selectedEvent = EVENT_LIST.find((event) => {
        const { label } = event;
        const eventLabel = this.getEventLabel(label);
        const eventListRegex = new RegExp(`${eventLabel}[A-Za-z0-9]*`);
        return eventListRegex.test(filterLabel);
      });
      return selectedEvent;
    };

    static getCategoryLabel = ({ filterLabel, leafNodeLabel }) => {
      let categoryLabel = leafNodeLabel || '';
      const savingRegex = new RegExp('Savings[A-Za-z0-9]*');
      const laborRegex = new RegExp('Labor Day Savings[A-Za-z0-9]*');
      if (filterLabel) {
        const eventFilter = this.getEventFilter(filterLabel);
        if (filterLabel === 'Savings' || filterLabel === 'Labor Day Savings') {
          categoryLabel = `${leafNodeLabel} ${filterLabel}`;
        } else if (laborRegex.test(filterLabel)) {
          const updatedFilter = filterLabel.replace('Labor Day Savings', '');
          categoryLabel = `${updatedFilter} ${leafNodeLabel} Labor Day Savings`;
        } else if (eventFilter) {
          const eventLabel = this.getEventLabel(eventFilter.label);
          const updatedFilter = filterLabel.replace(eventLabel, '');
          categoryLabel = `${updatedFilter} ${leafNodeLabel} ${eventLabel}`;
        } else if (savingRegex.test(filterLabel)) {
          const updatedFilter = filterLabel.replace('Savings', '');
          categoryLabel = `${updatedFilter} ${leafNodeLabel} Savings`;
        } else if (filterLabel && leafNodeLabel) {
          categoryLabel = `${filterLabel} ${leafNodeLabel}`;
        }
        return categoryLabel;
      }
      return categoryLabel;
    }

    static setCookie = (name, value, days) => {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
      }
      const domain = document.location.hostname;
      const host = domain.substring(domain.indexOf('.') + 1);
      const encodedValue = encodeURIComponent(value);
      if (typeof document !== 'undefined' && encodedValue && host) {
        document.cookie = `${name}=${encodedValue}${expires};domain=${host};path=/`;
      }
    };
}
