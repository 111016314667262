import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BackGroundColor from '../../helpers/backgroundColors';
import BackGroundStyles from '../../helpers/backgroundStyles';
import './Grid.scss';

const colorPicker = new BackGroundColor();
const styleHelper = new BackGroundStyles();

/**
 * @group Layout
 * Displays grid component
 */

const Grid = (props) => {
  const { opts = {}, children, channel } = props;
  const { options, isRow1 } = opts;

  if (channel === 'mobile' && options.hideMobile) return null;
  let background = options.background || options.backgroundColor;
  if (options.backgroundImage) {
    background = { image: options.backgroundImage };
  }
  let bgColor = (background?.bgPicker) ? colorPicker.get(background.bgPicker) : `${options.colorClass}`;
  if (bgColor === 'undefined') {
    bgColor = '';
  }
  const gridClass = classNames(
    'grid row',
    {
      'legacy-content u--fix-960': isRow1,
      isBound: options.isBound,
      withBorder: options.withBorder,
      transparentBorder: !options.transparentBorder,
    },
    bgColor,
  );
  const styles = styleHelper.get(background, options);

  return (
    <div
      className={gridClass}
      style={styles}
      id={options.name ? options.name : opts.id}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  opts: PropTypes.shape({
    isRow1: PropTypes.bool,
    content: PropTypes.instanceOf(Array),
    options: PropTypes.shape({}),
    id: PropTypes.string,
  }).isRequired,
  channel: PropTypes.string,
  children: PropTypes.node
};

Grid.defaultProps = {
  children: [],
  channel: 'desktop'
};

export default Grid;
