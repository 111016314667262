import React from 'react';
import PropTypes from 'prop-types';
import './EtchAnchorLinks.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

const EtchAnchorLinks = (props) => {

  const { opts = {} } = props;
  const {
    links
  } = opts;

  return (
    <div className="EtchAnchorLinks etch-analytics" data-module-id="{{id}}">
      <ul className="EtchAnchorLinks__list">
        { links.map((link, index) => {
          let linkJSX;
          if (link.link) {
            linkJSX = (
              <li className="EtchAnchorLinks__list__item" key={`${link.linkText}-${index}`}>
                <CTARenderer ctaLink={link.link} className="EtchAnchorLinks__list__item__link">
                  {link.linkText}
                </CTARenderer>
              </li>
            );
          } else {
            linkJSX = (
              <p key={`${link.linkText}-${index}`}>{link.linkText}</p>
            );
          }

          return linkJSX;
        })}
      </ul>
    </div>
  );
};

EtchAnchorLinks.displayName = 'EtchAnchorLinks';

EtchAnchorLinks.propTypes = {
  opts: PropTypes.shape({
    links: PropTypes.instanceOf(Array)
  }).isRequired
};

EtchAnchorLinks.defaultProps = {
};

export default EtchAnchorLinks;
