import { useEffect, useState, useRef } from 'react';

export const useMVCSID = (interval = 500, maxTries = 10) => {
  const [mcvisid, setMcvisid] = useState('');
  const ref = useRef();

  useEffect(() => {

    let count = 0;
    let val = window?.cookieUtils?.readBrowserCookie('_meta_neustar_mcvisid');
    if (val) {
      setMcvisid(val);
    } else {
      ref.current = setInterval(() => {
        val = window?.cookieUtils?.readBrowserCookie('_meta_neustar_mcvisid');

        if (val) {
          clearInterval(ref.current);
          setMcvisid(val);

        } else {
          count += 1;
          if (count > maxTries) clearInterval(ref.current);
        }

      }, interval);
    }
    return () => {
      if (ref.current) clearInterval(ref.current);
    };
  }, []);

  return mcvisid;
};
