import React from 'react';
import { Carousel } from '@one-thd/sui-carousel';
import { useStore } from '@thd-nucleus/experience-context';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { NoProductsMessage } from './NoProductsMessage';
import { CategoryCarouselPropType, CategoryCarouselDefaultPropType } from './Helper';
import { CustomProductPod } from './CustomProductPod';
import { CategoryBlock } from './CategoryBlock';

const CategoryCarousel = ({
  endCapData,
  exclude,
  categoryName,
  categoryImageURL,
  categoryURL,
  categoryShopAllLink,
  title,
  entryId,
  storeId
}) => {
  const { storeZip: zipCode } = useStore();
  const hasProducts = (endCapData?.products || []).filter((product) => {
    return (exclude || []).indexOf(product.itemId) === -1;
  }).length !== 0;
  // TODO: Temporary fix to add a max width for serverside render. Stencil team working on long term fix
  const productPodMaxWidth = typeof window !== 'undefined' ? 'none' : '240px';

  const properUrl = categoryShopAllLink !== '' ? categoryShopAllLink : categoryURL;
  const showCategoryBlock = categoryImageURL && properUrl;
  const dataSources = endCapData?.products?.[0]?.dataSources === 'personalizedDeals'
    ? 'personalizedDeals'
    : 'topDeals';
  const cacheKey = dataSources && dataSources === 'personalizedDeals'
    ? 'personalized-deals-child'
    : 'top-deals-child';

  let impressionName = title.replace(/\s/g, '');
  let impressionCategoryName = categoryName.replace(/\W+/g, '');

  const impressionData = {
    id: entryId || '',
    component: 'Endcap',
    name: impressionName || '',
    type: 'product',
    position: '',
    category: impressionCategoryName || ''
  };

  if (!hasProducts) {
    return (
      <NoProductsMessage
        categoryName={categoryName !== 'All' ? categoryName : ''}
        imageUrl={categoryImageURL}
        url={properUrl}
        title={title}
      />
    );
  }

  return (
    <ImpressionProvider data={impressionData}>
      <div className="endcap-section sui-w-full sui-flex sui-gap-4 sui-pt-5">
        <div
          id={'digital-endcap-' + endCapData.catName}
          className="digital-endcap sui-w-full sui-overflow-hidden"
        >
          <QueryProvider
            dataSource={endCapData?.products?.[0]?.dataSources}
            dataSources={endCapData?.products?.[0]?.dataSources}
            defaultVariables={{
              storeId,
              zipCode,
              skipSpecificationGroup: true
            }}
            cacheKey={cacheKey}
          >
            <Carousel
              slidesPerView={2}
              slidesPerGroup={2}
              breakpoints={{
                sm: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                md: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                lg: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                xl: {
                  slidesPerView: 5,
                  slidesPerGroup: 5,
                }
              }}
              initialSlide={0}
              disableMargin
            >
              {(showCategoryBlock ? [
                <CategoryBlock
                  categoryName={categoryName}
                  categoryImageURL={categoryImageURL}
                  properUrl={properUrl}
                  impressionData={impressionData}
                />
              ] : []).concat((endCapData?.products || [])
                .filter((product) => {
                  return (exclude || []).indexOf(product.itemId) === -1;
                })
                .map((product, productIndex) => (
                  <div
                    id={'top-deals__wrapper-' + productIndex}
                    key={product.itemId}
                    style={{ maxWidth: productPodMaxWidth }}
                  >
                    <CustomProductPod itemId={product.itemId} storeId={storeId} productIndex={productIndex} />
                  </div>
                )))}
            </Carousel>
          </QueryProvider>
        </div>
      </div>
    </ImpressionProvider>
  );
};

CategoryCarousel.displayName = 'CategoryCarousel';
CategoryCarousel.propTypes = CategoryCarouselPropType;
CategoryCarousel.defaultProps = CategoryCarouselDefaultPropType;

export { CategoryCarousel };
