/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import AddressEntryFormPrivacy from '../AddressEntryFormPrivacy';
import './PrivacyForm.scss';

/**
 * @group Legacy
 * Privacy form component
 */

const PrivacyForm = (props) => {
  return (
    <div className="col-1-1 pad alpha omega">
      <form name="ManagePrivacy" method="post" id="ManagePrivacy" action="/customer/account/v1/preferences/privacy">
        <input type="hidden" name="contentPageName" value="Manage_Privacy_Preferences" />
        <input type="hidden" name="URL" value="/c/Manage_Privacy_Preferences_Confirmation" />
        <input type="hidden" name="errorView" value="/c/webapp/catalog/servlet/ContentView" />
        <div id="errorMessageId" />
        <div id="welcome">
          <p className="l">The Home Depot knows that you care about how your information is used and we are committed to honoring your privacy. Please use this page to inform us of how you would like to receive marketing communications. These privacy preferences will not affect communications such as account statements, online order confirmations, customer service inquiries and legal documents.</p>
          <br />
          <p className="l">These preferences will not affect The Home Depot companies that are treated as separate businesses. Click <a href="http://www.homedepot.com/c/Select_Company_Privacy_Preferences">here</a>        for the privacy preference options for these businesses. Click <a href="http://www.homedepot.com/c/Privacy_Security">here</a>        to view the Privacy Statement for The Home Depot, Inc.</p>
          <br />
          <p className="l">Updates to your privacy preference information will be submitted once the Set Preferences button has been selected.</p>
          <br />
        </div>
        <div className="clearfix">
          <h3>E-mail</h3>
          <p className="l">
            The Home Depot would like to contact you with information about products,
            services, special offers and other promotions by email.
            Please let us know your email preferences.
          </p>
          <br />

          <div className="control-group">
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="emailRadio1" id="emailRadioBtnR1" name="emailRadioBtn" />
              <label className="radio-btn__label" htmlFor="emailRadioBtnR1"><span className="radio-btn" />I DO NOT wish to receive any marketing emails from The Home Depot.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="emailRadio2" id="emailRadioBtnR2" name="emailRadioBtn" />
              <label className="radio-btn__label" htmlFor="emailRadioBtnR2"><span className="radio-btn" />I would like to receive occasional marketing emails from The Home Depot in accordance with The Home Depot Privacy Statement.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" defaultChecked type="radio" value="emailRadio3" id="emailRadioBtnR3" name="emailRadioBtn" />
              <label className="radio-btn__label" htmlFor="emailRadioBtnR3"><span className="radio-btn" />No changes at this time.</label>
            </p>
            <div className="control-group">
              <label className="control-label col-2-12 alpha omega" htmlFor="emailAddress">
                <span>*</span>Email Address:
              </label>
              <div className=" col-4-12 alpha omega">
                <span className="form-input">
                  <input className="form-input__field" name="emailAddress" type="text" id="emailAddress" maxLength="50" />
                </span>
              </div>
            </div>
          </div>

        </div>
        <div className="clearfix u__float-clear">
          <h3>Telephone Calls</h3>
          <p className="l">
            On occasion, you may hear from The Home Depot about products, services, special offers and other promotions. However, at your request, The Home Depot will not call you regarding such promotions. For a copy of our Do Not Call Policy, <a href="http://www.homedepot.com/c/Do_Not_Call">Click here</a>
          </p>
          <br />

          <div className="control-group">
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="phRadio1" id="phoneRadioBtnR1" name="phoneRadioBtn" />
              <label className="radio-btn__label" htmlFor="phoneRadioBtnR1">
                <span className="radio-btn" />I DO NOT wish to receive any marketing calls from The Home Depot.
              </label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="phRadio2" id="phoneRadioBtnR2" name="phoneRadioBtn" />
              <label className="radio-btn__label" htmlFor="phoneRadioBtnR2"><span className="radio-btn" />I would like to receive marketing calls from The Home Depot in accordance with The Home Depot Privacy Statement.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" defaultChecked type="radio" value="phRadio3" id="phoneRadioBtnR3" name="phoneRadioBtn" />
              <label className="radio-btn__label" htmlFor="phoneRadioBtnR3"><span className="radio-btn" />No changes at this time.</label>
            </p>
            <br />
            <div className="control-group">
              <span htmlFor="" id="phoneNumberL" className="control-label col-2-12 alpha omega"><span>*</span>Phone Number(s): (include area code)</span>
              <div className="col-4-12 alpha omega">

                <div className="col-12-12 alpha omega altphone">
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText11" name="phText11" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText12" name="phText12" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="4" id="phText13" name="phText13" />
                    </span>
                  </div>
                </div>
                <div className="col-12-12 alpha omega" altphone>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText21" name="phText21" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText22" name="phText22" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="4" id="phText23" name="phText23" />
                    </span>
                  </div>
                </div>
                <div className="col-12-12 alpha omega altphone">
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText31" name="phText31" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="3" id="phText32" name="phText32" />
                    </span>
                  </div>
                  <div className="col-4-12 pad alpha omega">
                    <span className="form-input">
                      <input className="form-input__field" type="text" maxLength="4" id="phText33" name="phText33" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix u__float-clear">
          <h3>Postal Mail</h3>
          <p className="l">The Home Depot would like to contact you with information about products, services, special offers and other promotions by mail. If you have received information from us in the past, please enter your address exactly as it appears on the correspondence.Any errors may prevent removal from our mailing list. All fields are required unless noted.</p>
          <br />

          <div className="control-group">
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="mailRadio1" id="mailRadioBtnR1" name="mailRadioBtn" />
              <label className="radio-btn__label" htmlFor="mailRadioBtnR1"><span className="radio-btn" />I DO NOT wish to receive any marketing mailings from The Home Depot.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" type="radio" value="mailRadio2" id="mailRadioBtnR2" name="mailRadioBtn" />
              <label className="radio-btn__label" htmlFor="mailRadioBtnR2"><span className="radio-btn" />Please send me occasional catalogs, circulars, coupons and special offers.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" defaultChecked type="radio" value="mailRadio3" id="mailRadioBtnR3" name="mailRadioBtn" />
              <label className="radio-btn__label" htmlFor="mailRadioBtnR3"><span className="radio-btn" />No changes at this time.</label>
            </p>

            <AddressEntryFormPrivacy />

          </div>

        </div>
        <div className="clearfix u__float-clear">
          <h3>Data Sharing for Catalog Mailings</h3>
          <p className="l">From time to time, we may share your name, address and transactional information about products you have purchased from a Home Depot catalog. Home Depot will only share this information with carefully screened companies so that you may receive future catalogs offerings about other products and services that may be of interest to you. Please indicate below if you wish to not have your information shared with these companies.</p>
          <br />

          <div className="control-group">
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" defaultChecked type="radio" value="dataShare1" id="datashareRadioBtnR1" name="datashareRadioBtn" />
              <label className="radio-btn__label" htmlFor="datashareRadioBtnR1"><span className="radio-btn" />No, please do not share my information.</label>
            </p>
            <p className="radio-btn__content-wrapper">
              <input className="radio-btn__input" defaultChecked type="radio" value="dataShare2" id="datashareRadioBtnR2" name="datashareRadioBtn" />
              <label className="radio-btn__label" htmlFor="datashareRadioBtnR2"><span className="radio-btn" />No changes at this time.</label>
            </p>
          </div>

        </div><br />
        <div id="confFooter">
          <div className="clearfix u__float-clear">
            <h3>Additional Information</h3>
            <p className="l">
              Should you have any additional questions,
              please contact us at 1-800-HOME-DEPOT 1-800-(466-3337).<br />
              Updates to your privacy preference information will be
              submitted once the Set Preferences button has been selected
            </p>
          </div><br />
          <div className="clearfix">
            <button className="bttn bttn--primary" type="button"><span className="bttn__content">Set Preferences</span></button>
          </div><br />
          <div className="clearfix">
            <p className="l">
              At The Home Depot we take your privacy very seriously.
              Registration and sign-in are governed by our <a href="/c/Privacy_Security" id="Privacy_Security">Privacy & Security Statement.</a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

PrivacyForm.propTypes = {
};

export default PrivacyForm;
