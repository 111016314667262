import React from 'react';
import { Link } from '@one-thd/sui-atomic-components';
import { arrayOf, shape, string, bool } from 'prop-types';
import { trackDocumentLinkClick } from '../product-overview-analytics';

export const DocumentList = ({ documents, returnMessage, showReturnMessage }) => {
  const allDocuments = showReturnMessage && returnMessage !== 'Non-Returnable' ? [...documents, {
    name: 'Return Policy',
    url: 'https://www.homedepot.com/c/Return_Policy'
  }] : documents;

  return (
    <ul className="sui-list-disc sui-px-6">
      {allDocuments.map((document) => {
        const { name, url } = document;
        return (
          <li key={name}>
            <Link href={url} target="_blank" onClick={() => trackDocumentLinkClick(name.toLowerCase())}>
              {name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

DocumentList.displayName = 'DocumentList';

DocumentList.propTypes = {
  documents: arrayOf(shape({
    name: string.isRequired,
    url: string.isRequired
  })).isRequired,
  returnMessage: string,
  showReturnMessage: bool
};
DocumentList.defaultProps = {
  returnMessage: '',
  showReturnMessage: false
};
