import React from 'react';
import PropTypes from 'prop-types';
import SubHeader from './SubHeader.component';

/**
 * @group Titles
 * Block header
 */

const BlockHeader = (props) => {
  const { opts = {}, children } = props;
  const { options } = opts;

  return (
    <h3 className="block-header">
      <div className="block-header__border" />
      <SubHeader options={options} />
      {/* eslint-disable-next-line react/no-danger */}
      <div className="block-header__header" dangerouslySetInnerHTML={{ __html: opts.text }} />
      <div className="block-header__border" />
    </h3>
  );
};

BlockHeader.propTypes = {

  opts: PropTypes.shape({
    text: PropTypes.string,
    options: PropTypes.shape({
      hideSubhead: PropTypes.bool,
      subheadText: PropTypes.string
    })
  }).isRequired,
  children: PropTypes.instanceOf(Array)
};

BlockHeader.defaultProps = {
  children: []
};
export default BlockHeader;
