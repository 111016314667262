import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EtchVideo.scss';
import { MetaData, Data, DataConfig } from './data';

const EtchVideo = (props) => {
  const { opts = {} } = props;
  const {
    id = '',
    mediaData = {},
    name = ''
  } = opts;
  const { src = '', embedFullVideo } = mediaData;

  const configureVideo = async () => {
    const { default: EtchModule } = await import(/* webpackChunkName: "common" */ 'etchmodule');
    // eslint-disable-next-line no-shadow
    const EtchVideo = new EtchModule(MetaData);
    EtchVideo.data = Data;
    EtchVideo.dataConfig = DataConfig;
  };

  useState(() => {
    configureVideo();
  }, []);

  return (
    <div className="EtchVideo" data-module-id={id}>
      <iframe width="100%" src={src} allowFullScreen={embedFullVideo} frameBorder="0" id={name} title={name} />
    </div>
  );
};

EtchVideo.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    mediaData: PropTypes.shape({
      src: PropTypes.string
    })
  }).isRequired
};

export default EtchVideo;
