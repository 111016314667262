import { newRelicConstants } from '../constants';
import {
  BANNER, bannerTypes, carouselTypes, PRODUCT, schemas
} from '../constants/constants';

const PARAMS_VALIDATION = {
  default: {
    required: ['schema', 'browserid', 'appid'],
    optional: ['appName', 'customerType', 'storeid']
  },

  cat_sponsored: {
    required: ['category'],
    optional: []
  },

  browse_sponsored: {
    required: ['category'],
    optional: ['organicskus']
  },

  search_sponsored: {
    required: ['keyword', 'organicskus'],
    optional: ['category']
  },

  pip_sponsored: {
    required: ['viewedsku', 'organicskus']
  }
};

export const isEventPage = ({ data }) => {
  let flag = false;

  if (data && data.searchModel && data.searchModel.appliedDimensions) {
    data.searchModel.appliedDimensions.forEach((element) => {
      if (element.label === 'The Home Depot Events') {
        flag = true;
      }
    });
  }

  return flag;
};

export const getSchema = ({ schema = '', pageContext = {}, props }) => {

  const { label, isCategory, isSearch, isBrowse } = pageContext;
  // schema props is from educational layout
  if (props?.schema) return props.schema;
  if (schema) {
    return schema;
  }

  if (label === 'browse-search') {
    if (isCategory) {
      return 'cat_sponsored';
    }
    if (isSearch) {
      return 'search_sponsored';
    }
    if (isBrowse) {
      if (isEventPage(pageContext)) {
        return 'event_browse_sponsored';
      }
      return 'browse_sponsored';
    }
  }

  return null;
};

export const isBrandFpn = ({ experienceContext, pageContext }) => {
  const { path } = experienceContext;
  const { data } = pageContext;

  const hasBrandCrumb = data?.searchModel?.taxonomy?.breadCrumbs?.some((crumb) => crumb.dimensionName === 'Brand');
  const isFeaturedProductNode = path?.indexOf('/b/Featured-Products') !== -1;

  const hasBrandSearch = data?.searchModel?.metadata?.analytics?.semanticTokens
    && data.searchModel.metadata.analytics.semanticTokens.indexOf('br:{') !== -1;
  return hasBrandCrumb || hasBrandSearch || isFeaturedProductNode;
};

const getSkusFromSearchModel = (products) => {
  return products
    .map((product) => product.itemId)
    .reduce((total, sku) => total + ',' + sku, '').slice(1) || '';
};

const getSkusFromLocalStorage = () => {
  const retailMediaRecentlyViewedSku = localStorage.getItem('retailMediaRecentlyViewedSku');
  const retailMediaRecentlyViewedSkuRefreshAt = localStorage.getItem('retailMediaRecentlyViewedSkuRefreshAt');
  // eslint-disable-next-line
  if (retailMediaRecentlyViewedSku && retailMediaRecentlyViewedSkuRefreshAt && parseInt(retailMediaRecentlyViewedSkuRefreshAt) >= parseInt(new Date().getTime())) {
    return retailMediaRecentlyViewedSku;
  }
  return '';
};

export const getSkus = ({ data }, utilizeRvData) => {
  if (data?.searchModel?.products) {
    return getSkusFromSearchModel(data?.searchModel?.products);
  }
  if (utilizeRvData) {
    return getSkusFromLocalStorage();
  }
  return '';
};

export const haveProductsChanged = ({ lastRequestScenario, pageContext }) => {
  const currentSkus = getSkus(pageContext);
  return currentSkus && lastRequestScenario.skus !== currentSkus;
};

export const isExperienceDataResolved = ({ isLocalized, experienceContext }) => {
  const { mounted } = experienceContext;
  if (mounted && isLocalized) {
    return true;
  }
  return false;
};

export const hasValidPipData = (pageContext, fbtSkus) => {
  if (!pageContext?.data?.itemId) {
    return false;
  }

  return fbtSkus && fbtSkus?.length > 0;
};

export const isServer = () => {
  return typeof window === 'undefined';
};

export const getUrlParams = (url) => {
  const params = {};

  try {
    url.split('?')[1].split('&').forEach((el) => {
      const keyVal = el.split('=');
      params[keyVal[0]] = keyVal[1];
    });
  } catch (error) { /* */ }

  return params;
};

export const getDefaultBanner = ({ channel, isConsumerApp }, externalDefaultBannerConfig) => {
  const externalConfigDefaultBanner = externalDefaultBannerConfig?.defaultBanners?.find((banner) => banner.isActive === true);
  const defaultBannerMap = [
    {
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/bopis-desktop.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/bopis-mobile.jpg',
      clickthruURL: 'https://www.homedepot.com/c/pick_up_in_store',
    },
    {
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/delivery-desktop.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/delivery-mobile.jpg',
      clickthruURL: 'https://www.homedepot.com/c/About_Your_Online_Order',
    },
    {
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/homeservices-desktop1.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/homeservices-mobile1.jpg',
      clickthruURL: 'https://www.homedepot.com/services/?vfrom=capability',
    },
  ];
  if (!isConsumerApp) {
    defaultBannerMap.push({
      imgDesktop: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/app-desktop.jpg',
      imgMobile: 'https://contentgrid.thdstatic.com/hdus/en_US/DTCCOMNEW/fetch/app-mobile.jpg',
      clickthruURL: 'https://www.homedepot.com/c/SF_Mobile_Shopping'
    });
  }
  const defaultBanner = externalConfigDefaultBanner?.clickthruURL
    ? externalConfigDefaultBanner
    : defaultBannerMap[Math.floor(Math.random() * defaultBannerMap.length)];

  defaultBanner.url = defaultBanner.imgDesktop;
  if (channel === 'mobile') {
    defaultBanner.url = defaultBanner.imgMobile;
  }

  let imgSrc = defaultBanner?.url;
  let href = defaultBanner.clickthruURL;
  if (typeof window !== 'undefined') {
    try {
      const aTag = window.document.querySelector('#default_banner_carousel a');
      const img = window.document.querySelector('#default_banner_carousel img');
      href = aTag.href;
      imgSrc = img.src;
    } catch (error) { /* */ }
  }

  const paddingTop = channel === 'mobile' ? '37.7%' : '10.6%';

  const spacingCss = {
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
    position: 'relative',
    paddingTop
  };

  const bgCss = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundImage: `url("${imgSrc}")`
  };

  return { spacingCss, bgCss, href, imgSrc };
};

export const getMockPlaData = (itemId) => {
  return [
    {
      id: `${itemId}`,
      itemType: 'MERCHANDISE',
      // eslint-disable-next-line max-len
      imageURL: 'some-image.jpg',
      canonicalURL: `/p/${itemId}`,
      brand: 'Mock brand',
      productName: 'Mock product',
      price: 654.96,
      uom: 'each',
      slotId: '12485',
      campaignId: 109105,
      placementId: 23,
      // eslint-disable-next-line max-len
      onViewBeacon: 'https://ad-thd-east.tagdelivery.com/impression?id=4769a8f7-4d93-4261-bd76-c9d117f4ebc6&x=c3paeMtETMO4IlfeLVovMwe88pDevlQiWyFP6oEyO-3a5h4XD8L7pcUgkeqHQRs0_BQpaXlzlc-53Ri1X8y53YKyN0vrVr8bSOTBRVZOjAibUUrbaI8JlbqpsBXdsBQsKu6FI6PqUllVYeRv1j5_BwS7iDilHRf-q4W_Qd9izGhHfnt5pN_oywasjjqlnicW_K1WEhh4HVRWNtintwEPmgLxmRV7kbvmYHGMPs5k56goIAONpk0yOTFJvzgt-UP9Y-W563OkwfuNWzzkkH0JD0myav8Q6C2JzOWZopwxJEv_JIyj-vumPhmCT2XqYucD40HKiBSyrAEwSXwmTbfaBoSwcs55j9Hp6so7Q394Ye39InDACeLVoW_NU_WPOfsJaclZOkrfIeUU_5JBTz5y590iJDLQTWvlWPwxPdyh-xE%3D',
      // eslint-disable-next-line max-len
      onClickBeacon: 'https://ad-thd-east.tagdelivery.com/click?id=4769a8f7-4d93-4261-bd76-c9d117f4ebc6&x=c3paeMtETMO4IlfeLVovMwe88pDevlQiWyFP6oEyO-3a5h4XD8L7pcUgkeqHQRs0_BQpaXlzlc-53Ri1X8y53YKyN0vrVr8bSOTBRVZOjAibUUrbaI8JlbqpsBXdsBQsKu6FI6PqUllVYeRv1j5_BwS7iDilHRf-q4W_Qd9izGhHfnt5pN_oywasjjqlnicW_K1WEhh4HVRWNtintwEPmgLxmRV7kbvmYHGMPs5k56goIAONpk0yOTFJvzgt-UP9Y-W563OkwfuNWzzkkH0JD0myav8Q6C2JzOWZopwxJEv_JIyj-vumPhmCT2XqYucD40HKiBSyrAEwSXwmTbfaBoSwcs55j9Hp6so7Q394Ye39InDACeLVoW_NU_WPOfsJaclZOkrfIeUU_5JBTz5y590iJDLQTWvlWPwxPdyh-xE%3D&redirect='
    }
  ];
};

export const isEventBusCreated = () => {
  return typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS;
};

export const adIsNonEndemic = (customRenderId) => {
  if (customRenderId === 'sponsoredmiddlebanner') {
    return true;
  }
  return false;
};

export const adIsCategoryOrBrowse = (schema) => {
  const adType = schema.toLowerCase();
  if (adType === 'cat_sponsored' || adType === 'browse_sponsored') {
    return true;
  }
  return false;
};

export const nValueIsValid = (nValue) => {
  if (nValue?.startsWith('N-') && nValue?.includes('Z')) {
    return true;
  }
  return false;
};

export const isNValueRequiredAndValid = (slugId, customRenderId, schema) => {
  const nValue = slugId;

  if (!adIsNonEndemic(customRenderId) && adIsCategoryOrBrowse(schema) && !nValueIsValid(nValue)) {
    return false;
  }
  return true;
};

export const isValidRequest = (queryParams) => {
  const requiredDefaultParams = PARAMS_VALIDATION.default.required;

  for (let i = 0; i < requiredDefaultParams.length; i += 1) {
    const paramKey = requiredDefaultParams[i];

    if (!queryParams[paramKey]) {
      return false;
    }
  }
  return true;
};

export const triggerNewRelic = (adType, eventType) => {
  if (!window.sponsoredContent) {
    window.sponsoredContent = {};
  }

  if (window.newrelic && typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('sponsored-content.event', { adType, event: eventType });
    window.sponsoredContent[adType] = true;
  }
};

export function getIframeAnchorTags(bannerType) {
  let anchorTags = [];
  const targetIframe = [...document.querySelectorAll('iframe[data-revjet-options]')]
    .find((el) => el.attributes['data-revjet-options'].value.includes(bannerType));

  if (targetIframe) {
    anchorTags = [...targetIframe?.contentWindow.document.querySelectorAll('a')];
  }

  return anchorTags;
}

export function newRelicClickHandler(bannerType, anchorTags) {
  anchorTags.forEach((link) => {
    link.removeEventListener('click', newRelicClickHandler);
  });
  if (bannerType === 'skyscraper') {
    triggerNewRelic(newRelicConstants.SKYSCRAPER_BANNER, newRelicConstants.CLICK);
  } else if (bannerType === 'leaderboard') {
    triggerNewRelic(newRelicConstants.LEADERBOARD_BANNER, newRelicConstants.CLICK);
  } else if (bannerType === 'hero') {
    triggerNewRelic(newRelicConstants.HERO_BANNER, newRelicConstants.CLICK);
  } else if (bannerType === 'middle') {
    triggerNewRelic(newRelicConstants.PIP_BANNER, newRelicConstants.CLICK);
  }
}

export const triggerNewRelicTimeLog = (componentName, startTime, endTime) => {
  if (!window.sponsoredContent) {
    window.sponsoredContent = {};
  }

  if (window.newrelic && typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('performance.component-load', {
      componentName,
      startTime,
      endTime,
    });
    window.sponsoredContent[componentName] = true;
  }
};

export function newRelicClickMetricHandler(bannerType, anchorTags, mode = 'normal') {
  if (anchorTags.length > 0) {
    anchorTags.forEach((link) => {
      if (mode === 'test') {
        link.addEventListener('click', () => this.newRelicClickHandler(bannerType, anchorTags, mode), true);
      } else {
        link.addEventListener('click', () => {
          newRelicClickHandler(bannerType, anchorTags, mode);
        }, true);
      }
    });
  }
}

export function parseIfJson(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    return {};
  }
}

export function mapSearchModel(product, searchModel) {
  const {
    identifiers, pricing, reviews, media, itemId
  } = searchModel;
  return {
    ...product,
    id: itemId,
    brand: identifiers?.brandName,
    reviews: reviews?.ratingsReviews?.totalReviews ? Number(reviews?.ratingsReviews?.totalReviews) : null,
    rating: reviews?.ratingsReviews?.averageRating ? Number(reviews?.ratingsReviews?.averageRating) : null,
    price: pricing?.value,
    originalPrice: pricing?.original,
    dollarsOff: pricing?.promotion?.dollarOff,
    percentageOff: pricing?.promotion?.percentageOff,
    originalUnitPrice: pricing?.alternate?.unit?.unitsOriginalPrice,
    itemType: identifiers?.productType,
    canonicalURL: identifiers?.canonicalUrl,
    imageURL: media?.image?.url,
    caseUnitUom: pricing?.alternate?.unit?.caseUnitOfMeasure,
    uom: pricing?.unitOfMeasure,
    unitPrice: pricing?.alternate?.unit?.value
  };
}

export const getAdTypeFromParams = (queryParams) => {
  if (queryParams.show === BANNER) {
    if (queryParams.bannerType === bannerTypes.HERO) {
      return newRelicConstants.HERO_BANNER;
    }
    if (queryParams.bannerType === bannerTypes.SKYSCRAPER) {
      return newRelicConstants.SKYSCRAPER_BANNER;
    }
    if (queryParams.bannerType === bannerTypes.LEADERBOARD) {
      return newRelicConstants.LEADERBOARD_BANNER;
    }
    if (queryParams.bannerType === bannerTypes.MIDDLE) {
      return newRelicConstants.PIP_BANNER;
    }
    return newRelicConstants.STANDARD_BANNER;
  }
  if (queryParams.carouselType === carouselTypes.SEO) {
    return newRelicConstants.CAROUSEL_SEO;
  }
  if (queryParams.schema === schemas.PIP_SPONSORED && queryParams.carouselType === carouselTypes.PLA) {
    return newRelicConstants.PRODUCT_LISTING_AD;
  }
  if (queryParams.carouselType === carouselTypes.PLA || queryParams.show === PRODUCT || !queryParams.show) {
    return newRelicConstants.CAROUSEL_PLA;
  }
  return null;
};

export const onTriggerNewRelicAdBlocked = (adType) => {
  if (!adType) return;
  triggerNewRelic(adType, newRelicConstants.AD_BLOCKED);
};

export const showCarouselPlaceholder = (renderNewCarousel, experienceContext, pageContext, loadingState) => {
  if (renderNewCarousel && !isBrandFpn({ experienceContext, pageContext }) && loadingState) {
    return true;
  }
  return false;
};

export const hasAnchorSku = (pageContext) => {
  return pageContext?.data?.itemId;
};

export const handleLoadingState = ({
  error,
  serverError,
  isAPICallCompleted,
  products,
  atcFilteredProducts
}) => {
  if (error || serverError) {
    return false;
  }
  // handle 204
  if (isAPICallCompleted && products?.length === 0) {
    return false;
  }
  // we have a match from api and graphql
  if (atcFilteredProducts.length > 0) {
    return false;
  }
  // api return 200 but theres no match
  if (isAPICallCompleted && atcFilteredProducts?.length === 0) {
    return false;
  }
  return true;
};
