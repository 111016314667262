import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@thd-olt-component-react/carousel';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import './Slider.scss';

const Slider = (props) => {
  const { children, dots, multiItem } = props;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const screenBreakpoint = isMobile ? 640 : 1025;
  const items = children.filter((n) => n)[0];

  return (
    <div className="etch-slider">
      <Carousel
        multiItem={multiItem}
        showDots={dots && !isMobile}
        interval="8000"
        dotBelow={dots && !isMobile}
        itemWidthMobile="50%"
        screenBreakpoint={screenBreakpoint}
      >
        {items}
      </Carousel>
    </div>
  );
};

Slider.propTypes = {
  dots: PropTypes.bool,
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    type: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  children: PropTypes.instanceOf(Array),
  multiItem: PropTypes.bool
};

Slider.defaultProps = {
  dots: false,
  children: [],
  multiItem: true
};

export default Slider;
