import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import './HeroModule.scss';
import './HeroModuleBC.scss';
import './HeroModuleBL.scss';
import './HeroModuleCC.scss';
import './HeroModuleWhite.scss';
import CTARenderer from '../CTARenderer/CTARenderer.component';

function hasRequiredText(textVal) {
  /**
   * Here we are handling the strange edge case where
   * we're often getting strings with a single space in them
   * in the response from Etch / the content query.
   */
  return typeof textVal === 'string' && !!textVal.length && textVal !== ' ';
}

const HeroModule = (props) => {
  const {
    opts = {},
    isHeroModule,
    isHeroModuleWhite,
    isHeroModuleCC,
    isHeroModuleBC,
    isHeroModuleBL,
    channel
  } = props;
  const {
    id,
    ctaLink,
    heroMedia = {},
    heroMediaMobile = {},
    eventLogo = {},
    eventLogoMobile = {},
    heading,
    subHeading,
    cta,
    collectionLink,
    cornerCaption,
  } = opts;
  const isMobile = channel === 'mobile';
  const wrapperClass = classNames({
    etchHero: isHeroModule,
    etchHeroWhite: isHeroModuleWhite,
    etchHeroCC: isHeroModuleCC,
    etchHeroBC: isHeroModuleBC,
    etchHeroBL: isHeroModuleBL
  });

  const renderHeroMedia = () => {
    const media = isMobile ? heroMediaMobile : heroMedia;
    const { src, alt, height, width } = media;
    const imgClass = classNames({
      'img--block': height && width
    });
    return (
      <picture className={`${wrapperClass}__media`}>
        <source media="(min-width: 640px)" srcSet={src} />
        <Image
          className={`stretchy ${wrapperClass}__main ${imgClass}`}
          src={src}
          alt={alt}
          height={height}
          width={width}
        />
      </picture>
    );
  };

  const renderEventLogo = () => {
    const logo = isMobile ? eventLogoMobile : eventLogo;
    const { src, alt, height, width } = logo;
    const imgClass = classNames({
      'img--block': height && width
    });
    const logoImg = isMobile ? `${wrapperClass}__eventLogoMobile` : `${wrapperClass}__eventLogo`;
    const logoWrapperClass = isMobile ? `${wrapperClass}__mobilelogo` : `${wrapperClass}__logo`;
    return (
      <div className={`${logoWrapperClass}`}>
        <Image
          src={src}
          className={`${logoImg} ${imgClass}`}
          alt={alt}
          height={height}
          width={width}
        />
      </div>
    );
  };

  return (
    <div className={wrapperClass} id={id}>
      {hasRequiredText(ctaLink) ? (
        <CTARenderer ctaLink={ctaLink} className={`${wrapperClass}__link`}>
          {renderHeroMedia()}
        </CTARenderer>
      ) : (
        renderHeroMedia()
      )}
      <div className={`${wrapperClass}__info u__text-shadow--none`}>
        {renderEventLogo()}
        {hasRequiredText(heading) && (
          <h2 className={`${wrapperClass}__heading u__display-font--black u__jumbo`}>{heading}</h2>
        )}
        {hasRequiredText(subHeading) && (
          <h3 className={`${wrapperClass}__subHeading u__large`}>{subHeading}</h3>
        )}
        {hasRequiredText(cta) && (
          <CTARenderer ctaLink={ctaLink} className={`${wrapperClass}__btn bttn bttn--primary bttn--inline`}>
            <span className={`${wrapperClass}__btn__content bttn__content`}>{cta}</span>
          </CTARenderer>
        )}
      </div>
      {hasRequiredText(cornerCaption) && (
        <div className={`${wrapperClass}__corner`}>
          {collectionLink && (
            <CTARenderer className={`${wrapperClass}__corner__caption`} ctaLink={collectionLink}>
              {cornerCaption}
            </CTARenderer>
          )}
          {!collectionLink && <p className={`${wrapperClass}__corner__caption`}>{cornerCaption}</p>}
        </div>
      )}
    </div>
  );
};

HeroModule.propTypes = {
  opts: PropTypes.shape({
    id: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heroMedia: PropTypes.shape({}),
    heroMediaMobile: PropTypes.shape({}),
    eventLogo: PropTypes.shape({}),
    eventLogoMobile: PropTypes.shape({}),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    collectionLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    cornerCaption: PropTypes.string,
  }).isRequired,
  isHeroModule: PropTypes.bool,
  isHeroModuleWhite: PropTypes.bool,
  isHeroModuleCC: PropTypes.bool,
  isHeroModuleBC: PropTypes.bool,
  isHeroModuleBL: PropTypes.bool,
  channel: PropTypes.string
};
HeroModule.defaultProps = {
  isHeroModule: false,
  isHeroModuleWhite: false,
  isHeroModuleCC: false,
  isHeroModuleBC: false,
  isHeroModuleBL: false,
  channel: 'desktop'
};

export default HeroModule;