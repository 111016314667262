import { useRef } from 'react';
import { useDataModel, extend, params } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import {
  string as stringType, arrayOf as arrayOfType, number as numberType
} from '@thd-nucleus/data-sources/react/dataModel/prop-types';
import { useSpecialBuy } from '../context/SpecialBuyProvider';
import {
  getItemIds, getFulfillableProducts
} from '../utils/utils';
import { INITIAL_NUM_ITEMS_TO_LOAD, MAX_NUM_ITEMS_TO_LOAD } from '../constants';
import {
  SpecialBuyBannerProductPod
} from '../components/SpecialBuyBannerProductPod/SpecialBuyBannerProductPod';

export const useSearchProducts = (category, isFetchAllItemIds) => {
  const { selectedCategory } = useSpecialBuy();
  const { storeId, storeZip } = useStore();

  /* these strange lines ensure shouldLoad is a one-way change from false to true */
  const isSelected = Object.is(selectedCategory, category);
  const shouldLoad = useRef(isSelected);
  if (isSelected) shouldLoad.current = true;

  const allItemIds = category.itemIds;
  const firstItemIds = allItemIds.slice(0, INITIAL_NUM_ITEMS_TO_LOAD);
  const remainingItemIds = allItemIds.slice(INITIAL_NUM_ITEMS_TO_LOAD, MAX_NUM_ITEMS_TO_LOAD);

  const defaultVariables = {
    storeId,
    zipCode: storeZip,
    dataSource: 'searchNav',
    loyaltyMembershipInput: null,
    skipKPF: true,
    skipInstallServices: true,
    skipSpecificationGroup: true,
    skipSubscribeAndSave: true
  };

  const firstItemsResponse = useDataModel('searchModel', {
    variables: {
      ...defaultVariables,
      itemIds: firstItemIds
    },
    ssr: false,
    skip: !firstItemIds.length
      || !shouldLoad.current
  });

  const remainingItemsResponse = useDataModel('searchModel', {
    variables: {
      ...defaultVariables,
      itemIds: remainingItemIds
    },
    ssr: false,
    skip: !isFetchAllItemIds
      || !remainingItemIds.length
      || !shouldLoad.current
  });

  const products = getFulfillableProducts(
    firstItemsResponse?.data?.searchModel?.products,
    remainingItemsResponse?.data?.searchModel?.products
  );

  return {
    products,
    itemIdsToDisplay: getItemIds(products),
    isLoading: firstItemsResponse?.loading || remainingItemsResponse?.loading,
  };
};

useSearchProducts.dataModel = extend({
  searchModel: params({ itemIds: arrayOfType(stringType()) }).shape({
    products: params({ startIndex: numberType() }).arrayOf(
      SpecialBuyBannerProductPod.dataModel.product
    )
  })
});
