/* eslint-disable */
class BackGroundStyles {
  constructor() {
  }

  get(background) {

    if (!background?.image) {
      if (typeof background === 'string') {
        return {
          backgroundColor: background,
        };
      } else if (background?.color) {
        return {
          backgroundColor: background?.color,
        };
      }
      return {};
    }

    return {
      backgroundImage: background.image,
      backgroundSize: (background.isSize_Cover) ? 'center/cover': '',
      backgroundRepeat: (background.isNoRepeat) ? 'no-repeat': 'repeat'
    };
  }
}

export default BackGroundStyles;
