import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useSearchDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { track } from './analytics';
import Helpers from './helper';
import { dataModel } from './dataModel';
import { VisualNavigationTile } from './VisualNavigationTile';
import './visual-category-navigation.scss';

const PRODUCT_CATALOG_IMAGE_PATH = 'https://images.thdstatic.com/catalog/productImages';

const VisualCategoryNavigation = (props) => {
  const { channel } = useContext(ExperienceContext);
  const {
    keyword,
    navParam
  } = props;

  // eslint-disable-next-line
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined';

  // eslint-disable-next-line
  const searchResponse = useSearchDeprecated({ keyword, navParam, domains: ['relatedResults', 'dimensions'], skip: isControlledData });

  let data;
  // eslint-disable-next-line
  if (typeof props.data !== 'undefined') {
    ({ data } = props);
  } else {
    ({ data } = searchResponse);
  }

  if (!data) return null;

  const { visualNavs, visualNavContainsEvents } = data?.searchModel?.relatedResults || [];
  const { dimensions } = data?.searchModel || [];
  let searchTerm = data?.searchModel?.searchReport?.keyword || '';
  let urlAddOn = null;

  const getUrlParameter = (name) => {
    const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    let results;
    if (typeof window !== 'undefined') {
      results = regex.exec(window?.location?.search);
    } else {
      results = null;
    }
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const getUrlHashInfo = (hash) => {
    const cleaned = decodeURIComponent(hash);
    const params = cleaned?.split('&');
    const searchTermRaw = params?.find((param) => param?.includes('searchRedirect'));
    return searchTermRaw?.split('=')?.[1] || '';
  };

  if (!searchTerm) {
    searchTerm = getUrlParameter('searchRedirect');
    if (typeof window !== 'undefined') {
      if (!searchTerm && window?.location?.hash) {
        searchTerm = getUrlHashInfo(window?.location?.hash || '');
      }
    }
  }

  if (searchTerm) {
    urlAddOn = 'visNavSearch=' + encodeURIComponent(searchTerm);
  }

  if (!visualNavs?.length) {
    return null;
  }

  const getSpecialValuesRefinement = () => {
    const priceDimension = dimensions?.filter((dimension) => dimension?.label === 'Price')[0];
    return priceDimension?.refinements.find((refinement) => refinement?.refinementKey === '7');
  };

  const addSpecialSavingsToVisNav = (normalizedVisNavs) => {
    const specialValues = getSpecialValuesRefinement();
    if (!specialValues) {
      return normalizedVisNavs;
    }
    const analytics = {
      categoryId: 'savings',
    };
    const specialbuyVisualNav = {
      key: 'specialbuy',
      url: specialValues.url,
      label: 'Shop Savings',
      analytics,
      src: 'https://assets.thdstatic.com/images/special-buy--pod.jpg'
    };
    normalizedVisNavs.push(specialbuyVisualNav);
    return normalizedVisNavs;
  };

  const getVisNav = (isMobileSlider) => {
    const size = (isMobileSlider) ? 145 : 300;
    const classes = classNames('vis-nav__item');
    let normalizedVisNavs = [];
    visualNavs.forEach((visualNav, index) => {
      const {
        label, imageId, webUrl, categoryId, imageURL = ''
      } = visualNav;
      const indexedImageId = imageId + index;
      const imgUrl = imageId
        ? `${PRODUCT_CATALOG_IMAGE_PATH}/${size}/${imageId.substring(0, 2)}/${imageId}_${size}.jpg`
        : imageURL;
      const productUrl = `${webUrl}`;
      const analytics = {
        categoryId,
      };
      normalizedVisNavs.push({
        key: indexedImageId,
        url: productUrl,
        label,
        analytics,
        src: imgUrl
      });
    });
    if (!visualNavContainsEvents) {
      normalizedVisNavs = addSpecialSavingsToVisNav(normalizedVisNavs);
    }
    return normalizedVisNavs.map((visNav, index) => {
      const {
        key,
        url,
        label,
        analytics,
        src
      } = visNav;
      const indexedKey = key + index;
      let newUrl = url;
      if (urlAddOn) {
        newUrl = url.includes('?')
          ? url + '&' + urlAddOn
          : url + '?' + urlAddOn;
      }
      return (
        <VisualNavigationTile
          key={indexedKey}
          classes={classes}
          src={src}
          href={newUrl}
          onClick={() => track({ ...analytics, position: index + 1 })}
          label={label}
        />
      );
    });
  };

  const spread = (visualNavs.length > 4);
  const isMobileSlider = (channel === 'mobile');
  const getVisNavFunc = getVisNav(isMobileSlider);
  return (
    <div className={classNames('visual-category-navigation',
      { 'vis-nav__mobile': isMobileSlider, 'vis-nav__spread': spread })}
    >
      <div className="vis-nav__collection">
        {getVisNavFunc}
      </div>
    </div>
  );

};

VisualCategoryNavigation.Helpers = Helpers;

VisualCategoryNavigation.dataModel = dataModel;

VisualCategoryNavigation.propTypes = {
  keyword: PropTypes.string,
  navParam: PropTypes.string,
  data: PropTypes.shape({}),
  loading: PropTypes.bool
};

VisualCategoryNavigation.defaultProps = {
  keyword: '',
  navParam: '',
  data: undefined,
  loading: undefined
};

export { VisualCategoryNavigation };
