/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
const VideoPlayer = React.lazy(() => (
  import(/* webpackChunkName: "video-player" */ '@thd-olt-component-react/video-player')
).then((module) => ({ default: module.VideoPlayer })));

const StoryCard = ({
  description, hasVideoID, image = {}, link, linkText, videoID
}) => {
  const altVideoID = link && link.indexOf('playVideo') > -1 ? link.match(/(\d+)/gm)?.[0] : videoID;
  const [overlay, setOverlay] = useState(false);
  const anchorProps = {
    href: (link && link.indexOf('playVideo') === -1) ? link : '#',
    ...(hasVideoID && { 'data-video-id': videoID })
  };
  const { alt, src, height, width } = image;
  const imgClass = classNames({
    'img--block': height && width
  });

  const handleClick = (event) => {
    if (altVideoID) {
      event.preventDefault();
      setOverlay(true);
    }
  };

  if (link) {
    return (
      <>
        <a
          role="button"
          tabIndex={0}
          {...anchorProps}
          onClick={handleClick}
        >
          <Image
            className={`etchStory__card__img ${imgClass}`}
            src={src}
            alt={alt}
            data-testid="card-link-image"
            height={height}
            width={width}
          />
        </a>
        <a
          role="link"
          tabIndex={0}
          {...anchorProps}
          onClick={handleClick}
          className="etchStory__card__title"
        >
          {linkText}
        </a>
        <p className="etchStory__card__description">{description}</p>
        {overlay && (
          <Suspense fallback={<div />}>
            <Overlay
              onClose={() => setOverlay(false)}
              open
              fit
              closeButton
              modalClass="etch-widgets__video-overlay__modal"
              data-testid="card-link-overlay"
            >
              <div className="etch-widgets__video-overlay" data-testid="storyCard-link-video-overlay">
                <VideoPlayer
                  videoId={altVideoID}
                  borderColor="black"
                  autoPlay
                  className="etch-widgets__video-overlay__player"
                />
              </div>
            </Overlay>
          </Suspense>
        )}
      </>
    );
  }
  return (
    <>
      <Image
        className={`etchStory__card__img ${imgClass}`}
        src={src}
        alt={alt}
        data-testid="card-link-image"
        onClick={handleClick}
        height={height}
        width={width}
      />
      <p className="etchStory__card__title">{linkText}</p>
      <p className="etchStory__card__description">{description}</p>
      {overlay && (
        <Suspense fallback={<div />}>
          <Overlay
            onClose={() => setOverlay(false)}
            open
            fit
            closeButton
            modalClass="etch-widgets__video-overlay__modal"
            data-testid="card-link-overlay"
          >
            <div className="etch-widgets__video-overlay" data-testid="storyCard-video-overlay">
              <VideoPlayer
                videoId={altVideoID}
                borderColor="black"
                autoPlay
                className="etch-widgets__video-overlay__player"
              />
            </div>
          </Overlay>
        </Suspense>
      )}
    </>
  );
};

StoryCard.propTypes = {
  description: PropTypes.string.isRequired,
  hasVideoID: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  linkText: PropTypes.string.isRequired,
  videoID: PropTypes.string
};

StoryCard.defaultProps = {
  videoID: ''
};

StoryCard.displayName = 'StoryCard';

export { StoryCard };
