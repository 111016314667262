import React, { useState, useContext, useCallback } from 'react';
import { Tab, Tabs } from '@one-thd/sui-atomic-components';
import { CategoryTabsPropType, CategoryTabsDefaultPropType } from './Helper';

const CategoryTabs = ({
  categories,
  nameOfEventAttribute = 'Model2',
  handleTabClick,
}) => {
  const [value, setValue] = useState(0);
  const onChange = ({ index }) => {
    setValue(index);
  };
  const changeActiveIndex = useCallback((newIndex) => {
    setValue(newIndex);
  }, []);

  return (
    <div className="sui-w-full">
      <Tabs
        value={value}
        onChange={onChange}
        aria-label="digital-endcap-tabs"
        variant="scrollable"
        TabScrollButtonProps={{
          LeftIconButtonProps: { 'aria-label': 'scroll button left' },
          RightIconButtonProps: { 'aria-label': 'scroll button right' }
        }}
      >
        {(categories || []).map((category, categoryIndex) => (
          <Tab
            key={categoryIndex}
            label={category.category}
            value={categoryIndex}
            onClick={(ev) => handleTabClick(
              ev,
              categoryIndex,
              category.nValue,
              nameOfEventAttribute,
              changeActiveIndex
            )}
          />
        ))}
      </Tabs>
    </div>
  );
};

CategoryTabs.propTypes = CategoryTabsPropType;
CategoryTabs.defaultProps = CategoryTabsDefaultPropType;

export { CategoryTabs };