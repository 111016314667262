export const track = (key) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`product-details.${key}`);
  }
};

export const trackDocumentLinkClick = (name) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-overview.guide-click', { target: name });
  }
};
