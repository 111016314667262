import React from 'react';
import { Link } from '@one-thd/sui-atomic-components';
import { string } from 'prop-types';

export const AdditionalResourcesLink = (props) => {
  const { url, name, contentType } = props;
  const formatContentUrl = () => `${contentType !== 'Installation Services' ? `${contentType}: ` : ''} ${name}`;
  return (
    <div id="product-overview">
      <Link
        href={url}
      >
        {formatContentUrl()}
      </Link>
    </div>
  );
};

AdditionalResourcesLink.displayName = 'AdditionalResourcesLink';

AdditionalResourcesLink.propTypes = {
  name: string.isRequired,
  contentType: string.isRequired,
  url: string.isRequired
};
