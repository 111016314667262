import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@thd-olt-component-react/core-ui';
import './EtchCarousel.scss';

const breakpoints = {
  sm: {
    slidesPerView: 1,
    slidesPerGroup: 1
  },
  md: {
    slidesPerView: 1,
    slidesPerGroup: 1
  },
  lg: {
    slidesPerView: 1,
    slidesPerGroup: 1
  },
  xl: {
    slidesPerView: 1,
    slidesPerGroup: 1
  }
};
const EtchCarousel = (props) => {
  const { children } = props;

  const carouselItems = React.Children.toArray(children);

  return (
    <div className="etch-carousel">
      <Carousel
        autoplay
        breakpoints={breakpoints}
        pagination
        peek={false}
        slidesPerGroup={1}
        slidesPerView={1}
      >
        {carouselItems}
      </Carousel>
    </div>
  );
};

EtchCarousel.propTypes = {
  opts: PropTypes.shape({
    options: PropTypes.shape({}),
    type: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

EtchCarousel.defaultProps = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default EtchCarousel;
