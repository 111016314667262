import React, { useEffect, useRef, useState } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { string, shape } from 'prop-types';

export const RevJet = (props) => {
  const {
    itemId,
    parentId,
    defaultEnv
  } = props;

  const [revJetLoaded, setRevJetLoaded] = useState(false);
  const revJetRef = useRef(null);

  const revJetTag = useConfigService('revjet_tag') || defaultEnv.testTag;
  const revJetPlcId = useConfigService('revjet_plc_id') || defaultEnv.testPlcId;
  const revJetKey = useConfigService('revjet_key') || defaultEnv.testKey;
  const revJetPath = useConfigService('revjet_path') || defaultEnv.testPath;

  const revJetOnload = () => {
    if (window && revJetRef.current) {
      window.revjet = window.revjet || {};
      window.revjet.tags = window.revjet.tags || [];
      window.revjet.tags.push({
        _tag: revJetTag,
        _plc_id: revJetPlcId,
        _key: revJetKey,
        _opts: {
          embd_tag_id: (function (doc) {
            const tag = doc.createElement('div');
            tag.id = 'revjet-tag-' + window.revjet.tags.length;
            const scriptRevJet = revJetRef.current;
            if (scriptRevJet) {
              scriptRevJet.prepend(tag);
            }
            return tag.id;

          }(document)),
          responsive: true,
          autoscale: false,
          autohide: true,
          responsive_height: 'dynamic',
          delivery_method: 'banner',
          _imp_banner_size: '1400x1400'
        },
        omsid: itemId
      });
    }
  };

  const resetRevJetChildren = () => {
    if (revJetRef?.current?.children?.length > 0) {
      Array.from(revJetRef.current.children).forEach((child) => {
        revJetRef.current.removeChild(child);
      });
    }
  };

  useEffect(() => {
    if (revJetRef.current && revJetLoaded) {
      revJetOnload();
    }
  }, [revJetRef.current, revJetLoaded]);

  useEffect(() => {
    const script = document.createElement('script');

    script.onload = () => {
      setRevJetLoaded(true);
    };
    script.src = revJetPath;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      resetRevJetChildren();
      setRevJetLoaded(false);
    };
  }, [itemId]);

  return (
    <div id={parentId} ref={revJetRef} className="sui-w-full" data-testid="revJet" />
  );
};

RevJet.displayName = 'RevJet';

const defaultProps = {
  defaultEnv: {
    testTag: 'tag153737',
    testPlcId: '47147873',
    testKey: 'b80',
    testPath: 'https://rma.homedepotdev.com/bg'
  }
};

RevJet.propTypes = {
  itemId: string.isRequired,
  parentId: string.isRequired,
  defaultEnv: shape({})
};

RevJet.defaultProps = defaultProps;