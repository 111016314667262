import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '@thd-olt-component-react/overlay';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import './CTARenderer.scss';
import { getVideoId } from '../../helpers/helpers';

const CTARenderer = ({
  children, channel, ctaLink, videoId, className, videoStill, height, width, withOverlay
}) => {
  const [overlay, setOverlay] = useState(false);
  const videoIdStr = videoId || getVideoId(ctaLink);
  const onVideoClick = (event) => {
    event.preventDefault();
    if (videoIdStr) {
      setOverlay(true);
    }
  };

  /**
   * The optional withOverlay prop allows the VideoPlayer to open and
   * play on an overlay only when the channel is "desktop". Otherwise, the
   * VideoPlayer will just continue to play inline.
   */
  if (videoStill && withOverlay) {
    const ConditionalWrapper = ({ condition, wrapper, ctaChildren }) => {
      return condition ? wrapper(ctaChildren) : ctaChildren;
    };

    return (
      <>
        <ConditionalWrapper
          condition={channel !== 'mobile'}
          wrapper={(conditionalChildren) => (
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a href="#" className={className} onClick={onVideoClick}>
              {conditionalChildren}
            </a>
          )}
        >
          <VideoPlayer
            videoId={videoIdStr}
            videoStill={videoStill}
            className={`${className} video-player--full-width`}
            height={height}
            width={width}
            channel={channel}
          />
        </ConditionalWrapper>
        {overlay && channel !== 'mobile' ? (
          <Overlay
            onClose={() => setOverlay(false)}
            open
            fit
            closeButton
            modalClass="etch-widgets__video-overlay__modal"
          >
            <div className="etch-widgets__video-overlay" data-testid="image-video-overlay">
              <VideoPlayer
                videoId={videoIdStr}
                channel={channel}
                borderColor="black"
                autoPlay
                className="etch-widgets__video-overlay__player--overlay-active"
              />
            </div>
          </Overlay>
        ) : null}
      </>
    );
  }

  const renderVideoLink = () => {
    if (ctaLink && !videoIdStr) {
      return (
        <a className={`${className}`} data-testid="anchor-link" href={ctaLink}>
          {children}
        </a>
      );
    }
    return (
      <span
        role="button"
        tabIndex={0}
        className={`${className} video-player--pointer`}
        onClick={onVideoClick}
      >
        {children}
      </span>
    );

  };

  return (
    videoStill && videoIdStr
      ? (
        <VideoPlayer
          videoId={videoIdStr}
          videoStill={videoStill}
          className={`${className} video-player--full-width`}
          height={height}
          width={width}
        />
      )
      : (
        <>
          {renderVideoLink()}
          {overlay && (
            <Suspense fallback={<div />}>
              <Overlay
                onClose={() => setOverlay(false)}
                open
                fit
                closeButton
                modalClass="etch-widgets__video-overlay__modal"
              >
                <div className="etch-widgets__video-overlay" data-testid="image-video-overlay">
                  <VideoPlayer
                    videoId={videoIdStr}
                    channel={channel}
                    borderColor="black"
                    autoPlay
                    className="etch-widgets__video-overlay__player"
                  />
                </div>
              </Overlay>
            </Suspense>
          )}
        </>
      ));
};

CTARenderer.propTypes = {
  channel: PropTypes.oneOf([
    'desktop',
    'mobile'
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  height: PropTypes.number,
  width: PropTypes.number,
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  videoStill: PropTypes.string,
  withOverlay: PropTypes.bool
};

CTARenderer.defaultProps = {
  channel: 'desktop',
  className: '',
  ctaLink: null,
  height: null,
  width: null,
  videoStill: null,
  withOverlay: false,
  videoId: '',
  children: []
};

export default CTARenderer;
